import axios from 'axios'
import { omit } from 'lodash'

import { CreateSavedFilterPayload, DeleteResponse, SavedFilter } from './types'
import { defaultHeaders, denaliApiBaseUrl } from '../../api'
import { handleAxiosError } from '../../api/utils'
import { RequireKeys } from '../../utils/types'

export const fetchSavedFilters = (customerId: string) => {
  const url = `${denaliApiBaseUrl()}/account/saved-queries?customerId=${customerId}`

  return axios
    .get<{ results: SavedFilter[] }>(url, { headers: defaultHeaders() })
    .then(({ data }) => data.results)
    .catch(handleAxiosError)
}

export const createSavedFilter = (payload: CreateSavedFilterPayload) => {
  const url = `${denaliApiBaseUrl()}/account/saved-queries`

  return axios
    .post<SavedFilter>(url, payload, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}

export const updateSavedFilter = (payload: RequireKeys<SavedFilter, 'id'>) => {
  const url = `${denaliApiBaseUrl()}/account/saved-queries/${payload.id}`

  return axios
    .patch<SavedFilter>(url, omit(payload, 'id'), { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}

export const deleteSavedFilter = (id: string) => {
  const url = `${denaliApiBaseUrl()}/account/saved-queries/${id}`

  return axios
    .delete<DeleteResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => data)
    .catch(handleAxiosError)
}
