import { HTMLAttributes } from 'react'
import styled from 'styled-components'

const LoadingStyled = styled.div<{ color: string; width: string }>`
  align-items: center;
  display: flex;
  font-size: 10px;
  justify-content: center;
  width: ${({ width }) => width};

  > div {
    animation: ra-stretchdelay 1s infinite ease-in-out;
    background-color: ${({ color }) => color};
    border-radius: 2px;
    display: inline-block;
    margin: 1.5px;
    width: 4px;

    &:nth-child(1) {
      animation-delay: -1.1s;
      height: 12px;
      opacity: 0.8;
    }

    &:nth-child(2) {
      animation-delay: -1.1s;
      height: 20px;
    }

    &:nth-child(3) {
      height: 12px;
      opacity: 0.8;
    }
  }

  @keyframes ra-stretchdelay {
    20% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0.5);
    }
  }
`

interface TSProps extends HTMLAttributes<HTMLDivElement> {
  color?: string
  width?: string
}

const VerticalBarsLoader = ({
  color = '#fff',
  width = '50px',
  ...rest
}: TSProps) => (
  <LoadingStyled color={color} width={width} {...rest}>
    <div />
    <div />
    <div />
  </LoadingStyled>
)

export default VerticalBarsLoader
