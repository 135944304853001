import {
  SegmentedControl as MantineSegmentedControl,
  SegmentedControlItem,
  SegmentedControlProps,
  Text,
} from '@mantine/core'

import styles from './SegmentedControl.module.css'

interface SegmentedControlOption extends SegmentedControlItem {
  gainsightTagId?: string
}

interface Props extends Omit<SegmentedControlProps, 'data'> {
  activeOption?: string
  options: SegmentedControlOption[]
  updateOption: (value: string) => void
  activeColor?: string
  inactiveColor?: string
}

const SegmentedControl = ({
  activeOption,
  options = [],
  updateOption,
  activeColor,
  inactiveColor,
  ...rest
}: Props) => {
  const optionsData = options.map(
    ({ label, value, gainsightTagId = '', ...rest }) => {
      return {
        label: (
          <Text
            data-gainsight-id={gainsightTagId}
            c={
              activeOption === value
                ? activeColor ?? 'gray.0'
                : inactiveColor ?? 'gray.9'
            }
            lh={1}
          >
            {label}
          </Text>
        ),
        value,
        ...rest,
      }
    }
  )

  return (
    <MantineSegmentedControl
      className={styles.segmentedControl}
      pr={6}
      color='green.5'
      data={optionsData}
      onChange={updateOption}
      radius='sm'
      value={activeOption}
      {...rest}
    />
  )
}

export default SegmentedControl
