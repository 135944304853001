import Highcharts, {
  AxisLabelsFormatterContextObject,
  Options,
} from 'highcharts'
import DraggablePoints from 'highcharts/modules/draggable-points'
import Exporting from 'highcharts/modules/exporting'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { useTheme } from 'styled-components'

import { GroupWithPeak } from './PeakDemandPage'
import { getToolTipFormatted } from '../../components/CircuitsUsageDataView/utils'
import { useFormatNumber } from '../../components/mantine/Quantity/FormatNumber'
import { useThousandsSeparatorPreference } from '../../queries/settings'
import { getNumberWithPrecision } from '../../utils'
import {
  config as baseConfig,
  getScrollingColumnEvents,
} from '../../utils/chartsDefaultConfig'

Exporting(Highcharts)
NoDataToDisplay(Highcharts)
DraggablePoints(Highcharts)

interface TSProps extends HighchartsReact.Props {
  chartRef: any
  peakPowerData: GroupWithPeak[]
  loading: boolean
  title: string
}

const PeakPowerBarChart = (props: TSProps) => {
  const formatNumber = useFormatNumber()
  const theme = useTheme()
  const { data: thousandsSeparator } = useThousandsSeparatorPreference()

  const { chartRef, peakPowerData, title, loading } = props

  const average = '#e0e0e0' //224, 224, 224
  const peak = theme.colors.primary //72, 93, 160
  const yAxisTitleAlign = peakPowerData.length > 20 ? 'high' : 'middle'

  const seriesDataMax = peakPowerData.map((group) => {
    return {
      name: group.groupName || '',
      y: getNumberWithPrecision(group?.max || 0, 2),
    }
  })

  const seriesDataAvg = peakPowerData.map((group) => {
    return {
      name: group.groupName || '',
      y: getNumberWithPrecision(group?.average || 0, 2),
    }
  })

  const scrollingEvents =
    peakPowerData.length > 20 ? getScrollingColumnEvents() : {}

  const options: Options = {
    ...baseConfig,
    title: {
      ...baseConfig.title,
      text: title,
    },
    chart: {
      ...baseConfig.chart,
      events: {
        ...scrollingEvents,
      },
    },
    xAxis: {
      ...baseConfig.xAxis,
      categories: seriesDataMax.map((series) => series.name),
      max: peakPowerData.length > 20 ? 19 : peakPowerData.length - 1,
    },
    yAxis: {
      ...baseConfig.yAxis,
      min: 0,
      title: {
        align: yAxisTitleAlign,
        text: 'kW',
        textAlign: 'right',
        margin: 10,
      },
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatNumber(this.value as number)
        },
      },
    },
    tooltip: {
      ...baseConfig.tooltip,
      formatter: getToolTipFormatted({
        format: thousandsSeparator,
        suffix: 'kW',
      }),
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      ...baseConfig.plotOptions,
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
        borderRadius: 2,
      },
    },
    series: [
      {
        name: 'Peak Demand',
        data: loading ? [] : seriesDataMax,
        type: 'column',
        color: peak,
      },
      {
        name: 'Average Demand',
        data: loading ? [] : seriesDataAvg,
        type: 'column',
        color: average,
        pointPlacement: -0.059,
      },
    ],
    legend: {
      ...baseConfig.legend,
      enabled: true,
    },
    exporting: {
      ...baseConfig.exporting,
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG'],
        },
      },
      type: 'image/png',
    },
  }

  return (
    <>
      <HighchartsReact
        id={title}
        highcharts={Highcharts}
        options={options as unknown as Options}
        ref={chartRef}
        {...props}
      />
    </>
  )
}

export default PeakPowerBarChart
