interface TSProps {
  color?: string
  size?: string
  gainsightTagId?: string
}

const ChevronDown = ({
  color = '#162447',
  size = '16',
  gainsightTagId = '',
}: TSProps) => (
  <svg
    fill='none'
    height={size}
    viewBox='0 0 14 8'
    width={size}
    data-gainsight-id={gainsightTagId}
  >
    <path
      d='M1 1L7 7L13 1'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ChevronDown
