import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  DATA_EXPLORER_PATH,
  PORTFOLIO_PATH,
  PROJECTS_PATH,
} from '../constants/paths'
import { gainsightPXGlobalContext, TSActionTypes } from '../utils/gainsight'

const useSetGainsightContext = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    gainsightPXGlobalContext(TSActionTypes.setOnce, {
      userAgent: navigator.userAgent,
    })
    const noSiteContextPage =
      location?.pathname.includes(`${DATA_EXPLORER_PATH}/`) ||
      location?.pathname.includes(`${PORTFOLIO_PATH}/`) ||
      location?.pathname.includes(`${PROJECTS_PATH}/`) //TODO: put these values in an array

    if (!noSiteContextPage) {
      // Reset url params when navigating away from this page
      gainsightPXGlobalContext(TSActionTypes.remove, ['siteId', 'siteName'])
    }
  }, [dispatch, location?.pathname, location.state])
}

export default useSetGainsightContext
