import RightAngleIcon from '@iconscout/react-unicons/icons/uil-angle-right'
import { Grid, Group, Stack } from '@mantine/core'
import { isEqual, startCase } from 'lodash'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import AdvancedFilterPills from './AdvancedFilterPills'
import AdvancedFilters from './AdvancedFilters'
import DateRangePicker from './DateRangePicker'
import {
  useRemoveInvalidAdvancedFilters,
  useUpdateInvalidBasicFilters,
} from './filterUtils'
import GroupSelect from './GroupSelect'
import styles from './index.module.css'
import ResolutionSelect from './ResolutionSelect'
import Button from '../../../components/mantine/Button'
import MultiSelect from '../../../components/mantine/MultiSelect'
import Select from '../../../components/mantine/Select'
import { ResourceType } from '../../../ducks/types'
import { useSites } from '../../../queries/sites'
import { DataExplorerFilterParams, Params, UUID } from '../types'
import { FiltersContext } from '../utils/filterParams'

const DataExplorerFilters = ({ setActiveFilters }: Params) => {
  // Shared
  const { data: sites = [] } = useSites()
  const activeFilters = useContext(FiltersContext)
  const [pendingFilters, setPendingFilters] =
    useState<DataExplorerFilterParams>(activeFilters)
  useUpdateInvalidBasicFilters(pendingFilters, setPendingFilters)
  useRemoveInvalidAdvancedFilters(pendingFilters, setPendingFilters)

  const [advancedFiltersVisible, setAdvancedFiltersVisible] = useState(false)
  const toggleFilters = useCallback(
    () => setAdvancedFiltersVisible((prev) => !prev),
    [setAdvancedFiltersVisible]
  )

  // Sites
  const updateSites = (siteIds: UUID[]) => {
    setPendingFilters((prev) => ({ ...prev, siteIds }))
  }

  // Resource Type
  const { pageId = 'consumption-analysis' } = useParams()
  const allowResourceSelection = pageId === 'consumption-analysis'

  const resourceTypeOptions = [
    ResourceType.ELECTRICITY,
    ResourceType.WATER,
    ResourceType.NATURAL_GAS,
  ].map((resource) => ({
    label: startCase(resource),
    value: resource,
  }))
  const setResourceType = (val: string | null) => {
    setPendingFilters((prev) => ({
      ...prev,
      resourceType: (val as ResourceType) ?? ResourceType.ELECTRICITY,
    }))
  }

  // Apply Button
  const hasPendingFilterChanges = !isEqual(pendingFilters, activeFilters)
  const applyPendingFilters = () => {
    setActiveFilters(() => pendingFilters)
    setAdvancedFiltersVisible(false)
  }
  const clearPendingFilters = () => {
    setPendingFilters(activeFilters)
  }
  const location = useLocation()
  useEffect(() => {
    setPendingFilters(activeFilters)
    // Reset pending filters when location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <Stack px='xl' gap={16}>
      <Grid columns={12} gutter={4} pl={0}>
        <Grid.Col span='auto'>
          <Group gap='4px 12px'>
            {activeFilters.options?.singleSiteSelect ? (
              <Select
                data={sites.map((site) => ({
                  value: site.id,
                  label: site.validName,
                }))}
                data-gainsight-id='sites-query-filter-selector-single-site-selector'
                label='Sites'
                onChange={(_, option) => updateSites([option.value])}
                placeholder='Select Sites'
                size='md'
                value={pendingFilters.siteIds?.[0]}
                w={210}
              />
            ) : (
              <MultiSelect
                data={sites.map((site) => ({
                  value: site.id,
                  label: site.validName,
                }))}
                data-gainsight-id='sites-query-filter-selector-multi-site-selector'
                label='Sites'
                maxDisplayedValues={1}
                pillMaxWidth={135}
                placeholder='Select Sites'
                setValues={updateSites}
                showSelectAll
                size='md'
                values={pendingFilters.siteIds}
                w={210}
              />
            )}
            <DateRangePicker
              pendingFilters={pendingFilters}
              setPendingFilters={setPendingFilters}
              data-gainsight-id='sites-query-filter-selector-date-range'
            />
            <Select
              data-gainsight-id='resource-type-selector'
              data={resourceTypeOptions}
              disabled={!allowResourceSelection}
              key='resource-type-selection'
              label='Resource Type'
              onChange={setResourceType}
              rightSectionWidth={30}
              size='md'
              styles={{ input: { paddingLeft: 8 } }}
              value={pendingFilters.resourceType}
              w={120}
            />
            <GroupSelect
              pendingFilters={pendingFilters}
              setPendingFilters={setPendingFilters}
            />
            <ResolutionSelect
              pendingFilters={pendingFilters}
              setPendingFilters={setPendingFilters}
            />
          </Group>
        </Grid.Col>

        <Grid.Col span='content' pr={0}>
          <Group mt={{ base: 16, sm: 22 }} gap={4}>
            <Button
              bg='transparent'
              c={hasPendingFilterChanges ? 'black' : 'gray.5'}
              data-gainsight-id='sites-query-filter-selector-cancel-button'
              disabled={!hasPendingFilterChanges}
              onClick={clearPendingFilters}
              size='compact-sm'
              style={{ border: 'none' }}
              variant='transparent'
            >
              Cancel
            </Button>
            <Button
              data-gainsight-id='sites-query-filter-selector-apply-button'
              onClick={applyPendingFilters}
              disabled={!hasPendingFilterChanges}
            >
              Apply
            </Button>
          </Group>
        </Grid.Col>
      </Grid>

      <Group gap={8} wrap='nowrap'>
        <Button
          bg='transparent'
          c='black'
          className={styles.advancedFiltersButton}
          data-gainsight-id='sites-query-filter-selector-toggle-advanced-filters-toggle'
          data-rotated={advancedFiltersVisible}
          h={25}
          onClick={toggleFilters}
          size='compact-sm'
          variant='transparent'
          leftSection={
            <RightAngleIcon width={10} height={14} viewBox='6 4 12 16' />
          }
        >
          Advanced Filters
        </Button>

        <Group gap={8}>
          <AdvancedFilterPills
            key='advanced-filter-pills'
            setPendingFilters={setPendingFilters}
            pendingFilters={pendingFilters}
          />
        </Group>
      </Group>

      <AdvancedFilters
        pendingFilters={pendingFilters}
        setPendingFilters={setPendingFilters}
        visible={advancedFiltersVisible}
      />
    </Stack>
  )
}

export default DataExplorerFilters
