import styled from 'styled-components'

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  margin: 0;
`
export default Title
