import axios from 'axios'
import { omit } from 'lodash'

import {
  TSCreateSiteShiftPayload,
  TSDeleteSiteShiftPayload,
  TSSiteShiftResponse,
  TSSiteShiftsResponse,
  TSUpdateSiteShiftPayload,
} from './types'
import { defaultHeaders, denaliApiBaseUrl } from '../../api'
import { handleAxiosError } from '../../api/utils'
import siteShiftsMockData from '../../mockData/siteShifts'
import { isVariantActive } from '../../utils/variants'

export const fetchSiteShifts = (siteId: string) => {
  if (isVariantActive('mock')) {
    return Promise.resolve(siteShiftsMockData).then(
      (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
    )
  }
  const url = `${denaliApiBaseUrl()}/sites/${siteId}/shifts`
  return axios
    .get(url, { headers: defaultHeaders() })
    .then(({ data }: { data: TSSiteShiftsResponse }) => data)
    .catch(handleAxiosError)
}

export const createSiteShift = (payload: TSCreateSiteShiftPayload) => {
  const url = `${denaliApiBaseUrl()}/sites/${payload.siteId}/shifts`

  return axios
    .post(url, omit(payload, ['siteId']), { headers: defaultHeaders() })
    .then(({ data }: { data: TSSiteShiftResponse }) => data)
    .catch(handleAxiosError)
}

export const deleteSiteShift = (payload: TSDeleteSiteShiftPayload) => {
  const url = `${denaliApiBaseUrl()}/sites/${payload.siteId}/shifts/${
    payload.shiftId
  }`

  return axios
    .delete(url, { headers: defaultHeaders() })
    .then(({ data }: { data: TSSiteShiftsResponse }) => data)
    .catch(handleAxiosError)
}

export const updateSiteShift = (payload: TSUpdateSiteShiftPayload) => {
  const url = `${denaliApiBaseUrl()}/sites/${payload.siteId}/shifts/${
    payload.shiftId
  }`

  return axios
    .patch(url, omit(payload, ['siteId', 'shiftId']), {
      headers: defaultHeaders(),
    })
    .then(({ data }: { data: TSSiteShiftResponse }) => data)
    .catch(handleAxiosError)
}
