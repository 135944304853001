import { UilAngleDoubleDown, UilAngleDoubleUp } from '@iconscout/react-unicons'
import { Box, Flex, Group, Stack, Text } from '@mantine/core'
import { useEffect, useState } from 'react'

import { getUsername } from '../../api/utils'
import { EMPTY_METRIC_STRING } from '../../constants/strings'
import {
  TSOpportunityAttachment,
  TSOpportunityComment,
} from '../../queries/allProjects/types'
import { extractFileNameInfo } from '../../utils'
import FileThumbnailPreview from '../FileThumbnailPreview'
import DownloadIcon from '../Icons/DownloadIcon'
import Spinner from '../Spinner'
interface CommentSectionTypes {
  comments: TSOpportunityComment[]
  attachments: TSOpportunityAttachment[]
  setDocumentSelectedforDeletion: React.Dispatch<React.SetStateAction<string>>
  setShowDeleteDocumentModal: React.Dispatch<React.SetStateAction<boolean>>
  isCommentsLoading: boolean
  isAttachmentsLoading: boolean
}

const CommentSection = ({
  comments,
  attachments,
  setDocumentSelectedforDeletion,
  setShowDeleteDocumentModal,
  isCommentsLoading,
  isAttachmentsLoading,
}: CommentSectionTypes) => {
  const username = getUsername()

  const [commentsToShow, setCommentsToShow] = useState(comments.slice(0, 2))
  useEffect(() => {
    setCommentsToShow(comments.slice(0, 2))
  }, [comments])

  return (
    <Stack my='xl' gap='lg'>
      <Text c='black' fw='700'>
        Comments
      </Text>
      {!isCommentsLoading && !isAttachmentsLoading && comments.length == 0 && (
        <Text c='gray.6'> No comments have been added yet</Text>
      )}
      {(isCommentsLoading || isAttachmentsLoading) && <Spinner centered />}
      {!isCommentsLoading && !isAttachmentsLoading && comments.length > 0 && (
        <>
          <Stack mt='md'>
            {commentsToShow?.map((comment, ix) => {
              return (
                <Stack
                  key={`opportunityComment_${ix}`}
                  bg={
                    username.toLowerCase() == comment.createdBy.toLowerCase()
                      ? 'white'
                      : 'rgba(108, 109, 110, 0.12)'
                  }
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #E0E0E0',
                  }}
                  p='md'
                >
                  <Flex justify='space-between'>
                    <Text size='md' fw={500} c='blue.5'>
                      {username.toLowerCase() == comment.createdBy.toLowerCase()
                        ? 'Me'
                        : comment.commentAddedBy}
                    </Text>
                    <Text size='sm' c='#6C6D6E'>
                      {comment.created}
                    </Text>
                  </Flex>
                  <Text size='md' c='black'>
                    {comment.comment}
                  </Text>
                  {attachments?.map((attachment) => {
                    return attachment.commentId == comment.id ? (
                      <Box m='4px 0px' key={attachment.id}>
                        <FileThumbnailPreview
                          href={attachment.attachmentUrl}
                          onClose={() => {
                            setDocumentSelectedforDeletion(attachment.id)
                            setShowDeleteDocumentModal(true)
                          }}
                          fileIcon={<DownloadIcon size='32' />}
                          variant='inline'
                          title={decodeURIComponent(
                            extractFileNameInfo(attachment.fileName)?.name ||
                              EMPTY_METRIC_STRING
                          )}
                          metaText={[
                            extractFileNameInfo(attachment.fileName)
                              ?.extension || EMPTY_METRIC_STRING,
                          ]}
                        />
                      </Box>
                    ) : null
                  })}
                </Stack>
              )
            })}
          </Stack>

          {comments.length > 2 && (
            <Group
              gap='0px'
              align='center'
              onClick={() =>
                setCommentsToShow(
                  commentsToShow.length > 2 ? comments.slice(0, 2) : comments
                )
              }
              style={{ cursor: 'pointer' }}
              w='max-content'
            >
              <Text c='blue.6'>
                {commentsToShow.length > 2
                  ? `Show fewer comments`
                  : `Show all ${comments.length} comments`}
              </Text>
              {commentsToShow.length > 2 ? (
                <UilAngleDoubleUp color='#43558E' />
              ) : (
                <UilAngleDoubleDown color='#43558E' />
              )}
            </Group>
          )}
        </>
      )}
    </Stack>
  )
}

export default CommentSection
