import { CloseButton, CloseButtonProps } from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component'

const CloseButtonComponent = ({
  c = 'dark',
  disabled = false,
  iconSize = 14,
  size = 'sm',
  variant = 'transparent',
  title = 'close',
  ...rest
}: PolymorphicComponentProps<'button', CloseButtonProps>) => {
  return (
    <CloseButton
      c={c}
      disabled={disabled}
      iconSize={iconSize}
      size={size}
      title={title}
      variant={variant}
      {...rest}
    />
  )
}

export default CloseButtonComponent
