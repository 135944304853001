import moment from 'moment'
import styled from 'styled-components'

import { SUBMISSIONS_CREATE_DATE } from '.'
import Grid from '../../../components/Grid'
import MetricCard from '../../../components/MetricCard'
import Spinner from '../../../components/Spinner'
import { useSummaryDataQuery } from '../../../queries/energyStar'
import { isCurrentUserRedaptiveDemoUser } from '../../../utils/demo'

const MetricValueStyled = styled.div`
  font-size: 20px;
  span {
    font-size: 16px;
  }
`

const ChampionTiles = () => {
  const summaryDataQuery = useSummaryDataQuery()

  if (summaryDataQuery.isPending) return <Spinner size='sm' centered />

  const currentDate = new Date()
  const showZeroValue =
    currentDate.getUTCDate() < SUBMISSIONS_CREATE_DATE &&
    !isCurrentUserRedaptiveDemoUser()
      ? 0
      : null
  const subtitle = `For: ${moment().subtract(1, 'month').format(' MMM YYYY')}`

  const dataArray = [
    {
      title: 'Latest Submission Date',
      value: summaryDataQuery.data?.latestSubmissionDate
        ? moment(summaryDataQuery.data?.latestSubmissionDate).format(
            'MMM DD, YYYY'
          )
        : '-',
    },
    {
      title: 'Electric Meters Submitted',
      value:
        showZeroValue ?? (
          <MetricValueStyled>
            {summaryDataQuery.data?.sitesSubmittedForElectricity}
            <span>/{summaryDataQuery.data?.totalSitesForElectricity}</span>
          </MetricValueStyled>
        ) ??
        '-',
      subtitle,
      valueTooltip: `${
        summaryDataQuery.data?.sitesSubmittedForElectricity ?? 0
      } submitted`,
    },
    {
      title: 'Water Meters Submitted',
      value:
        showZeroValue ?? (
          <MetricValueStyled>
            {summaryDataQuery.data?.sitesSubmittedForWater}
            <span>/{summaryDataQuery.data?.totalSitesForWater}</span>
          </MetricValueStyled>
        ) ??
        '-',
      subtitle,
      valueTooltip: `${
        summaryDataQuery.data?.sitesSubmittedForWater ?? 0
      } submitted`,
    },
    {
      title: 'Gas Meters Submitted',
      value:
        showZeroValue ?? (
          <MetricValueStyled>
            {summaryDataQuery.data?.sitesSubmittedForGas}
            <span>/{summaryDataQuery.data?.totalSitesForGas}</span>
          </MetricValueStyled>
        ) ??
        '-',
      subtitle,
      valueTooltip: `${
        summaryDataQuery.data?.sitesSubmittedForGas ?? 0
      } submitted`,
    },
  ]
  return (
    <Grid colsLg={4}>
      {dataArray.map(({ title, value, subtitle, valueTooltip }) => (
        <MetricCard
          key={title}
          title={title}
          subtitle={subtitle}
          value={value}
          valueTooltip={valueTooltip}
        />
      ))}
    </Grid>
  )
}

export default ChampionTiles
