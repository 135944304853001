interface TSProps {
  color?: string
  size?: string
  gainsightTagId?: string
}

const ChevronUp = ({
  color = '#162447',
  size = '16',
  gainsightTagId = '',
}: TSProps) => (
  <svg
    fill='none'
    height={size}
    viewBox='0 0 14 8'
    width={size}
    data-gainsight-id={gainsightTagId}
  >
    <path
      d='M13 7L7 1L1 7'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ChevronUp
