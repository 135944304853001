import { TooltipFormatterContextObject } from 'highcharts'
import numeral from 'numeral'

import { formatNumberWithCustomFormatting } from '../../utils'
// eslint-disable-next-line import/order
import './utils.css'

export const getToolTipFormatted = ({ format, suffix }) =>
  function formatter(this: TooltipFormatterContextObject) {
    const [p1, p2]: any = this.points
    return `<p class="title">${p1.key || p2.key}</p>
    <br/>
        ${this.points
          ?.map((p) => {
            const seriesName = p.series.name
            const value = numeral(p.y).value()
            return `
            <div class="data">
              <span>${seriesName}:</span><span class="point-value">${formatNumberWithCustomFormatting(
                value,
                format
              )}</span>
              <span class="suffix">${suffix && suffix}</span>
            </div>
            `
          })
          .join('')}`
  }
