import { Stack } from '@mantine/core'
import { useMemo, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'

import { TSLabels } from '../../queries/allProjects/types'
import { getLabelOptionsByGroupForReactSelect } from '../../queries/allProjects/utils'
import Button from '../Button'
import Popup, { PopupFooter } from '../Popup'

export interface TSAddLabelsPopupProps {
  labels: TSLabels
  labelsLoading: boolean
  handleAddLabels: (formattedLabels: { id?: string; text: string }[]) => void
  handleClosePopup: () => void
}

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  input {
    width: 100%;
  }
`

const AddLabelsPopup = ({
  labels,
  labelsLoading,
  handleAddLabels,
  handleClosePopup,
}: TSAddLabelsPopupProps) => {
  const [selectedLabels, setSelectedLabels] = useState<
    readonly { value: string; label: string }[]
  >([])

  const labelsForSelector = useMemo(
    () => getLabelOptionsByGroupForReactSelect(labels),
    [labels]
  )

  const handleOnAdd = () => {
    // do any validation here, if valid call the create method passed from the parent
    const formattedLabels = selectedLabels?.map((selectedLabel) => {
      const foundLabel = labels.find(
        (label) => label.text === selectedLabel.label
      )

      if (foundLabel) {
        return {
          id: foundLabel.id,
          text: foundLabel.text,
        }
      } else {
        return { text: selectedLabel.label }
      }
    })

    // if we made it to here, ok to save the batch
    handleAddLabels(formattedLabels)
  }

  return (
    <Popup width='800px' title='Add Labels' handleOnClose={handleClosePopup}>
      <Stack gap='lg'>
        <FormGroup>
          <CreatableSelect
            isMulti
            options={labelsForSelector}
            closeMenuOnSelect={false}
            placeholder={
              !labelsLoading
                ? 'Type to search or create a new label'
                : 'Labels are still loading...'
            }
            onChange={(values) => setSelectedLabels(values)}
            value={selectedLabels}
          />
        </FormGroup>
      </Stack>

      <PopupFooter>
        <Button onClick={handleClosePopup} borderRadius='small'>
          Cancel
        </Button>
        <Button
          onClick={handleOnAdd}
          borderRadius='small'
          buttonType='secondary'
          disabled={selectedLabels.length <= 0}
        >
          Add labels
        </Button>
      </PopupFooter>
    </Popup>
  )
}

export default AddLabelsPopup
