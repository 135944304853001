import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { isAxiosError } from 'axios'

const MAX_RETRIES = 3
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404, 413]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default caching to 20 seconds
      staleTime: 1000 * 20,
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false
        }

        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          return false
        }

        return true
      },
    },
  },
})

const ReactQueryProvider = ({ children }: { children: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

export default ReactQueryProvider
