import { isNil, round } from 'lodash'

import { useThousandsSeparatorPreference } from '../../../queries/settings'
import { formatNumberWithCustomFormatting } from '../../../utils'

const FormatNumber = ({
  value,
  precision = 2,
}: {
  value: number | undefined
  precision?: number
}): string | undefined => {
  const formatNumber = useFormatNumber()

  return isNil(value) ? undefined : formatNumber(round(value, precision))
}

export const useFormatNumber = () => {
  const { data: thousandsSeparator } = useThousandsSeparatorPreference()

  return (value?: number | string | null) =>
    isNil(value)
      ? undefined
      : formatNumberWithCustomFormatting(value, thousandsSeparator)
}

export default FormatNumber
