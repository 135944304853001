import { Button, Group, Modal, Stack, Text } from '@mantine/core'

import styles from './styles.module.css'
import { DeleteSavedFilterConfirmationProps } from './types'
import CustomButton from '../../../../components/mantine/Button'
import { useDeleteSavedFilter } from '../../../../queries/savedFilters'

const DeleteSavedFilterConfirmation = ({
  id,
  name,
  onClose,
}: DeleteSavedFilterConfirmationProps) => {
  const deleteSavedFilter = useDeleteSavedFilter()

  const deleteSavedFilterHandler = () => {
    if (!id) return

    deleteSavedFilter.mutate(id)
    onClose()
  }

  return (
    <Modal
      opened={true}
      onClose={onClose}
      title='Delete Saved Filter'
      className={styles.modal}
    >
      <Stack gap='md'>
        <Text>
          Are you sure you want to delete{' '}
          <Text span fw={600}>
            {name}
          </Text>
          ?
        </Text>
        <Group justify='flex-end'>
          <Button
            c='black'
            data-autofocus
            data-gainsight-id='data-explorer-delete-saved-filter-modal-cancel-button'
            onClick={onClose}
            size='compact-md'
            variant='transparent'
          >
            Cancel
          </Button>

          <CustomButton
            data-gainsight-id='data-explorer-delete-saved-filter-modal-delete-button'
            onClick={deleteSavedFilterHandler}
            size='md'
            variant='danger'
          >
            Delete
          </CustomButton>
        </Group>
      </Stack>
    </Modal>
  )
}

export default DeleteSavedFilterConfirmation
