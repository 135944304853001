import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'
import { ErrorBoundary } from '@rollbar/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import RollbarProvider from './components/RollbarProvider'
import './index.css'
import './init'
import App from './pages/App'
import ErrorPage from './pages/ErrorPage'
import ReactQueryProvider from './ReactQueryProvider'
import configureStore from './store/configureStore'
import { CustomMantineProvider } from './themes/mantine'
import RedaptiveThemeProvider from './themes/RedaptiveThemeProvider'

const store = configureStore()
export type TSRootState = ReturnType<typeof store.getState>
const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <ReactQueryProvider>
    <BrowserRouter>
      <RedaptiveThemeProvider>
        <CustomMantineProvider>
          <RollbarProvider>
            <ErrorBoundary fallbackUI={ErrorPage}>
              <Notifications zIndex={1500} />
              <Provider store={store}>
                <App />
              </Provider>
            </ErrorBoundary>
          </RollbarProvider>
        </CustomMantineProvider>
      </RedaptiveThemeProvider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </ReactQueryProvider>
)
