import styled from '@emotion/styled'
import InfoCircle from '@iconscout/react-unicons/icons/uil-info-circle'
import { Alert as AlertDialog } from '@mantine/core'
import { ReactNode } from 'react'
import { css } from 'styled-components'

type AlertType = 'red' | 'orange' | 'green'

type TVariant = 'light' | 'filled' | 'outline'

const AlignCenterAlertStyle = css`
  .mantine-Alert-wrapper {
    align-items: center;
  }
`

const AlertDialogStyled = styled(AlertDialog)<{
  children?: ReactNode
}>`
  ${({ children }) => !children && AlignCenterAlertStyle};
`

export const Alert = ({
  title,
  type = 'green',
  variant = 'light',
  closeButtonLabel = 'Close Alert Dialog',
  children,
  ...rest
}: {
  title: ReactNode
  type?: AlertType
  variant?: TVariant
  closeButtonLabel?: string
  children?: ReactNode
  withCloseButton?: boolean
}) => {
  return (
    <AlertDialogStyled
      title={title}
      color={type}
      variant={variant}
      icon={<InfoCircle />}
      closeButtonLabel={closeButtonLabel}
      {...rest}
    >
      {children}
    </AlertDialogStyled>
  )
}
