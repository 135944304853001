import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'

import CloseButtonComponent from './mantine/CloseButton'
import Title from './Title'
import { actions as modalActions } from '../ducks/modal'

export interface TSOptEnergyStarModalProps {
  handleOnClose: () => void
}

const ModalWrapperStyled = styled.div`
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0.5rem;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5000;

  * {
    box-sizing: border-box;
  }
`

const ModalStyled = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  grid-template-rows: auto 1fr auto;
  display: grid;
  text-align: center;
  width: 550px;
`

const ModalTitleStyled = styled(Title)`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 24px;
`

const ModalBodyStyled = styled.div`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: 10px;
  padding: 12px 16px;
`

const BodyText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  margin-bottom: 24px;
`

const HyperLinkStyles = styled.a`
  color: #485da0;
  cursor: pointer;
  text-decoration: underline;
`

const HeadWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
`

const CloseButtonComponentStyled = styled(CloseButtonComponent)`
  margin: 5px;
  background-color: #485da0;
`

const OptEnergyStarModal = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const bodyOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = bodyOverflow
    }
  }, [])

  return (
    <ModalWrapperStyled>
      <ModalStyled>
        <HeadWrapperStyled>
          <CloseButtonComponentStyled
            variant='filled'
            c='white'
            title='Close modal'
            size='lg'
            onClick={() => dispatch(modalActions.hideModal())}
          />
        </HeadWrapperStyled>
        <ModalBodyStyled>
          <ModalTitleStyled>Did You Know ?</ModalTitleStyled>
          <BodyText>
            Redaptive can use your meter data to make monthly submissions on
            your behalf to disclosure frameworks like ENERGY STAR.
          </BodyText>
          <BodyText>
            Please reach out to your{' '}
            <HyperLinkStyles
              id='es-mail-hyperlink'
              href='mailto:esgreporting@redaptiveinc.com'
            >
              Redaptive Customer Representative
            </HyperLinkStyles>{' '}
            for more details
          </BodyText>
          <Tooltip
            style={{ maxWidth: '360px', textAlign: 'center' }}
            anchorSelect='#es-mail-hyperlink'
            content=' esgreporting@redaptiveinc.com'
          />
        </ModalBodyStyled>
      </ModalStyled>
    </ModalWrapperStyled>
  )
}

export default OptEnergyStarModal
