import moment, { Moment } from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates'
import { END_DATE, START_DATE } from 'react-dates/constants'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import styled, { css } from 'styled-components'

import * as consts from '../constants'
import { Period } from '../constants'
import useWindowSize from '../hooks/useWindowSize'
import { zIndices } from '../utils'
import { getOptionsDates } from '../utils/optionsDates'
import ChevronLeftIcon from './Icons/ChevronLeftIcon'
import ChevronRightIcon from './Icons/ChevronRightIcon'
import MonthRangePicker from './MonthRangePicker'

interface TSProps {
  end: Moment | null
  hideDropdown: () => void
  options?: Array<any>
  period?: string
  getProgramStartDate: () => Moment
  start: Moment | null
  updateDateRange: (any) => void
  maxDays?: number
  calendarInfo?: React.ComponentType
  isVerticalOrientation?: boolean
  isMonthlyDateRangePicker?: boolean
  monthsLimit?: number
}

export interface TSDateRange {
  startDate: Moment
  endDate: Moment | null
  source?: string
}

const VerticalOrientationStyles = css`
  .DayPickerNavigation,
  .DayPickerNavigation__vertical {
    position: absolute;
    top: 28px;

    div:nth-child(2) {
      left: 260px;
    }
  }

  .DayPickerNavigation_button,
  .DayPickerNavigation_button__vertical {
    display: inline;
    position: absolute;
  }

  .DayPicker_focusRegion {
    height: 300px;
  }
`

const DateRangePickerStyles = styled.div<{
  windowWidth: number
  isVertical: boolean
}>`
  position: relative;
  text-align: left;
  padding: 15px;
  height: 350px;

  ${({ isVertical }) => isVertical && VerticalOrientationStyles};

  .DayPickerNavigation_button__horizontalDefault {
    padding: 3px 2px;
  }

  .CalendarDay {
    font-size: 12px;
  }

  .DayPicker_weekHeader {
    top: 53px;
  }

  .DayPicker_weekHeader_ul {
    left: -8px;
    position: relative;
  }

  .CalendarMonth_caption {
    font-size: 14px;
  }

  .DateInput {
    width: 268px;
    padding-right: 20px;

    &:last-child {
      border-left: 1px solid #e0e0e0;
      padding-left: 20px;
    }
  }

  .DateInput_fang {
    display: none;
  }

  .DateRangePicker {
    width: ${({ windowWidth, isVertical }) =>
      windowWidth <= 800 || isVertical ? '300px' : '100%'};
  }

  .DayPicker__withBorder {
    box-shadow: none;
  }

  .DateRangePickerInput__withBorder {
    display: flex;
    justify-content: space-evenly;
    border-radius: 0;
    border: 0;
  }

  .DateRangePickerInput input {
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.primary};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    letter-spacing: 0.02em;
    line-height: 20px;
    padding-bottom: 2px;
    padding-top: 6px;
  }

  .DateRangePickerInput_arrow {
    display: none;
  }
  .DateRangePickerInput_calendarIcon {
    display: none;
  }

  .ion-android-arrow-forward {
    display: none;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: -9px;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 28px;
  }

  .DayPicker_weekHeaders__horizontal {
    margin-left: -1px;
  }

  .DayPicker_transitionContainer {
    left: -18px;
  }

  .DayPicker {
    top: -20px;
    left: 9px;
  }

  .DayPicker__horizontal,
  .CalendarMonthGrid,
  .DateRangePicker_picker {
    background-color: transparent;
    left: ${({ windowWidth, isVertical }) =>
      (windowWidth <= 800 || isVertical) && '0 !important'};
  }

  .DayPickerNavigation_button__default {
    border: 0;
  }

  .DayPickerNavigation_svg__horizontal {
    fill: #000000;
  }

  .CalendarDay__default {
    border: 0;
    background: #e0e0e0;
    border-radius: 4px;

    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 4px;
      border: 0;
      color: #fff;
      opacity: 0.7;
    }
  }

  .CalendarDay__selected_span {
    background: ${({ theme }) => theme.colors.primary};
    border: 0;
    border-radius: 4px;
    color: #fff;
    opacity: 0.7;
  }

  .CalendarDay__selected {
    &,
    &:active,
    &:hover {
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 4px;
      border: 0;
      color: #fff;
    }
  }

  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 4px;
    border: 0;
    color: #fff;
    opacity: 0.7;
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:active,
  .CalendarDay__blocked_out_of_range:hover {
    background: #f9f9f9;
    border: 0;
    border-radius: 4px;
    color: #000000;
    opacity: 0.6;
  }

  .CalendarMonth_caption {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 18px;
    color: #162447;
  }
`

const SelectedItemStyles = css`
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
`

const DateOptionsItemStyles = styled.div<{
  selected: boolean
}>`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  letter-spacing: normal;
  line-height: 20px;
  padding: 8px 19px 6px 19px;
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.colors.focused};
    color: ${({ theme }) => theme.colors.fontMain};
    cursor: pointer;
  }

  ${({ selected }) => selected && SelectedItemStyles};

  :not(:last-child) {
    margin-bottom: 10px;
  }
`

const DateOptionsStyles = styled.div`
  position: relative;
  background-color: #f9f9f9;
  border-left: 1px solid #e0e0e0;
  border-radius: 0px 8px 8px 0px;
  padding: 15px;
  width: 180px;
`

const DateButtonsStyles = styled.div<{
  windowWidth: number
}>`
  z-index: 1;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: end;
  padding: 10px;

  button {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 12px;
    font-weight: 600;
    background-color: #9dbd79;
    border-radius: 4px;
    border: none;
    color: #fff;
    height: 36px;
    margin: 0 5px;
    width: 72px;

    &:hover {
      background-color: #f2a91c;
      cursor: pointer;
    }

    &:first-child {
      background-color: #e0e0e0;
      color: #6c6d6e;
      font-size: 12px;
      line-height: 20px;

      &:hover {
        background-color: #818b96;
        color: #fff;
      }
    }
  }
`

const DateSections = styled.div`
  display: flex;
`

export const DropdownStyles = styled.div`
  position: relative;
  text-transform: none;
  user-select: none;
  z-index: ${zIndices.datePickerDropdown};
`

export const DropdownInnerStyles = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #c7c7c7;
  box-shadow: 1px 2px 4px 0px rgba(84, 91, 99, 0.46);
  display: flex;
  position: absolute;
  z-index: 2;
  margin-top: 5px;
  flex-direction: column;
`

const getEndDate = (date: Moment) => {
  const endDate = moment(date, consts.URL_DATE_FORMAT)
  return Math.round(moment(endDate).diff(moment(), 'days')) >= 1
    ? moment()
    : endDate
}

const getStartDate = (date: Moment) => moment(date, consts.URL_DATE_FORMAT)

const DatePickerDropdown = ({
  end = null,
  hideDropdown,
  options = [],
  period = '',
  getProgramStartDate,
  start = null,
  updateDateRange,
  maxDays,
  calendarInfo,
  isVerticalOrientation = false,
  isMonthlyDateRangePicker = false,
  monthsLimit,
}: TSProps) => {
  const windowSize = useWindowSize()
  const programStartDate = getProgramStartDate()
  const [endDate, setEndDate]: [Moment | null, (Moment) => void] = useState(
    end ? getEndDate(end) : end
  )
  const [startDate, setStartDate]: [Moment | null, (Moment) => void] = useState(
    start ? getStartDate(start) : start
  )
  const [focusedInput, setFocusedInput]: [string, (string) => void] =
    useState(START_DATE)
  const [calendarOrientation, setCalendarOrientation]: [
    string,
    (string) => void,
  ] = useState('horizontal')
  const [selectedPeriod, updateSelectedPeriod] = useState(period)
  const windowSizeWidth = windowSize[0]

  const handleFocusChange: any = useCallback((focusedInput: string) => {
    if ([START_DATE, END_DATE].includes(focusedInput)) {
      setFocusedInput(focusedInput)
    } else {
      setFocusedInput(END_DATE)
    }
  }, [])

  const handleCancelClick: any = () => {
    setEndDate(end ? getEndDate(end) : end)
    setStartDate(start ? getStartDate(start) : start)
    updateSelectedPeriod(period)
    hideDropdown && hideDropdown()
  }

  const handleApplyClick = useCallback(() => {
    const optionsDates = getOptionsDates({ programStartDate })
    const [updatedStartDate, updatedEndDate] = optionsDates[selectedPeriod] ?? [
      startDate,
      endDate,
    ]

    if (selectedPeriod !== Period.CUSTOM) {
      setStartDate(updatedStartDate)
      setEndDate(updatedEndDate)
    }

    return (
      updateDateRange &&
      updateDateRange({
        startDate: updatedStartDate,
        endDate: updatedEndDate,
        selectedOptionDate: selectedPeriod,
      })
    )
  }, [updateDateRange, selectedPeriod, programStartDate, startDate, endDate])

  const handleOptionSelect: any = useCallback(
    (value: string) => () => {
      setStartDate(null)
      setEndDate(null)
      updateSelectedPeriod(value)
      setFocusedInput(START_DATE)
    },
    []
  )

  const handleDateChange = useCallback(
    ({ startDate, endDate, source = '' }: TSDateRange) => {
      let updateEndDate: moment.Moment | null = endDate

      if (
        startDate &&
        endDate &&
        // If the start date is being set...
        focusedInput === START_DATE &&
        !(source === 'end') // not from end date dropdown
      ) {
        // Reset the end date.
        updateEndDate = null
      }

      if (endDate === null && startDate) {
        setFocusedInput(END_DATE)
      }
      setStartDate(startDate ?? null)
      setEndDate(updateEndDate)
      updateSelectedPeriod(Period.CUSTOM)
    },
    [focusedInput]
  )

  const isOutsideRange: any = useCallback(
    (programStartDate: any) =>
      (baseDay: any, focusedInputOverride: string | null = null) => {
        const focusedInputState = focusedInputOverride || focusedInput

        // Utilize startOf('day') to provide a baseline for comparison
        // the `baseDay` provided by DatePicker is at noon for each day.
        const day = baseDay.clone().startOf('day')
        const today = moment().startOf('day')

        // startDate can never be later than today.
        if (
          focusedInputState === START_DATE &&
          (day.isAfter(today) || day.isBefore(programStartDate))
        ) {
          return true
        }

        if (focusedInputState === END_DATE) {
          // endDate can never be later than today.
          if (day.isAfter(today)) {
            return true
          }

          if (programStartDate) {
            const startDate = programStartDate.clone().startOf('day')
            // endDate can never be before startDate.
            if (day.isBefore(startDate)) {
              return true
            }
          }
        }

        // endDate can never be later than the allow max days to pick, if any.
        if (startDate && maxDays) {
          return day.isAfter(startDate.clone().add(maxDays, 'days'))
        }

        return false
      },
    [focusedInput, startDate, maxDays]
  )

  useEffect(() => {
    if (windowSize[0] <= 960 || isVerticalOrientation) {
      setCalendarOrientation('vertical')
    } else {
      setCalendarOrientation('horizontal')
    }
  }, [windowSize, isVerticalOrientation])

  return (
    <DropdownStyles>
      <DropdownInnerStyles>
        <DateSections>
          <DateRangePickerStyles
            windowWidth={windowSizeWidth}
            isVertical={calendarOrientation === 'vertical'}
          >
            {!isMonthlyDateRangePicker ? (
              <DateRangePicker
                isOutsideRange={isOutsideRange(programStartDate)}
                daySize={35}
                endDate={endDate}
                endDateId='date-range-form-end'
                focusedInput={focusedInput}
                keepOpenOnDateSelect
                hideKeyboardShortcutsPanel
                onDatesChange={handleDateChange}
                onFocusChange={handleFocusChange}
                showDefaultInputIcon
                startDate={startDate}
                startDateId='date-range-form-start'
                orientation={calendarOrientation}
                navPrev={
                  calendarOrientation === 'vertical' && <ChevronLeftIcon />
                }
                navNext={
                  calendarOrientation === 'vertical' && <ChevronRightIcon />
                }
                numberOfMonths={calendarOrientation === 'vertical' ? 1 : 2}
                verticalHeight={370}
                renderCalendarInfo={calendarInfo}
                minimumNights={0}
              />
            ) : (
              <MonthRangePicker
                endDate={endDate}
                startDate={startDate}
                onDatesChange={handleDateChange}
                minDate={getProgramStartDate}
                focusedInput={focusedInput}
                onFocusChange={handleFocusChange}
                monthsLimit={monthsLimit}
              />
            )}
          </DateRangePickerStyles>
          {calendarOrientation === 'horizontal' && options.length > 0 && (
            <DateOptionsStyles>
              {options.map((option) => {
                const isSelected = selectedPeriod === option.value
                return (
                  <DateOptionsItemStyles
                    selected={isSelected}
                    onClick={handleOptionSelect(option.value)}
                    key={option.value}
                  >
                    {option.label}
                  </DateOptionsItemStyles>
                )
              })}
            </DateOptionsStyles>
          )}
        </DateSections>
        <DateButtonsStyles windowWidth={windowSizeWidth}>
          <button type='button' onClick={handleCancelClick}>
            Cancel
          </button>
          <button type='button' onClick={handleApplyClick}>
            Apply
          </button>
        </DateButtonsStyles>
      </DropdownInnerStyles>
    </DropdownStyles>
  )
}

export default DatePickerDropdown
