import { Loader, Stack } from '@mantine/core'
import { Outlet, useLocation } from 'react-router-dom'

import NoSitesPlaceholder from '../components/NoSitesPlaceholder'
import { useSites } from '../queries/sites'

const excludedPaths = ['/projects/meter-installation-tracker', '/account']

const NoSitesRoutes = () => {
  const { pathname } = useLocation()
  const sitesQuery = useSites()

  if (sitesQuery.isPending) {
    return (
      <Stack h='80vh' align='center' justify='center'>
        <Loader color='blue' size='lg' />
      </Stack>
    )
  }

  if (
    !sitesQuery.data?.length &&
    excludedPaths.every((path) => !pathname.startsWith(path))
  ) {
    return <NoSitesPlaceholder />
  }

  return <Outlet />
}

export default NoSitesRoutes
