import Highcharts, {
  AxisLabelsFormatterContextObject,
  Options,
} from 'highcharts'
import DraggablePoints from 'highcharts/modules/draggable-points'
import Exporting from 'highcharts/modules/exporting'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { useTheme } from 'styled-components'

import { useQuantity } from '../../components/mantine/Quantity'
import { useFormatNumber } from '../../components/mantine/Quantity/FormatNumber'
import { PEAK_DEMAND_CHART_DATE_FORMAT } from '../../constants'
import { config as baseConfig } from '../../utils/chartsDefaultConfig'

Exporting(Highcharts)
NoDataToDisplay(Highcharts)
DraggablePoints(Highcharts)

interface TSProps extends HighchartsReact.Props {
  chartRef: any
  peakPowerData: { ts: string; value: number }[]
  title: string
  timezone: string
}

const PeakUseDotChart = (props: TSProps) => {
  const quantity = useQuantity()
  const formatNumber = useFormatNumber()
  const theme = useTheme()
  const { chartRef, peakPowerData, title, timezone } = props
  const serieData: any[] = []

  if (peakPowerData) {
    peakPowerData.forEach(function mapElements(value) {
      // convert time to milliseconds from midnight of start
      const timeInMs =
        moment(value.ts).tz(timezone).valueOf() -
        moment(value.ts).tz(timezone).startOf('day').valueOf()

      return serieData.push({
        x: timeInMs,
        y: value.value,
        marker: {
          symbol: 'circle',
        },
        color: '#485DA0',
        custom: {
          date: value.ts,
        },
      })
    })
  }

  const options = {
    ...baseConfig,
    chart: {
      ...baseConfig.chart,
      type: 'scatter',
      zoomType: 'xy',
      timezone: timezone,
    },
    title: {
      ...baseConfig.title,
      text: title,
    },
    xAxis: {
      ...baseConfig.xAxis,
      gridLineWidth: 0.5,
      min: 0,
      max: 1000 * 60 * 60 * 23.75, // 1 day in milliseconds minus another day
      tickInterval: 1000 * 60 * 30, // 30 min increments
      tickColor: '#000',
      labels: {
        formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
          const time = moment.duration(this.value)
          return `${moment.utc(time.as('milliseconds')).format('HH:mm')}`
        },
      },
    },
    yAxis: {
      ...baseConfig.yAxis,
      gridLineWidth: 0.5,
      lineColor: '#000',
      lineWidth: 1,
      min: 0,
      title: {
        text: 'kW',
      },
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatNumber(this.value as number)
        },
      },
    },
    tooltip: {
      ...baseConfig.tooltip,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        // Highchart fix
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const date = this.point?.custom.date
        const dateFormatted = moment(date)
          .tz(timezone)
          .format(PEAK_DEMAND_CHART_DATE_FORMAT)

        return (
          '<div style="' +
          'width: 223px;' +
          'padding: 16px' +
          '"><div style="' +
          'font-size: 14px;' +
          `font-color: ${theme.colors.fontMain};` +
          `font-family: ${theme.fontFamily};` +
          'font-weight: 600;' +
          'margin-bottom: 4px;' +
          '">' +
          'Time of Peak' +
          '</div><div style="' +
          'margin-bottom: 15px;' +
          `font-color: ${theme.colors.fontMain};` +
          `font-family: ${theme.secondaryFontFamily};` +
          'font-size: 14px;' +
          'font-weight: 400;' +
          '">' +
          dateFormatted +
          '</div><div style="' +
          'font-size: 14px;' +
          'font-weight: 600;' +
          'margin-bottom: 4px;' +
          `font-color: ${theme.colors.fontMain};` +
          `font-family: ${theme.fontFamily};` +
          '">' +
          'Peak Value' +
          '</div><div style="' +
          `font-color: ${theme.colors.fontMain};` +
          `font-family: ${theme.secondaryFontFamily};` +
          'font-size: 14px;' +
          'font-weight: 400;' +
          '">' +
          quantity(this.y || 0, 'kW') +
          '</div>' +
          '</div>'
        )
      },
    },
    legend: {
      enabled: false,
    },
    exporting: {
      ...baseConfig.exporting,
      buttons: {
        contextButton: {
          menuItems: ['downloadPNG'],
        },
      },
      type: 'image/png',
    },
    series: [
      {
        data: serieData,
        marker: {
          symbol: 'circle',
        },
        color: '#485DA0',
        name: 'Peak',
      },
    ],
  }

  return (
    <>
      <HighchartsReact
        id={title}
        highcharts={Highcharts}
        options={options as unknown as Options}
        ref={chartRef}
        {...props}
      />
    </>
  )
}

export default PeakUseDotChart
