import CreatableSelect from 'react-select/creatable'

import { TSCustomOpportunityField } from '../../queries/allProjects/types'
import { zIndices } from '../../utils'

interface CustomFieldCreatorProps {
  onChange: any
  isDisabled: boolean
  allCustomFields: TSCustomOpportunityField[]
  unusedCustomFields: TSCustomOpportunityField[] // ones not already selected
}

export function CustomFieldCreator({
  onChange,
  isDisabled,
  allCustomFields,
  unusedCustomFields,
}: CustomFieldCreatorProps) {
  const handleChange = (selectedOption: any) => {
    onChange(selectedOption)
  }

  const customFieldTitles = allCustomFields?.map((field) => {
    return { value: field.title, label: field.title }
  })

  const isOptionDisabled = (option: {
    value: string
    label: string
    __isNew__?: boolean
  }) => {
    return (
      !option.__isNew__ &&
      unusedCustomFields?.filter((field) => field.title == option.label)
        .length == 0
    )
  }

  return (
    <>
      <CreatableSelect
        options={customFieldTitles}
        placeholder='Title'
        name='title'
        onChange={(selectedOption: any) => handleChange(selectedOption)}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
        menuPosition='fixed'
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            border: '1px solid #ced4da',
            boxShadow: 'none',
            height: '42px',
            borderRadius: '4px',
            ':hover': {
              borderColor: '#162447',
            },
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: '0 0 0 4px',
            fontSize: '14px',
            fontWeight: '600',
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            fontSize: '14px',
            fontWeight: '600',
            zIndex: `${zIndices.modalPopup}`,
          }),
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        required
      />
    </>
  )
}
