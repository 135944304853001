import { uniq } from 'lodash'

import showCustomNotification from '../../../components/mantine/Notifications'
import { titleCase } from '../../../utils'
import MessageBatcher from '../../../utils/MessageBatcher'

const removeIds = (filter) => filter.replace(/[\s_]ids/gi, 's')

type ChangedFilters = string[]
const changedFiltersNotification = (changedFilters: ChangedFilters) => {
  const message = uniq(changedFilters)
    .map((filter) => titleCase(removeIds(filter)))
    .join(', ')

  showCustomNotification({
    title: 'Some filters were invalid and have been updated',
    message: `Changed filters: ${message}`,
  })
}

const filterNotificationsBatcher = new MessageBatcher<string>(
  changedFiltersNotification,
  250
)

export default filterNotificationsBatcher
