import { composeWithDevTools } from '@redux-devtools/extension'
import { applyMiddleware, compose, createStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import createSagaMiddleware from 'redux-saga'

import rootReducer from '../reducers/rootReducer'
import rootSaga from '../sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware()

function configureStoreProd() {
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    sagaMiddleware,
  ]

  const store = createStore(
    rootReducer(),
    compose(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)

  return store
}

function configureStoreDev() {
  // Shows timing of Redux actions in Performance results
  // https://medium.com/@cvitullo/performance-profiling-a-redux-app-c85e67bf84ae
  const reduxUserTimingMiddleware = () => (next) => (action) => {
    if (performance.mark === undefined) return next(action)
    performance.mark(`${action.type}_start`)
    const result = next(action)
    performance.mark(`${action.type}_end`)
    performance.measure(
      `${action.type}`,
      `${action.type}_start`,
      `${action.type}_end`
    )
    return result
  }

  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your
    // state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),
    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    sagaMiddleware,
    reduxUserTimingMiddleware,
  ]

  const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25,
  })

  const store = createStore(
    rootReducer(),
    composeEnhancers(applyMiddleware(...middlewares))
  )

  sagaMiddleware.run(rootSaga)

  if (import.meta.hot) {
    // Enable Webpack hot module replacement for reducers
    import.meta.hot.accept('../reducers/rootReducer', () => {
      // eslint-disable-next-line @typescript-eslint/no-require-imports
      const nextReducer = require('../reducers/rootReducer').default
      store.replaceReducer(nextReducer)
    })
  }
  return store
}

const configureStore = import.meta.env.PROD
  ? configureStoreProd
  : configureStoreDev
export type RootState = ReturnType<typeof rootReducer>
export default configureStore
