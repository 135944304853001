import { Flex } from '@mantine/core'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { actions as modalActions } from '../../../ducks/modal'
import { useOnboardingAccountDataQuery } from '../../../queries/energyStar'
import { colors } from '../../../themes/redaptiveOne'
import Spinner from '../../Spinner'
import {
  ESOnboardingContext,
  SubModalHeading,
  SubModalSubHeading,
} from '../OnboardingModal'

const StyledLink = styled.span`
  color: ${colors.primary100};
  text-decoration: underline;
  cursor: pointer;
`

const Confirmation = () => {
  const { formik } = useContext(ESOnboardingContext)
  const dispatch = useDispatch()
  const onboardingDataQuery = useOnboardingAccountDataQuery({
    currentAccountId: formik?.values?.accountId,
  })

  if (onboardingDataQuery?.isPending) {
    return (
      <Flex justify='center' align='center'>
        <Spinner size='sm' />
      </Flex>
    )
  }

  return (
    <>
      <SubModalHeading>Great, your ESPM account is connected.</SubModalHeading>
      <SubModalSubHeading>
        Our system detected{' '}
        <b>{onboardingDataQuery.data?.numberOfInactiveProperties} properties</b>
        .
      </SubModalSubHeading>
      <SubModalSubHeading>
        These properties will be enabled by Redaptive team and it can take a few
        days. To enable properties now,{' '}
        <StyledLink
          onClick={() =>
            dispatch(modalActions.showPropertiesConfirmationModal())
          }
        >
          click here.
        </StyledLink>
      </SubModalSubHeading>
    </>
  )
}

export default Confirmation
