import styled from 'styled-components'

import { breakpoints } from '../themes'

export const gapMap = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 20,
  xxl: 24,
}

const Stack = styled.div<{
  gap?: keyof typeof gapMap
  padding?: boolean
  direction?: 'column' | 'row'
}>`
  display: flex;
  flex-direction: ${({ direction = 'column' }) => direction};
  gap: ${({ gap = 'xxl' }) => gapMap[gap]}px;
  ${({ padding = true }) => padding && 'padding: 0 16px 16px 16px;'};

  @media (min-width: ${breakpoints.md}) {
    ${({ padding = true }) => padding && 'padding: 0 24px 24px 24px;'}
  }
`
export default Stack
