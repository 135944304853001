/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { handleSagaError } from '../../api/utils'
import { filterDemoUsageMetaData } from '../../utils/demo'
import { ResourceType, TSMetaState } from '../types'
import {
  API,
  enhanceGroupItem,
  TSGetResourceMetaData,
  TSResourceMetaItemResponse,
  TSResourceMetaResponse,
} from './electricityMetaData'

export interface TSNaturalGasMetaData extends TSResourceMetaItemResponse {
  id: string
}

interface TSNaturalGasMetaDataState extends TSMetaState {
  noNaturalGasMetaData: boolean
}

export interface TSNaturalGasMetaEntityState {
  items: Array<TSNaturalGasMetaData>
  meta: TSNaturalGasMetaDataState
}

interface TSState {
  entities: {
    naturalGasMetaData: TSNaturalGasMetaEntityState
  }
}

export const types = {
  FETCH_NATURAL_GAS_META_DATA: 'FETCH_NATURAL_GAS_META_DATA',
  FETCH_NATURAL_GAS_META_DATA_SUCCESS: 'FETCH_NATURAL_GAS_META_DATA_SUCCESS',
  FETCH_NATURAL_GAS_META_DATA_ERROR: 'FETCH_NATURAL_GAS_META_DATA_ERROR',
  RESET_NATURAL_GAS_META_DATA: 'RESET_NATURAL_GAS_META_DATA',
}

export const actions = {
  fetchNaturalGasMetaData: (payload: TSGetResourceMetaData): any => ({
    type: types.FETCH_NATURAL_GAS_META_DATA,
    ...payload,
  }),
  resetNaturalGasMetaData: () => ({
    type: types.RESET_NATURAL_GAS_META_DATA,
  }),
}

export const initialState: TSNaturalGasMetaEntityState = {
  items: [],
  meta: {
    loading: true,
    error: '',
    noNaturalGasMetaData: false,
  },
}

function entityItems(action, state) {
  const newItems: Array<TSNaturalGasMetaData> = Object.values(action.payload)
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_NATURAL_GAS_META_DATA:
    case types.RESET_NATURAL_GAS_META_DATA:
      return initialState.items
    case types.FETCH_NATURAL_GAS_META_DATA_SUCCESS:
      return entityItems(action, state)
    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_NATURAL_GAS_META_DATA:
    case types.RESET_NATURAL_GAS_META_DATA:
      return {
        ...state,
        error: '',
        loading: true,
        noNaturalGasMetaData: false,
      }
    case types.FETCH_NATURAL_GAS_META_DATA_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case types.FETCH_NATURAL_GAS_META_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noNaturalGasMetaData: !action.payload[0],
      }
    default:
      return state
  }
}

export default combineReducers({
  items,
  meta,
})

export const selectNaturalGasMetaDataEntity = (
  state: TSState
): TSNaturalGasMetaEntityState => state.entities.naturalGasMetaData

function* fetchNaturalGasMetaDataSaga({
  type,
  ...payload
}: TSGetResourceMetaData & { type: string }): Generator<any, void, any> {
  try {
    const naturalGasMetaData: TSResourceMetaResponse = yield call(
      API.fetchResourceMetaData,
      { ...payload, resourceType: ResourceType.NATURAL_GAS }
    )
    filterDemoUsageMetaData(naturalGasMetaData)

    yield put({
      type: types.FETCH_NATURAL_GAS_META_DATA_SUCCESS,
      payload: naturalGasMetaData?.results.map((groupItem) =>
        enhanceGroupItem(groupItem)
      ),
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_NATURAL_GAS_META_DATA_ERROR, e as Error)
  }
}

export const sagas = [
  takeLatest(types.FETCH_NATURAL_GAS_META_DATA, fetchNaturalGasMetaDataSaga),
]
