import { SVGProps } from 'react'

type IconProps = SVGProps<SVGSVGElement> & {
  size: number
}

const GlobeIcon = ({ size, ...rest }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='currentColor'
      stroke='currentColor'
      {...rest}
    >
      <path
        d='M7.99976 1.33276C11.6822 1.33276 14.6674 4.318 14.6674 8.00048C14.6674 11.683 11.6822 14.6681 7.99976 14.6681C4.31727 14.6681 1.33203 11.683 1.33203 8.00048C1.33203 4.318 4.31727 1.33276 7.99976 1.33276ZM9.95903 11.0003H6.04047C6.47516 12.6097 7.23076 13.6681 7.99976 13.6681C8.76876 13.6681 9.52429 12.6097 9.95903 11.0003ZM5.00527 11.0007L3.19028 11.0006C3.82918 12.0226 4.78481 12.8264 5.91973 13.2744C5.57155 12.7277 5.28422 12.0436 5.07289 11.264L5.00527 11.0007ZM12.8092 11.0006L10.9942 11.0007C10.7784 11.8898 10.4666 12.667 10.079 13.2744C11.1437 12.8544 12.0502 12.1217 12.6857 11.1896L12.8092 11.0006ZM4.72869 6.6667H2.49028L2.4871 6.67815C2.38572 7.10242 2.33203 7.54515 2.33203 8.00048C2.33203 8.70455 2.46043 9.37868 2.69508 10.0007L4.81052 10.0004C4.71565 9.36768 4.66537 8.69555 4.66537 8.00048C4.66537 7.54448 4.68701 7.09835 4.72869 6.6667ZM10.2644 6.66674H5.73512C5.68976 7.09362 5.66537 7.54008 5.66537 8.00048C5.66537 8.70682 5.7228 9.38035 5.82519 10.0006H10.1743C10.2767 9.38035 10.3341 8.70682 10.3341 8.00048C10.3341 7.54008 10.3097 7.09362 10.2644 6.66674ZM13.5095 6.66614L11.2708 6.66671C11.3125 7.09835 11.3341 7.54448 11.3341 8.00048C11.3341 8.69555 11.2838 9.36768 11.189 10.0004L13.3044 10.0007C13.539 9.37868 13.6674 8.70455 13.6674 8.00048C13.6674 7.54088 13.6128 7.09402 13.5095 6.66614ZM5.92043 2.72657L5.90517 2.73238C4.54029 3.27552 3.43628 4.33372 2.83317 5.66697L4.86525 5.66721C5.07415 4.49868 5.43871 3.4814 5.92043 2.72657ZM7.99976 2.33276L7.92263 2.3363C7.07903 2.41347 6.26402 3.74812 5.88555 5.66693H10.114C9.73649 3.75349 8.92503 2.42096 8.08396 2.33698L7.99976 2.33276ZM10.0798 2.72661L10.151 2.84267C10.5971 3.58432 10.9358 4.55757 11.1342 5.66721L13.1663 5.66697C12.5899 4.3927 11.5559 3.36968 10.2739 2.80746L10.0798 2.72661Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.3'
      />
    </svg>
  )
}
export default GlobeIcon
