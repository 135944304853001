import { useContext } from 'react'

import TextInput from '../../mantine/TextInput'
import { ESOnboardingContext, SubModalHeading } from '../OnboardingModal'

const IdentifyModal = () => {
  const { formik } = useContext(ESOnboardingContext)

  const { handleChange, errors } = formik

  return (
    <>
      <SubModalHeading>Energy Star Portfolio Manager Details</SubModalHeading>
      <TextInput
        name='accountId'
        label='Account ID'
        placeholder='Enter Account ID'
        description='Find the Account ID in the Energy Star Portal (Settings Page)'
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        gainsightTagId='onboarding-modal-account-id-input-field'
        onChange={handleChange}
        type='number'
        error={errors.accountId}
        disabled={formik.isSubmitting}
      />
    </>
  )
}

export default IdentifyModal
