import Highcharts, { Options } from 'highcharts'
import DraggablePoints from 'highcharts/modules/draggable-points'
import Exporting from 'highcharts/modules/exporting'
import Heatmap from 'highcharts/modules/heatmap'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'

import { config as baseConfig } from '../../utils/chartsDefaultConfig'
import { OPERATING_HOURS_CHART_DAYS } from '../../utils/operatingHoursHelper'
import { useQuantity } from '../mantine/Quantity'

Exporting(Highcharts)
NoDataToDisplay(Highcharts)
DraggablePoints(Highcharts)
Heatmap(Highcharts)

const dataPointColor = ({ value, isActive, maxValue }) => {
  const color = isActive ? '108, 109, 110' : '72, 93, 160'
  const opacity = value / maxValue
  return `rgba(${color}, ${opacity})`
}

interface TSProps extends HighchartsReact.Props {
  chartRef: any
  loading: boolean
  operatingDaysAndHours: object
  title: string
  usageByDayAndHour: object
}

const OperatingHoursHeatMapChart = (props: TSProps) => {
  const {
    chartRef,
    operatingDaysAndHours,
    title,
    usageByDayAndHour,
    maxUsage,
  } = props

  const quantity = useQuantity()

  const hours = [...Array(24).keys()]
  const data = OPERATING_HOURS_CHART_DAYS.map((day, dayNumber) => {
    return hours.map((hour) => {
      const usage = usageByDayAndHour[day]?.[hour] ?? 0
      const isOperatingHour = operatingDaysAndHours[day]?.[hour]

      return {
        x: hour,
        y: dayNumber,
        value: usage,
        day,
        color: dataPointColor({
          value: usage,
          isActive: isOperatingHour,
          maxValue: maxUsage,
        }),
      }
    })
  })

  const options: Options = {
    ...baseConfig,
    chart: {
      ...baseConfig.chart,
      type: 'heatmap',
    },
    title: {
      ...baseConfig.title,
      text: 'Average Consumption by Hour',
    },
    xAxis: {
      ...baseConfig.xAxis,
      lineWidth: 0,
      gridLineWidth: 0,
      lineColor: 'transparent',
      categories: [
        '0:00',
        '1:00',
        '2:00',
        '3:00',
        '4:00',
        '5:00',
        '6:00',
        '7:00',
        '8:00',
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
    },
    yAxis: {
      ...baseConfig.yAxis,
      categories: OPERATING_HOURS_CHART_DAYS,
      reversed: true,
      lineWidth: 0,
      gridLineWidth: 0,
      lineColor: 'transparent',
    },
    colorAxis: {
      min: 0,
      minColor: '#ffffff',
      maxColor: '#485DA0',
    },
    tooltip: {
      ...baseConfig.tooltip,
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        return quantity(this.point.value || 0, 'kWh')
      },
    },
    legend: {
      ...baseConfig.legend,
      enabled: false,
    },
    exporting: {
      ...baseConfig.exporting,
      type: 'image/png',
    },
    series: [
      {
        type: 'heatmap',
        name: 'Average Consumption by Hour',
        data: data.flat(),
      },
    ],
  }

  return (
    <>
      <HighchartsReact
        id={title}
        highcharts={Highcharts}
        options={options as unknown as Options}
        ref={chartRef}
        {...props}
      />
    </>
  )
}

export default OperatingHoursHeatMapChart
