import {
  useEnergyStarSitesCountQuery,
  useMeterMappingDataQuery,
  usePendingPropertiesQuery,
} from '.'
import { getUsername } from '../../api/utils'

export const useESPendingPropertiesCount = () => {
  const pendingPropertiesQuery = usePendingPropertiesQuery()
  const meterMappingDataQuery = useMeterMappingDataQuery()

  const count = Math.min(
    pendingPropertiesQuery.data?.length ?? 0,
    meterMappingDataQuery.data?.filter(
      (item) => !item.esPropertyData.isDisabled
    ).length ?? 0
  )
  return count
}

export const useShowESPropertiesNotification = () => {
  const count = useESPendingPropertiesCount()
  const energyStarSitesCountQuery = useEnergyStarSitesCountQuery()
  if (count < 1) {
    return false
  }

  const username = getUsername()
  const isInternal = username.includes('@redaptiveinc.com')
  return isInternal || !energyStarSitesCountQuery.data?.siteMappingEnabled
}
