import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { TSMetaState } from './types'
import { defaultHeaders, denaliApiBaseUrl } from '../api/index'
import { handleAxiosError, handleSagaError } from '../api/utils'
import customersMockData from '../mockData/customers'
import { filterDemoCustomers } from '../utils/demo'
import { isVariantActive } from '../utils/variants'

export interface TSCustomerResponse {
  customerLogoUrl?: string
  externalId?: string
  id: string
  name: string
  notifyDataBehind: boolean
  validName: string
  initials?: string
  logoBackgroundColor?: string
}

export type TSCustomersResponse = TSCustomerResponse[]

export type TSCustomer = TSCustomerResponse

interface TSCustomersMetaState extends TSMetaState {
  noCustomers: boolean
}

export interface TSCustomersEntityState {
  byId: {
    [id: string]: TSCustomer
  }
  items: Array<TSCustomer>
  meta: TSCustomersMetaState
  preventCustomerChange: boolean
}

interface TSState {
  entities: {
    customers: TSCustomersEntityState
  }
}

export const types = {
  FETCH_CUSTOMERS: 'FETCH_CUSTOMERS',
  FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_ERROR: 'FETCH_CUSTOMERS_ERROR',
  SWITCH_CUSTOMER: 'SWITCH_CUSTOMER',
  PREVENT_CUSTOMER_CHANGE: 'PREVENT_CUSTOMER_CHANGE',
}

export const actions = {
  fetchCustomers: () => ({ type: types.FETCH_CUSTOMERS }),
  preventCustomerChange: (prevent: boolean) => ({
    type: types.PREVENT_CUSTOMER_CHANGE,
    payload: prevent,
  }),
}

export const initialState: TSCustomersEntityState = {
  byId: {},
  items: [],
  meta: {
    loading: true,
    noCustomers: false,
    error: '',
  },
  preventCustomerChange: false,
}

function entityById(action, state) {
  return {
    ...state,
    ...action.payload.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  }
}

function entityItems(action, state) {
  const newItems: Array<TSCustomer> = Object.values(action.payload)
  return state
    .filter((item) => !newItems.find((newItem) => newItem.id === item.id))
    .concat(newItems)
}

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMERS:
      return initialState.byId
    case types.FETCH_CUSTOMERS_SUCCESS:
      return entityById(action, state)
    default:
      return state
  }
}

function items(state = initialState.items, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMERS:
      return initialState.items
    case types.FETCH_CUSTOMERS_SUCCESS:
      return entityItems(action, state)
    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_CUSTOMERS:
      return {
        ...state,
        error: '',
        noCustomers: false,
      }
    case types.FETCH_CUSTOMERS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case types.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noCustomers: !action.payload.length,
      }
    default:
      return state
  }
}

function preventCustomerChange(
  state = initialState.preventCustomerChange,
  action
) {
  switch (action.type) {
    case types.PREVENT_CUSTOMER_CHANGE:
      return action.payload
    default:
      return state
  }
}

export default combineReducers({
  byId,
  items,
  meta,
  preventCustomerChange,
})

export const selectCustomersEntity = (state: TSState): TSCustomersEntityState =>
  state.entities.customers

export const enhanceCustomer = (customer: TSCustomerResponse): TSCustomer =>
  customer

export const API = {
  fetchCustomers: () => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customersMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const url = `${denaliApiBaseUrl()}/customers?page=0&size=10000`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: TSCustomersResponse }) => data)
      .catch(handleAxiosError)
  },
}

function* fetchCustomersSaga(): Generator<any, void, any> {
  try {
    let payload: TSCustomersResponse = yield call(API.fetchCustomers)
    // pass through demo filter, will apply any relevant filters if this is a demo user
    payload = filterDemoCustomers(payload)
    yield put({
      type: types.FETCH_CUSTOMERS_SUCCESS,
      payload,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_CUSTOMERS_ERROR, e as Error)
  }
}

export const sagas = [takeLatest(types.FETCH_CUSTOMERS, fetchCustomersSaga)]
