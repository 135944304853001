import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useCurrentCustomerStore } from '../globalState/currentCustomer'
import { useCustomers } from '../queries/customers'

export const useCustomerSelection = () => {
  const { data: customers } = useCustomers()
  const { currentCustomerId, setCurrentCustomerId } = useCurrentCustomerStore()
  const [searchParams] = useSearchParams()
  const customerId = searchParams.get('customerId')

  useEffect(() => {
    if (customers && !currentCustomerId) {
      setCurrentCustomerId(customers[0].id)
    }
  }, [customers, currentCustomerId, setCurrentCustomerId])

  useEffect(() => {
    if (!customerId) return
    const canAccessCustomer = customers?.map((c) => c.id).includes(customerId)
    const customerSwitchRequested = customerId !== currentCustomerId
    if (customerSwitchRequested && canAccessCustomer) {
      setCurrentCustomerId(customerId)
    }
  }, [customers, customerId, currentCustomerId, setCurrentCustomerId])
}
