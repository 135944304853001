// Footer
export const FOOTER_HOME = 'Redaptive Home'
export const FOOTER_TERMS = 'Terms of Use'
export const FOOTER_PRIVACY = 'Privacy Policy'

// Corporate Site
export const COMPANY_URL = 'https://redaptive.com'
export const TERMS_URL = `${COMPANY_URL}/terms-use`
export const PRIVACY_URL = `${COMPANY_URL}/privacy-policy`

// Page Title
export const REDAPTIVE_ONE_PAGE_TITLE = 'Redaptive ONE'
export const ESG_PAGE_TITLE = 'ESG Reporting & Disclosures'
export const BENCHMARKING_PAGE_TITLE = 'Benchmarking'

export const LIST_SELECTOR_EMPTY = 'Unassigned'

//Opportunities
export const EMPTY_METRIC_STRING = '--'

export const ES_COORDINATOR_GROUP_ID = 'energy-star-coordinator'
export const MIT_GROUP_ID = 'meter-install-tracker-access'
// Login
export const supportEmail = 'support@redaptiveinc.com'
export const DENALI_ADMIN = 'denali-admin'
