import { useParams } from 'react-router-dom'

import { Grouping, MeasurementTypes, Resolution } from '../../../ducks/types'
import { DataExplorerDefaultFilters } from '../types'
import { defaultFilters } from './filterParams'

export const usePageDefaults = (): DataExplorerDefaultFilters => {
  const { pageId = 'consumption-analysis' } = useParams()

  const defaultsByPage = {
    'operating-hours': {
      resolution: Resolution.HOURLY,
    },
    'peak-demand': {
      resolution: Resolution.FIFTEEN_MINUTES,
    },
    'voltage-analysis': {
      grouping: Grouping.EQUIPMENT,
      resolution: Resolution.FIFTEEN_MINUTES,
      options: { singleSiteSelect: true },
      measurementTypes: MeasurementTypes.RMS_VOLTAGE,
    },
  }
  return { ...defaultFilters, ...defaultsByPage[pageId] }
}
