interface TSProps {
  color?: string
  size?: string
}

const ExpandedIcon = ({ color = '#162447', size = '24' }: TSProps) => (
  <svg fill='none' height={size} viewBox='0 0 18 18' width={size}>
    <path
      d='M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z'
      fill={color}
    />
  </svg>
)

export default ExpandedIcon
