import moment from 'moment'

import { Resources } from '../components/mantine/Quantity/types'
import { Period } from '../constants'

// common types

export enum Resolution {
  DAILY = '1day',
  HOURLY = '1hour',
  FIFTEEN_MINUTES = '15min',
  MONTHLY = '1month',
}

export enum Grouping {
  SITE = 'site',
  PANEL = 'panel',
  BUILDING_SYSTEM = 'buildingSystem',
  EQUIPMENT = 'equipment',
  CIRCUIT = 'circuit',
  METER = 'meter',
  CATEGORY_VALUE = 'categoryValue',
}

export enum MeasurementTypes {
  ACTIVE_ENERGY = 'activeEnergy',
  RMS_VOLTAGE = 'rmsVoltage',
  VOLUME = 'volume',
}

export type TSQuerySearchParams = {
  panel?: string
  buildingSystem?: string
  categoryValue?: string
  circuit?: string
  equipment?: string
  fromDate?: string
  grouping: Grouping
  groupingId?: string
  meter?: string
  period: Period
  resolution: Resolution
  resourceType: ResourceType
  measurementTypes?: string
  site: string
  toDate?: string
}

// this is what the API sends back for the response data:
export interface ItemUsage {
  groupName: string
  groupId: string
  calcSpend?: number
  activeEnergy?: Array<number>
  volume?: Array<number>
  rmsVoltage?: Array<number>
}

export enum ResourceType {
  ELECTRICITY = 'electricity',
  NATURAL_GAS = 'natural-gas',
  WATER = 'water',
  ALL = 'all',
}

export const toResource: Record<
  Exclude<ResourceType | 'naturalGas', 'all'>,
  Resources
> = {
  [ResourceType.ELECTRICITY]: 'electric',
  [ResourceType.NATURAL_GAS]: 'gas',
  naturalGas: 'gas',
  [ResourceType.WATER]: 'water',
}

export type TSDateRange = {
  toDate: moment.Moment
  fromDate: moment.Moment
}

export interface TSMetaState {
  loading: boolean
  error: string
}

export const initialMetaState = {
  loading: false,
  error: '',
}

export const loadingMetaState = {
  loading: true,
  error: '',
}

export interface TSListResponseMetaState extends TSMetaState {
  noResults: boolean
  currentId: string
}

export const initialListResponseMetaState = {
  ...initialMetaState,
  noResults: false,
  currentId: '',
}

export const successAction = (baseAction: string) => {
  return `${baseAction}_SUCCESS`
}

export const errorAction = (baseAction: string) => {
  return `${baseAction}_ERROR`
}
