import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import _, { isEmpty, isNil, sortBy, sum } from 'lodash'
import { Moment } from 'moment'

import { defaultHeaders, denaliApiBaseUrl } from '../api'
import { getDateRange, getRequestRange, queryStringify } from '../api/utils'
import { ImperialUnits } from '../components/mantine/Quantity/types'
import { Grouping, ItemUsage, Resolution, ResourceType } from '../ducks/types'
import {
  DataExplorerFilterParams,
  ParsedDataExplorerSearchParams,
  UUID,
} from '../pages/DataExplorer/types'
import { filterDemoCircuitData } from '../utils/demo'
import { enhanceAxiosError } from './utils/enhanceAxiosError'
import { useCurrentCustomerStore } from '../globalState/currentCustomer'

export interface UsageDataResponse {
  data: Array<ItemUsage>
  from: string
  to: string
  grouping: Grouping
  resolution: Resolution
  measurementTypes: Array<string>
  units: Array<ImperialUnits>
  ts: Array<string>
}

export const customFiltersToCategoryValue = (
  params: ParsedDataExplorerSearchParams
) => {
  const categoryValue = _(params)
    .pickBy((_, k) => k.startsWith('categoryValue-'))
    .values()
    .flatten()
    .value()
    .filter(Boolean)
  return categoryValue ? categoryValue : undefined
}

export const customGroupingToPayload = (
  grouping: Grouping | UUID | undefined
) => {
  if (
    grouping &&
    !(Object.values(Grouping) as (Grouping | UUID)[]).includes(grouping)
  ) {
    return {
      groupingId: grouping,
      grouping: Grouping.CATEGORY_VALUE,
    }
  } else {
    return { grouping }
  }
}

const fetchUsageData = (
  customerId: UUID,
  params: DataExplorerFilterParams,
  programStartDate?: Moment
) => {
  const { startDate, endDate } = getDateRange(
    params.fromDate,
    params.toDate,
    params.period,
    programStartDate
  )

  const query = {
    ...params,
    ...getRequestRange(startDate, endDate),
    site: params.siteIds?.join(','),
    categoryValue: customFiltersToCategoryValue(params)?.join(','),
    ...customGroupingToPayload(params.grouping),
  }

  const queryFiltered = _(query)
    .omitBy(isNil)
    .omitBy(isEmpty)
    .omitBy((_, k) => k.startsWith('categoryValue-'))
    .omit('period', 'resourceType', 'siteIds', 'selectedSiteIds', 'options')
    .valueOf()
  const queryStr = decodeURIComponent(queryStringify(queryFiltered))
  const url = `${denaliApiBaseUrl()}/customers/${customerId}/usage/${
    params.resourceType
  }?${queryStr}`

  return axios
    .get<UsageDataResponse>(url, { headers: defaultHeaders() })
    .then(({ data }) => {
      filterDemoCircuitData(data)
      return { groups: data.data, ts: data.ts, units: data.units }
    })
    .catch(enhanceAxiosError)
}

export type EnhancedGroupData = ReturnType<typeof enhanceGroupUsageData>[0]
const enhanceGroupUsageData = (data: ItemUsage[], resourceType: ResourceType) =>
  data.map((group) => ({
    ...group,
    usage: group.activeEnergy ?? group.volume,
    kWUsage:
      resourceType == ResourceType.ELECTRICITY
        ? group?.activeEnergy?.map((value) => value * 4) // convert 15min of kWh to kW
        : undefined,
    totalUsage: sum(group.activeEnergy ?? group.volume),
  }))

export const useResourceUsageData = (
  params: DataExplorerFilterParams,
  programStartDate?: Moment,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options ?? {}
  const { currentCustomerId } = useCurrentCustomerStore()
  return useQuery({
    queryKey: [
      'resourceUsage',
      params.resourceType,
      currentCustomerId,
      { params },
    ],
    queryFn: () => fetchUsageData(currentCustomerId, params, programStartDate),
    enabled: !!currentCustomerId && !!params.resourceType && enabled,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: ({ groups, ts, units }) => ({
      groups: sortBy(
        enhanceGroupUsageData(groups, params.resourceType),
        'totalUsage'
      ).reverse(),
      ts,
      units,
    }),
  })
}
