interface TSProps {
  checked?: boolean
  color?: string
  size?: string
  onClick?: (event: any) => void
}

const BookmarkIcon = ({
  color = '#fff',
  size = '22',
  onClick = () => ({}),
}: TSProps) =>
  color == '#fff' ? (
    <svg
      onClick={onClick}
      fill='none'
      height={size}
      viewBox='0 0 24 24'
      width={size}
      style={{ cursor: 'pointer' }}
    >
      <path
        fill='#485da0'
        d='M16,2H8A3,3,0,0,0,5,5V21a1,1,0,0,0,.5.87,1,1,0,0,0,1,0L12,18.69l5.5,3.18A1,1,0,0,0,18,22a1,1,0,0,0,.5-.13A1,1,0,0,0,19,21V5A3,3,0,0,0,16,2Zm1,17.27-4.5-2.6a1,1,0,0,0-1,0L7,19.27V5A1,1,0,0,1,8,4h8a1,1,0,0,1,1,1Z'
      ></path>
    </svg>
  ) : (
    <svg
      onClick={onClick}
      fill='none'
      height={size}
      viewBox='0 0 24 24'
      width={size}
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M16,2H8C6.3,2,5,3.3,5,5v16c0,0.2,0,0.3,0.1,0.5C5.4,22,6,22.1,6.5,21.9l5.5-3.2l5.5,3.2C17.7,22,17.8,22,18,22c0.6,0,1-0.4,1-1V5C19,3.3,17.7,2,16,2z'
        fill='#485da0'
      />
    </svg>
  )

export default BookmarkIcon
