const EmptyStateSvg = (props) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='155'
        height='123'
        viewBox='0 0 155 123'
        fill='none'
        {...props}
      >
        <path
          d='M61.9206 122.122C95.6436 122.122 122.981 94.7837 122.981 61.0608C122.981 27.3378 95.6436 0 61.9206 0C28.1977 0 0.859863 27.3378 0.859863 61.0608C0.859863 94.7837 28.1977 122.122 61.9206 122.122Z'
          fill='url(#paint0_linear_211_6105)'
        />
        <g filter='url(#filter0_d_211_6105)'>
          <path
            d='M34.2402 27.6807H144.15C145.229 27.6807 146.265 28.1096 147.028 28.873C147.791 29.6364 148.22 30.6718 148.22 31.7514V52.105C148.22 53.1846 147.791 54.22 147.028 54.9834C146.265 55.7468 145.229 56.1757 144.15 56.1757H34.2402C33.1605 56.1757 32.1251 55.7468 31.3617 54.9834C30.5983 54.22 30.1694 53.1846 30.1694 52.105V31.7514C30.1694 30.6718 30.5983 29.6364 31.3617 28.873C32.1251 28.1096 33.1605 27.6807 34.2402 27.6807V27.6807Z'
            fill='white'
          />
        </g>
        <path
          d='M86.3455 34.1939H65.1778C63.8289 34.1939 62.7354 35.2874 62.7354 36.6363C62.7354 37.9853 63.8289 39.0788 65.1778 39.0788H86.3455C87.6944 39.0788 88.7879 37.9853 88.7879 36.6363C88.7879 35.2874 87.6944 34.1939 86.3455 34.1939Z'
          fill='#B4DAFF'
        />
        <path
          d='M101 44.7776H65.1778C63.8289 44.7776 62.7354 45.8712 62.7354 47.2201C62.7354 48.569 63.8289 49.6625 65.1778 49.6625H101C102.349 49.6625 103.443 48.569 103.443 47.2201C103.443 45.8712 102.349 44.7776 101 44.7776Z'
          fill='#DEE9FC'
        />
        <path
          d='M56.222 41.9278C56.222 35.408 50.9367 30.1227 44.4169 30.1227C37.8971 30.1227 32.6118 35.408 32.6118 41.9278C32.6118 48.4475 37.8971 53.7328 44.4169 53.7328C50.9367 53.7328 56.222 48.4475 56.222 41.9278Z'
          fill='#485DA0'
        />
        <path
          d='M43.6846 45.7494C44.6953 45.7493 45.6768 45.4111 46.4731 44.7887L48.9766 47.2922L49.7818 46.487L47.2775 43.9827C47.9067 43.1692 48.2418 42.1666 48.2282 41.1383C48.2146 40.11 47.8532 39.1166 47.2027 38.32C46.5523 37.5234 45.6512 36.9705 44.6464 36.7516C43.6415 36.5327 42.5922 36.6605 41.6693 37.1143C40.7464 37.5681 40.0045 38.3211 39.5643 39.2506C39.1242 40.1801 39.0118 41.2312 39.2456 42.2327C39.4793 43.2342 40.0454 44.127 40.8515 44.7656C41.6576 45.4043 42.6562 45.751 43.6846 45.7494ZM43.6846 37.7757C44.3609 37.7757 45.022 37.9762 45.5844 38.352C46.1467 38.7277 46.585 39.2617 46.8438 39.8865C47.1026 40.5113 47.1703 41.1989 47.0383 41.8622C46.9064 42.5255 46.5807 43.1348 46.1025 43.613C45.6243 44.0912 45.015 44.4168 44.3517 44.5488C43.6884 44.6807 43.0009 44.613 42.3761 44.3542C41.7513 44.0954 41.2172 43.6571 40.8415 43.0948C40.4658 42.5325 40.2652 41.8714 40.2652 41.1951C40.2652 40.2882 40.6255 39.4185 41.2668 38.7772C41.908 38.1359 42.7778 37.7757 43.6846 37.7757Z'
          fill='white'
        />
        <g filter='url(#filter1_d_211_6105)'>
          <path
            d='M131.937 64.3172H22.0277C19.7796 64.3172 17.957 66.1398 17.957 68.3879V88.7415C17.957 90.9897 19.7796 92.8123 22.0277 92.8123H131.937C134.185 92.8123 136.008 90.9897 136.008 88.7415V68.3879C136.008 66.1398 134.185 64.3172 131.937 64.3172Z'
            fill='white'
          />
        </g>
        <path
          d='M74.1331 70.8304H52.9654C51.6165 70.8304 50.5229 71.9239 50.5229 73.2728C50.5229 74.6218 51.6165 75.7153 52.9654 75.7153H74.1331C75.482 75.7153 76.5755 74.6218 76.5755 73.2728C76.5755 71.9239 75.482 70.8304 74.1331 70.8304Z'
          fill='#B4DAFF'
        />
        <path
          d='M88.7877 81.4142H52.9654C51.6165 81.4142 50.5229 82.5077 50.5229 83.8566C50.5229 85.2055 51.6165 86.299 52.9654 86.299H88.7877C90.1366 86.299 91.2301 85.2055 91.2301 83.8566C91.2301 82.5077 90.1366 81.4142 88.7877 81.4142Z'
          fill='#DEE9FC'
        />
        <path
          d='M44.0101 78.5649C44.0101 72.0451 38.7248 66.7598 32.205 66.7598C25.6852 66.7598 20.3999 72.0451 20.3999 78.5649C20.3999 85.0847 25.6852 90.37 32.205 90.37C38.7248 90.37 44.0101 85.0847 44.0101 78.5649Z'
          fill='#485DA0'
        />
        <path
          d='M31.4727 82.3865C32.4834 82.3864 33.4649 82.0482 34.2612 81.4259L36.7647 83.9294L37.5699 83.1242L35.0655 80.6199C35.6947 79.8064 36.0299 78.8038 36.0163 77.7754C36.0027 76.7471 35.6413 75.7537 34.9908 74.9571C34.3404 74.1605 33.4393 73.6077 32.4345 73.3888C31.4296 73.1698 30.3803 73.2977 29.4574 73.7515C28.5345 74.2053 27.7925 74.9583 27.3524 75.8878C26.9123 76.8172 26.7999 77.8684 27.0337 78.8699C27.2674 79.8714 27.8335 80.7642 28.6396 81.4028C29.4457 82.0414 30.4443 82.3882 31.4727 82.3865ZM31.4727 74.4128C32.149 74.4128 32.8101 74.6134 33.3724 74.9891C33.9348 75.3648 34.373 75.8989 34.6318 76.5237C34.8907 77.1485 34.9584 77.836 34.8264 78.4993C34.6945 79.1626 34.3688 79.7719 33.8906 80.2501C33.4124 80.7283 32.8031 81.054 32.1398 81.1859C31.4765 81.3179 30.789 81.2502 30.1642 80.9913C29.5394 80.7325 29.0053 80.2943 28.6296 79.7319C28.2539 79.1696 28.0533 78.5085 28.0533 77.8322C28.0533 76.9253 28.4136 76.0556 29.0548 75.4143C29.6961 74.7731 30.5658 74.4128 31.4727 74.4128Z'
          fill='white'
        />
        <defs>
          <filter
            id='filter0_d_211_6105'
            x='24.1694'
            y='24.6807'
            width='130.051'
            height='40.495'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='3' />
            <feGaussianBlur stdDeviation='3' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_211_6105'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_211_6105'
              result='shape'
            />
          </filter>
          <filter
            id='filter1_d_211_6105'
            x='11.957'
            y='61.3172'
            width='130.051'
            height='40.495'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='3' />
            <feGaussianBlur stdDeviation='3' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_211_6105'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_211_6105'
              result='shape'
            />
          </filter>
          <linearGradient
            id='paint0_linear_211_6105'
            x1='61.9206'
            y1='0'
            x2='61.9206'
            y2='122.122'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#E3ECFA' />
            <stop offset='1' stopColor='#DAE7FF' />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default EmptyStateSvg
