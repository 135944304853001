import { UilTimes } from '@iconscout/react-unicons'
import { Flex, Stack, Text } from '@mantine/core'
import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

type TSVariant = 'default' | 'micro' | 'inline'

const previewLinkPaddingTop: { [Property in TSVariant]: string } = {
  default: '4px',
  inline: '3px',
  micro: '0',
}

export const PreviewLink = styled.a<{
  variant?: TSVariant
}>`
  text-decoration: none;
  color: #485da0;
  display: flex;
  align-items: center;
  flex-basis: content;
  flex-grow: 1;
  gap: 8px;
  padding-top: ${({ variant }) => previewLinkPaddingTop[variant]};

  &:hover {
    cursor: pointer;
  }
  > svg {
    flex-shrink: 0;
  }
`

interface TSProps {
  title: string | ReactNode
  metaText?: Array<string>
  onClose?: (...args: any) => void
  onClick?: (...args: any) => void
  href?: string
  variant?: TSVariant
  fileIcon?: ReactElement
}

const FileThumbnailPreview = ({
  title,
  metaText,
  onClick,
  onClose,
  href,
  fileIcon,
  variant = 'default',
}: TSProps) => {
  return (
    <Flex
      p={`${variant == 'default' ? '4px 4px 8px 16px' : '4px 4px 8px 8px'}`}
      bg='white'
      mih={`${variant == 'default' ? '78px' : '50px'}`}
      style={{ borderRadius: '8px', border: '1px solid #E0E0E0' }}
    >
      <PreviewLink
        href={href && variant != 'micro' ? href : undefined}
        onClick={onClick}
        target='_blank'
        variant={variant}
      >
        {variant != 'micro' && fileIcon}
        <Stack gap='xs'>
          {typeof title == 'string' ? (
            <Text
              fw='600'
              lh='normal'
              lineClamp={2}
              style={{ textTransform: 'capitalize' }}
            >
              {title}
            </Text>
          ) : (
            title
          )}
          {metaText?.length && (
            <Flex align='center'>
              {metaText?.map((text, index) => {
                return (
                  <>
                    <Text
                      key={`${text}-${index}`}
                      c='#6C6D6E'
                      size='12px'
                      lh='normal'
                      fw='600'
                      style={{ textTransform: 'uppercase' }}
                    >
                      {text}
                    </Text>
                    {index != metaText.length - 1 && (
                      <Text c='#E0E0E0' mx='8px'>
                        |
                      </Text>
                    )}
                  </>
                )
              })}
            </Flex>
          )}
        </Stack>
      </PreviewLink>
      {variant != 'micro' && onClose && (
        <Flex
          align='center'
          justify='center'
          w={16}
          h={16}
          bg='#E0E0E0'
          style={{ borderRadius: '100%', cursor: 'pointer' }}
          onClick={onClose}
        >
          <UilTimes size={16} color='#6C6D6E' />
        </Flex>
      )}
    </Flex>
  )
}

export default FileThumbnailPreview
