import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import {
  UilAngleDoubleDown,
  UilAngleDoubleUp,
  UilDraggabledots,
  UilPlusCircle,
} from '@iconscout/react-unicons'
import {
  Box,
  Button as MantineButton,
  Checkbox,
  Flex,
  Group,
  Stack,
  Text,
} from '@mantine/core'
import { useListState } from '@mantine/hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'

import Spinner from '../Spinner'
import ProjectTaskCard from './ProjectTaskCard/ProjectTaskCard'
import {
  useCreateCustomerTask,
  useFetchUserEmails,
  useReorderCustomerTask,
  useUpdateCustomerTask,
  useUpdateCustomerTaskStatus,
} from '../../queries/allProjects'
import {
  TSCreateCustomerTaskPayload,
  TSOpportunityTaskResponse,
  TSReorderCustomerTaskPayload,
  TSUpdateCustomerTaskPayload,
  TSUpdateCustomerTaskStatusPayload,
} from '../../queries/allProjects/types'

export type ProjectTasksSectionTypes = {
  projectTasks: Array<TSOpportunityTaskResponse>
  isTasksLoading: boolean
  projectId: string
  isBatch: boolean
  currentCustomerId: string
  setShowDeleteTaskModal: React.Dispatch<React.SetStateAction<boolean>>
  setTaskSelectedforRemoval: React.Dispatch<React.SetStateAction<string>>
}

const ProjectTasksSection = ({
  isTasksLoading,
  projectTasks,
  projectId,
  isBatch,
  currentCustomerId,
  setShowDeleteTaskModal,
  setTaskSelectedforRemoval,
}: ProjectTasksSectionTypes) => {
  const { data: userEmails } = useFetchUserEmails(currentCustomerId)
  const { mutate: reorderTask } = useReorderCustomerTask()
  const { mutate: updateTaskStatus } = useUpdateCustomerTaskStatus()
  const { mutate: updateTask } = useUpdateCustomerTask()
  const { mutate: createTask } = useCreateCustomerTask()
  const formattedUserEmails = userEmails?.map((user) => ({
    label: user.email,
    value: user.email,
  }))

  // Sort tasks based on sequenceNumber, with completed tasks appearing below incomplete tasks
  const sortedTasks = projectTasks.sort((a, b) => {
    if (a.status === 'COMPLETED' && b.status !== 'COMPLETED') {
      return 1
    } else if (a.status !== 'COMPLETED' && b.status === 'COMPLETED') {
      return -1
    } else {
      return a.sequenceNumber - b.sequenceNumber
    }
  })

  const [tasks, tasksHandler] = useListState(sortedTasks.slice(0, 3))
  const [newTaskButtonVisible, setNewTaskButtonVisible] = useState(true)
  const [showCompletedTasksOnly, setShowCompletedTasksOnly] = useState(false)

  const handleCancelFromTask = (id) => {
    setNewTaskButtonVisible(true)
    const updatedTasks = tasks?.filter(
      (task) => !(task.id === id && task.isNew)
    )
    tasksHandler.setState(updatedTasks)
  }
  useEffect(() => {
    if (showCompletedTasksOnly) {
      tasksHandler.setState(
        tasks.length > 3
          ? getCompletedTasks(projectTasks)
          : getCompletedTasks(projectTasks).slice(0, 3)
      )
    } else {
      tasksHandler.setState(
        tasks.length > 3 ? projectTasks : projectTasks.slice(0, 3)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTasks])

  const handleSaveFromTask = ({ formData }) => {
    const {
      data: {
        id,
        title,
        description,
        dueDate,
        assignedToEmail,
        sequenceNumber,
        status,
      },
      isNew,
    } = formData
    if (isNew) {
      const createCustomerTaskPayload: TSCreateCustomerTaskPayload = {
        title,
        description,
        assignedToEmail: assignedToEmail.value,
        dueDate: dueDate ? moment(dueDate).format() : null,
        projectOpportunityId: !isBatch ? projectId : undefined,
        projectBatchId: isBatch ? projectId : undefined,
        customerId: currentCustomerId,
        sequenceNumber,
        status,
      }
      createTask(createCustomerTaskPayload)
    } else {
      const updateCustomerTaskPayload: TSUpdateCustomerTaskPayload = {
        id,
        title,
        description,
        assignedToEmail: assignedToEmail.value,
        dueDate: dueDate ? moment(dueDate).format() : null,
        sequenceNumber,
        status,
      }
      updateTask(updateCustomerTaskPayload)
    }
    setNewTaskButtonVisible(true)
  }

  const handleCheckboxClickFromTask = ({ statusData }) => {
    const { taskId, isChecked } = statusData
    const updateCustomerTaskStatusPayload: TSUpdateCustomerTaskStatusPayload = {
      taskId,
      status: isChecked ? 'COMPLETED' : 'IN_PROGRESS',
      customerId: currentCustomerId,
    }
    updateTaskStatus(updateCustomerTaskStatusPayload)
  }

  const handleRemoveTaskFromTask = (taskId) => {
    setShowDeleteTaskModal(true)
    setTaskSelectedforRemoval(taskId)
  }

  const getLowestSequenceNumber = (tasks) => {
    // case if this is the first task
    if (tasks.length <= 0) {
      return 999 * 2
    } else {
      return tasks.reduce((minSequenceNumber, task) => {
        return task.sequenceNumber < minSequenceNumber
          ? task.sequenceNumber
          : minSequenceNumber
      }, 999)
    }
  }

  const addNewTask = () => {
    setNewTaskButtonVisible(false)

    const newTask: TSOpportunityTaskResponse = {
      id: (tasks.length + 1).toString(),
      title: '',
      description: '',
      assignedToEmail: null,
      dueDate: null as Date | null,
      isNew: true,
      sequenceNumber: getLowestSequenceNumber(projectTasks) / 2,
      status: 'IN_PROGRESS',
    }

    tasksHandler.setState([newTask, ...tasks])
  }
  const getCompletedTasks = (tasks) =>
    tasks?.filter((task) => task.status == 'COMPLETED')
  return (
    <Stack my='xl' gap='xl'>
      <Group justify='space-between' h={30} pl='24px'>
        <Text c='black' fw='700'>
          Tasks
        </Text>

        {newTaskButtonVisible && (
          <MantineButton
            leftSection={<UilPlusCircle size={16} />}
            variant='default'
            radius='md'
            size='compact-md'
            px='12px'
            onClick={addNewTask}
          >
            Add task
          </MantineButton>
        )}
      </Group>

      {(showCompletedTasksOnly ||
        getCompletedTasks(projectTasks).length > 0) && (
        <Checkbox
          checked={showCompletedTasksOnly}
          onChange={(event) => {
            setShowCompletedTasksOnly(event.currentTarget.checked)
            if (event.currentTarget.checked) {
              if (tasks.length > 3) {
                tasksHandler.setState(getCompletedTasks(projectTasks))
              } else {
                tasksHandler.setState(
                  getCompletedTasks(projectTasks).slice(0, 3)
                )
              }
            } else {
              if (tasks.length > 3) {
                tasksHandler.setState(projectTasks)
              } else {
                tasksHandler.setState(projectTasks.slice(0, 3))
              }
              setNewTaskButtonVisible(true)
            }
          }}
          label='Show completed tasks only'
          pl='24px'
          color='blue.5'
        />
      )}

      {!isTasksLoading && tasks.length == 0 && (
        <Text c='gray.6' pl='24px'>
          {showCompletedTasksOnly
            ? 'No completed tasks to show'
            : 'No tasks have been added yet'}
        </Text>
      )}
      {isTasksLoading && <Spinner centered />}

      {!isTasksLoading && tasks.length > 0 && (
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (!destination) return
            if (destination.index == source.index) return

            const destinationIndex = destination.index
            // Case: if dragged to completed tasks
            if (tasks[destinationIndex].status == 'COMPLETED') return

            let previousTaskSequenceNumber
            let nextTaskSequenceNumber

            if (source.index < destinationIndex) {
              // Case: when dragging downwards
              previousTaskSequenceNumber =
                destinationIndex > 0
                  ? tasks[destinationIndex].sequenceNumber
                  : 0
              nextTaskSequenceNumber =
                destinationIndex < tasks.length - 1
                  ? tasks[destinationIndex + 1].sequenceNumber
                  : 0
            } else if (source.index > destinationIndex) {
              // Case: when dragging upwards
              previousTaskSequenceNumber =
                destinationIndex > 0
                  ? tasks[destinationIndex - 1].sequenceNumber
                  : 0
              nextTaskSequenceNumber =
                destinationIndex < tasks.length - 1
                  ? tasks[destinationIndex].sequenceNumber
                  : 0
            }

            const newSequenceNumber =
              nextTaskSequenceNumber == 0
                ? previousTaskSequenceNumber + 1
                : (previousTaskSequenceNumber + nextTaskSequenceNumber) / 2

            tasksHandler.reorder({
              from: source.index,
              to: destination.index,
            })

            const reorderCustomerTaskPayload: TSReorderCustomerTaskPayload = {
              taskId: tasks[source.index].id,
              sequenceNumber: newSequenceNumber,
            }
            reorderTask(reorderCustomerTaskPayload)
          }}
        >
          <Droppable droppableId='droppable'>
            {(provided) => (
              <Stack {...provided.droppableProps} ref={provided.innerRef}>
                {tasks?.map((task, index) => (
                  <Draggable
                    key={task.id}
                    draggableId={task.id}
                    index={index}
                    isDragDisabled={task.status == 'COMPLETED'}
                  >
                    {(provided) => (
                      <Flex
                        w='100%'
                        gap='0'
                        align='center'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        {task.status !== 'COMPLETED' ? (
                          <Flex
                            {...provided.dragHandleProps}
                            w='24px'
                            h='24px'
                            align='center'
                            justify='center'
                          >
                            <UilDraggabledots size={16} />
                          </Flex>
                        ) : null}
                        <Box
                          w='100%'
                          pl={task.status !== 'COMPLETED' ? '0px' : '24px'}
                        >
                          <ProjectTaskCard
                            id={task.id}
                            title={task.title}
                            description={task.description}
                            assignedToEmail={task.assignedToEmail}
                            date={task.dueDate}
                            assignees={formattedUserEmails}
                            isNew={task.isNew}
                            handleCancelInParent={handleCancelFromTask}
                            sequenceNumber={task.sequenceNumber}
                            status={task.status}
                            handleSaveInParent={handleSaveFromTask}
                            handleCheckboxClickInParent={
                              handleCheckboxClickFromTask
                            }
                            handleRemoveTaskInParent={handleRemoveTaskFromTask}
                          />
                        </Box>
                      </Flex>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {((projectTasks.length > 3 && !showCompletedTasksOnly) ||
        (showCompletedTasksOnly &&
          getCompletedTasks(projectTasks).length > 3)) && (
        <Group
          gap='0px'
          align='center'
          pl='24px'
          onClick={() => {
            if (showCompletedTasksOnly) {
              tasksHandler.setState(
                tasks.length > 3
                  ? getCompletedTasks(projectTasks).slice(0, 3)
                  : getCompletedTasks(projectTasks)
              )
            } else {
              tasksHandler.setState(
                tasks.length > 3 ? projectTasks.slice(0, 3) : projectTasks
              )
            }
          }}
          style={{ cursor: 'pointer' }}
          w='max-content'
        >
          <Text c='blue.6'>
            {tasks.length > 3 && 'Show fewer tasks'}
            {tasks.length <= 3 &&
              !showCompletedTasksOnly &&
              `Show all ${projectTasks.length} tasks`}
            {tasks.length <= 3 &&
              showCompletedTasksOnly &&
              `Show all ${getCompletedTasks(projectTasks).length} tasks`}
          </Text>
          {tasks.length > 3 ? (
            <UilAngleDoubleUp color='#43558E' />
          ) : (
            <UilAngleDoubleDown color='#43558E' />
          )}
        </Group>
      )}
    </Stack>
  )
}

export default ProjectTasksSection
