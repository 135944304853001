import Highcharts, {
  AxisLabelsFormatterContextObject,
  Options,
} from 'highcharts'
import DraggablePoints from 'highcharts/modules/draggable-points'
import Exporting from 'highcharts/modules/exporting'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import { useTheme } from 'styled-components'

import { useThousandsSeparatorPreference } from '../../queries/settings'
import { getNumberWithPrecision } from '../../utils'
import {
  config as baseConfig,
  getScrollingColumnEvents,
} from '../../utils/chartsDefaultConfig'
import { getToolTipFormatted } from '../CircuitsUsageDataView/utils'
import { useFormatNumber } from '../mantine/Quantity/FormatNumber'
Exporting(Highcharts)
NoDataToDisplay(Highcharts)
DraggablePoints(Highcharts)

const groupName = ({ name, defaultOpHoursUsed }) => {
  const defaultOpHoursMarker = defaultOpHoursUsed ? '*' : ''
  return `${defaultOpHoursMarker}${name}`
}

interface TSProps extends HighchartsReact.Props {
  chartRef: any
  groupDayHourUsage: Array<{
    groupName: string
    insideOpHoursUsage: number
    outsideOpHoursUsage: number
    defaultOpHoursUsed: boolean
  }>
  loading: boolean
  title: string
}

//TODO: why is this working here, but not when passing it in via React object below??
Highcharts.setOptions({ lang: { thousandsSep: ',' } })

const OperatingHoursBarChart = (props: TSProps) => {
  const theme = useTheme()
  const { chartRef, groupDayHourUsage = [], title, loading } = props
  const formatNumber = useFormatNumber()
  const { data: thousandsSeparator } = useThousandsSeparatorPreference()

  const inColor = '#e0e0e0' //224, 224, 224
  const outColor = theme.colors.primary //72, 93, 160
  const yAxisTitleAlign = groupDayHourUsage.length > 20 ? 'high' : 'middle'

  const seriesDataOut = groupDayHourUsage.map((group) => {
    return {
      name: groupName({
        name: group.groupName,
        defaultOpHoursUsed: group.defaultOpHoursUsed,
      }),
      y: getNumberWithPrecision(group.outsideOpHoursUsage || 0, 2),
    }
  })

  const seriesDataIn = groupDayHourUsage.map((group) => {
    return {
      name: group.groupName || '',
      y: getNumberWithPrecision(group?.insideOpHoursUsage || 0, 2),
    }
  })

  const scrollingEvents =
    groupDayHourUsage.length > 20 ? getScrollingColumnEvents() : {}

  const options: Options = {
    ...baseConfig,
    title: {
      ...baseConfig.title,
      text: title,
    },
    chart: {
      ...baseConfig.chart,
      events: {
        ...scrollingEvents,
      },
    },
    tooltip: {
      ...baseConfig.tooltip,
      formatter: getToolTipFormatted({
        format: thousandsSeparator,
        suffix: 'kWh',
      }),
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      ...baseConfig.plotOptions,
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
        borderRadius: 2,
      },
    },
    xAxis: {
      ...baseConfig.xAxis,
      categories: seriesDataOut.map((series) => series.name),
      max: groupDayHourUsage.length > 20 ? 19 : groupDayHourUsage.length - 1,
    },
    series: [
      {
        name: 'Inside Operating Hours',
        data: loading ? [] : seriesDataIn,
        type: 'column',
        color: inColor,
      },
      {
        name: 'Outside Operating Hours',
        data: loading ? [] : seriesDataOut,
        type: 'column',
        pointPlacement: -0.059,
        color: outColor,
      },
    ],
    yAxis: {
      ...baseConfig.yAxis,
      min: 0,
      title: {
        align: yAxisTitleAlign,
        margin: 10,
        textAlign: 'right',
        text: 'kWh',
      },
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatNumber(Number(this.value))
        },
      },
    },
    legend: {
      ...baseConfig.legend,
      enabled: true,
    },
  }

  return (
    <>
      <HighchartsReact
        id={title}
        highcharts={Highcharts}
        options={options as unknown as Options}
        ref={chartRef}
        {...props}
      />
    </>
  )
}

export default OperatingHoursBarChart
