import { useSelector } from 'react-redux'

import {
  type TSCreateModalParams,
  modalTypes,
  selectModalEntity,
} from '../../ducks/modal'
import ConfirmModal from '../ConfirmModal'
import EnableSitesModal from '../EnergyStarModals/EnableSitesModal'
import OnboardingModal from '../EnergyStarModals/OnboardingModal'
import PropertiesConfirmationModal from '../EnergyStarModals/PropertiesConfirmationModal'
import OpportunityDetailModal from '../OpportunityDetailModal'
import AddLabelsPopup from '../OpportunityFeed/AddLabelsPopup'
import CreateBatchPopup from '../OpportunityFeed/CreateBatchPopup'
import CreateOrEditOpportunity from '../OpportunityFeed/CreateOrEditOpportunity'
import OptEnergyStarModal from '../OptEnergyStarModal'

type TSProps = TSCreateModalParams

const MODAL_COMPONENTS = {
  [modalTypes.MODAL_OPPORTUNITY_DETAIL]: OpportunityDetailModal,
  [modalTypes.MODAL_OPPORTUNITY_CREATE_OR_EDIT_OPPORTUNITY]:
    CreateOrEditOpportunity,
  [modalTypes.MODAL_OPPORTUNITY_CREATE_BATCH]: CreateBatchPopup,
  [modalTypes.MODAL_OPPORTUNITY_ADD_LABELS]: AddLabelsPopup,
  [modalTypes.MODAL_CONFIRM_NAVIGATION]: ConfirmModal,
  [modalTypes.OPT_ENERGY_STAR]: OptEnergyStarModal,
  [modalTypes.ENABLE_SITES_ENERGY_STAR]: EnableSitesModal,
  [modalTypes.ONBOARDING_ENERGY_STAR]: OnboardingModal,
  [modalTypes.ES_PROPERTIES_CONFIRMATION]: PropertiesConfirmationModal,
}

const ModalContainer = () => {
  const { modalType, modalProps }: TSProps = useSelector(selectModalEntity)

  if (!modalType) {
    return null
  }

  const CurrentModal = MODAL_COMPONENTS[modalType]
  if (!CurrentModal) {
    return null
  }

  return <CurrentModal {...modalProps} />
}

export default ModalContainer
