export const PORTFOLIO_PATH = '/portfolio'
export const PORTFOLIO_SITES_PATH = '/portfolio/sites'
export const SUSTAINABILITY_REPORTING_PATH =
  '/portfolio/sustainability-reporting'
export const SUSTAINABILITY_BENCHMARKING_PATH =
  '/portfolio/sustainability-benchmarking'
export const PORTFOLIO_CONSUMPTION_PATH = '/portfolio/consumption'
export const PROJECTS_PATH = '/projects/all'
export const ALL_PROJECTS_POST_NTP_PATH = '/projects/post-ntp'
export const PROJECTS_PERFORMANCE_PATH = '/projects/performance'
export const DATA_EXPLORER_PATH = '/data-explorer'
export const CONSUMPTION_ANALYSIS_PATH = '/data-explorer/consumption-analysis'
export const ALERTS = '/alerts'
export const PROFILE_PATH = '/account/profile'
export const SETTINGS_PATH = '/account/settings'
export const PREFERENCES_PATH = '/account/preferences'

// Legacy Paths
export const LEGACY_ESG_REPORTING_PATH = '/sustainability/esg-reporting'
export const LEGACY_SUSTAINABILITY_BENCHMARKING_PATH =
  '/sustainability/benchmarking'
export const LEGACY_PORTFOLIO_CONSUMPTION_PATH =
  '/sustainability/portfolio-consumption'

export const LEGACY_SUSTAINABILITY_PATH = '/sustainability'
export const LEGACY_OPPORTUNITIES_PATH = '/opportunities'
export const LEGACY_BUILDING_INSIGHTS_PATH = '/building-insights'
