import { combineReducers } from 'redux'

import { TSEntitiesState } from './rootReducer'
import circuitData, {
  initialState as circuitDataInitialState,
} from '../ducks/circuit/circuitData'
import circuitMetaData, {
  initialState as circuitMetaDataInitialState,
} from '../ducks/circuit/circuitMeta'
import customerCategories, {
  initialState as customerCircuitCategoriesInitialState,
} from '../ducks/customerCircuitCategories/customerCategories'
import customers, {
  initialState as customersInitialState,
} from '../ducks/customers'
import modal, { initialState as modalInitialState } from '../ducks/modal'
import savingsAggregations, {
  initialState as savingsAggregationsInitialState,
} from '../ducks/projects/aggregations'
import invoices, {
  initialState as invoicesInitialState,
} from '../ducks/projects/invoice'
import savingsPerformance, {
  initialState as savingsPerformanceInitialState,
} from '../ducks/projects/performance'
import savings, {
  initialState as savingsInitialState,
} from '../ducks/projects/savings'
import singleSiteAnalysis, {
  initialState as singleSiteAnalysisInitialState,
} from '../ducks/singleSiteAnalysis/singleSiteAnalysis'
import sites, { initialState as sitesInitialState } from '../ducks/sites'
import siteShifts, {
  initialState as siteShiftsInitialState,
} from '../ducks/siteShifts'
import { TSMetaState } from '../ducks/types'
import electricUsage, {
  initialState as electricUsageInitialState,
} from '../ducks/usageByCustomer/electric'
import naturalGasUsage, {
  initialState as naturalGasUsageInitialState,
} from '../ducks/usageByCustomer/naturalGas'
import waterUsage, {
  initialState as waterUsageInitialState,
} from '../ducks/usageByCustomer/water'
import electricityMetaData, {
  initialState as electricityMetaDataInitialState,
} from '../ducks/usageByCustomerMetaData/electricityMetaData'
import naturalGasMetaData, {
  initialState as naturalGasMetaDataInitialState,
} from '../ducks/usageByCustomerMetaData/naturalGasMetaData'
import waterMetaData, {
  initialState as waterMetaDataInitialState,
} from '../ducks/usageByCustomerMetaData/waterMetaData'

interface TSEntityState {
  byId: Record<string, unknown>
  items: Array<Record<string, unknown>>
  meta: TSMetaState
}

export const emptyEntityState: TSEntityState = {
  byId: {},
  items: [],
  meta: {
    loading: false,
    error: '',
  },
}

export const initialState: TSEntitiesState = {
  customers: customersInitialState,
  sites: sitesInitialState,
  siteShifts: siteShiftsInitialState,
  circuitData: circuitDataInitialState,
  circuitMetaData: circuitMetaDataInitialState,
  modal: modalInitialState,
  savings: savingsInitialState,
  invoices: invoicesInitialState,
  savingsPerformance: savingsPerformanceInitialState,
  savingsAggregations: savingsAggregationsInitialState,
  electricityMetaData: electricityMetaDataInitialState,
  waterMetaData: waterMetaDataInitialState,
  naturalGasMetaData: naturalGasMetaDataInitialState,
  electricUsage: electricUsageInitialState,
  waterUsage: waterUsageInitialState,
  naturalGasUsage: naturalGasUsageInitialState,
  customerCategories: customerCircuitCategoriesInitialState,
  singleSiteAnalysis: singleSiteAnalysisInitialState,
}

export default combineReducers({
  customers,
  sites,
  siteShifts,
  circuitMetaData,
  circuitData,
  modal,
  savings,
  invoices,
  savingsPerformance,
  savingsAggregations,
  electricityMetaData,
  waterMetaData,
  naturalGasMetaData,
  electricUsage,
  waterUsage,
  naturalGasUsage,
  customerCategories,
  singleSiteAnalysis,
})
