import { SimpleGrid } from '@mantine/core'
import { max, sum } from 'lodash'
import moment from 'moment'
import { useContext, useRef } from 'react'
import styled from 'styled-components'

import { QueryError } from './QueryError'
import Card from '../../components/Card'
import { Alert } from '../../components/mantine/Alert/Alert'
import Quantity from '../../components/mantine/Quantity'
import MetricCard from '../../components/MetricCard'
import Spinner from '../../components/Spinner'
import Stack from '../../components/Stack'
import {
  DATE_FORMAT_TIMESTAMP,
  PEAK_DEMAND_CHART_DATE_FORMAT,
} from '../../constants'
import { REDAPTIVE_ONE_PAGE_TITLE } from '../../constants/strings'
import { useDocumentTitle } from '../../hooks/useSetDocumentTitle'
import {
  EnhancedGroupData,
  useResourceUsageData,
} from '../../queries/resourceUsage'
import { useSitesById } from '../../queries/sites'
import { formatNumberToDecimals } from '../../utils'
import { memberWithMax } from '../../utils/functional'
import { noDataMessage } from '../DataExplorer'
import useProgramStartDate, {
  useIsDateRangeValid,
} from './Filters/useProgramStartDate'
import InvalidDateRangeAlert from './InvalidDateRangeAlert'
import PeakPowerBarChart from './PeakDemandBarChart'
import PeakDemandScatterplot from './PeakDemandScatterplot'
import { FiltersContext, useSelectedSiteIds } from './utils/filterParams'

const ZoomHelpTextStyled = styled.div`
  font-size: 12px;
  text-align: right;
`

export type GroupWithPeak = {
  max: number
  average: number
  peakOverAvgUsage: number
} & EnhancedGroupData

const PeakDemandPage = () => {
  useDocumentTitle(`Peak Demand | ${REDAPTIVE_ONE_PAGE_TITLE}`)
  const activeFilters = useContext(FiltersContext)
  const sitesQuery = useSitesById()
  const programStartDate = useProgramStartDate(activeFilters.resourceType)
  const isDateRangeValid = useIsDateRangeValid(activeFilters)
  const selectedSiteIds = useSelectedSiteIds()

  const usageQuery = useResourceUsageData(activeFilters, programStartDate)

  const firstSite = sitesQuery.data?.[selectedSiteIds[0]]

  const timezone = firstSite?.address.timezone ?? 'America/Chicago'

  const peakPowerChartRef = useRef()
  const peakPowerOverAvgRef = useRef()

  const totalUsageByTime =
    usageQuery.data?.ts?.map((ts, i) => {
      const usage = sum(
        usageQuery.data?.groups?.map((group) => group.kWUsage?.[i])
      )
      return { ts, usage }
    }) ?? []
  const peakDemand = memberWithMax(totalUsageByTime, 'usage')
  const peakDateTime = peakDemand
    ? moment.tz(peakDemand.ts, timezone).format(PEAK_DEMAND_CHART_DATE_FORMAT)
    : undefined
  const averageUsage =
    sum(totalUsageByTime.map(({ usage }) => usage)) / totalUsageByTime.length
  const peakOverAvg = peakDemand?.usage
    ? (peakDemand?.usage / averageUsage) * 100
    : 0

  const dailyPeaks = totalUsageByTime.reduce((acc, { ts, usage }) => {
    const date = moment.tz(ts, timezone).format('YYYY-MM-DD')
    if (!acc[date] || acc[date].value < usage) {
      acc[date] = { ts, value: usage }
    }
    return acc
  }, {})
  const peakDemandScatterplotData = Object.keys(dailyPeaks).map((key) => {
    const { ts, value } = dailyPeaks[key]
    return {
      ts: moment.tz(ts, timezone).format(DATE_FORMAT_TIMESTAMP),
      value,
    }
  })

  const groupsWithPeakDemand =
    usageQuery.data?.groups?.map((group) => {
      const usage = group.kWUsage ?? []
      const peakUsage = max<number>(usage) ?? 0
      const average = usage ? sum(usage) / usage.length : 0
      return {
        ...group,
        max: peakUsage,
        average,
        peakOverAvgUsage: peakUsage - average,
      }
    }) ?? []
  const largestConsumerOverAvg = memberWithMax(
    groupsWithPeakDemand,
    'peakOverAvgUsage'
  )

  const noUsageData =
    usageQuery.isSuccess && usageQuery.data.groups.length === 0

  return (
    <Stack>
      {(sitesQuery.isLoading || usageQuery.isLoading) && <Spinner centered />}
      {!isDateRangeValid && sitesQuery.isSuccess && usageQuery.isSuccess && (
        <InvalidDateRangeAlert />
      )}
      {noUsageData && sitesQuery.isSuccess && usageQuery.isSuccess && (
        <Alert title={noDataMessage} type='orange' />
      )}
      {usageQuery.isError && (
        <QueryError
          status={usageQuery.error.status}
          message={usageQuery.error.message}
        />
      )}
      {sitesQuery.isSuccess &&
        usageQuery.isSuccess &&
        !noUsageData &&
        isDateRangeValid && (
          <>
            <SimpleGrid cols={{ base: 1, sm: 4 }}>
              <MetricCard title='Peak Date Time' value={peakDateTime} />
              <MetricCard
                title='Peak Demand'
                value={<Quantity quantityType='kW' value={peakDemand.usage} />}
              />
              <MetricCard
                title='% Peak Over Avg'
                value={`${formatNumberToDecimals(peakOverAvg, 0)}%`}
              />
              <MetricCard
                title='Largest Consumer Over Avg'
                value={largestConsumerOverAvg.groupName}
              />
            </SimpleGrid>

            <Card data-gainsight-id='peak-use-time-dot-chart'>
              <PeakDemandScatterplot
                chartRef={peakPowerChartRef}
                peakPowerData={peakDemandScatterplotData}
                timezone={timezone}
                title='Daily Peak Demand'
              />
              <ZoomHelpTextStyled>
                Click-and-drag to zoom. Shift-drag to pan.
              </ZoomHelpTextStyled>
            </Card>
            <Card data-gainsight-id='peak-use-over-avg-bar-chart'>
              <PeakPowerBarChart
                chartRef={peakPowerOverAvgRef}
                peakPowerData={groupsWithPeakDemand}
                loading={usageQuery.isLoading}
                title='Peak vs. Average Demand by Group'
              />
              <ZoomHelpTextStyled>
                Click-and-drag to zoom. Shift-drag to pan.
              </ZoomHelpTextStyled>
            </Card>
          </>
        )}
    </Stack>
  )
}

export default PeakDemandPage
