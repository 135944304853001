import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMatch, useNavigate } from 'react-router-dom'

import { selectCustomersEntity } from '../../ducks/customers'
import { actions as siteActions } from '../../ducks/sites'
import { actions as electricUsageActions } from '../../ducks/usageByCustomer/electric'
import { actions as gasUsageActions } from '../../ducks/usageByCustomer/naturalGas'
import { actions as waterUsageActions } from '../../ducks/usageByCustomer/water'
import { actions as electricityMetaDataAction } from '../../ducks/usageByCustomerMetaData/electricityMetaData'
import { actions as gasMetaDataAction } from '../../ducks/usageByCustomerMetaData/naturalGasMetaData'
import { actions as waterMetaDataAction } from '../../ducks/usageByCustomerMetaData/waterMetaData'
import { useCurrentCustomerStore } from '../../globalState/currentCustomer'
import { useEnableEnergyStarPopupStore } from '../../globalState/energyStar'
import { naturallySort } from '../../utils'
import ListSelector from '../ListSelector'

const CustomerSelector = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentCustomerId, setCurrentCustomerId } = useCurrentCustomerStore()
  const { items: customers, preventCustomerChange } = useSelector(
    selectCustomersEntity
  )
  const { resetEnableEnergyStarPopupState } = useEnableEnergyStarPopupStore()
  const customerItems = [...customers]
    .sort((item1, item2) => naturallySort(item1.validName, item2.validName))
    .map((customer) => ({
      name: customer.validName,
      id: customer.id,
    }))
  const selectedCustomerItem = customerItems.find(
    (c) => c.id === currentCustomerId
  )

  const handleCustomerChange = useCallback(
    ({ value }: { value: string }) => {
      // prevent switching customer and navigation to home if unsaved changes in current page when using usePrompt hook
      if (!preventCustomerChange && value !== currentCustomerId) {
        setCurrentCustomerId(value)
        dispatch(siteActions.clearSites())
        dispatch(electricUsageActions.resetElectricUsageData())
        dispatch(electricityMetaDataAction.resetElectricMetaData())
        dispatch(waterUsageActions.resetWaterUsageData())
        dispatch(waterMetaDataAction.resetWaterMetaData())
        dispatch(gasUsageActions.resetGasUsageData())
        dispatch(gasMetaDataAction.resetNaturalGasMetaData())
        resetEnableEnergyStarPopupState()
        navigate('/')
      }
    },
    [preventCustomerChange, currentCustomerId, dispatch, navigate]
  )

  const isProjectPerformanceActive = !!useMatch('/projects/performance/*')
  const isProjectInvoiceActive = !!useMatch('/projects/invoices/*')
  const getCustomerSwitcherGainsightId = () => {
    if (isProjectInvoiceActive)
      return 'primary-nav-project-inovices-company-switcher'
    if (isProjectPerformanceActive)
      return 'primary-nav-project-performance-company-switcher'
    return 'primary-nav-company-switcher'
  }

  if (!(customers?.length > 1)) return null

  return (
    <ListSelector
      gainsightTagId={getCustomerSwitcherGainsightId()}
      items={customerItems}
      selectedItem={selectedCustomerItem}
      updateValue={handleCustomerChange}
      unsettable={false}
    />
  )
}

export default CustomerSelector
