import CheckCircleIcon from '@iconscout/react-unicons/icons/uil-check-circle'
import {
  Badge,
  Button as MantineButton,
  ButtonProps,
  createPolymorphicComponent,
  rem,
} from '@mantine/core'
import { isNumber } from 'lodash'

import VerticalBarsLoader from '../VerticalBarsLoader'
import classes from './Button.module.css'

type CustomVariants =
  | 'primary'
  | 'primary-outline'
  | 'secondary'
  | 'secondary-outline'
  | 'danger'
  | 'outline'
  | 'transparent'
  | 'filled'

interface CustomButtonProps extends ButtonProps {
  countBadge?: number
  isSaved?: boolean
  isSaving?: boolean
  variant?: CustomVariants
  gainsightTagId?: string
}

const _Button = ({
  children,
  countBadge,
  isSaved = false,
  isSaving = false,
  leftSection,
  size = 'md',
  variant = 'primary',
  gainsightTagId,
  ...rest
}: CustomButtonProps) => {
  const rightSection = isNumber(countBadge) && (
    <Badge variant={variant} h={rem(24)} py={0} px={8} fz={13}>
      {countBadge}
    </Badge>
  )
  const savingIcon = isSaving && (
    <VerticalBarsLoader data-testid='vertical-bars-loader' width='23px' />
  )
  const savedIcon = isSaved && (
    <CheckCircleIcon data-testid='check-circle-icon' />
  )

  return (
    <MantineButton
      className={classes.button}
      data-gainsight-id={gainsightTagId}
      data-testid='button-component'
      disabled={isSaving}
      rightSection={rightSection}
      size={size}
      variant={variant}
      {...rest}
      leftSection={savingIcon || savedIcon || leftSection}
    >
      {(isSaving && 'Saving') || (isSaved && 'Saved') || children}
    </MantineButton>
  )
}

const Button = createPolymorphicComponent<'button', CustomButtonProps>(_Button)

export default Button
