import moment from 'moment'

const resourceStartDateKey = {
  electricity: 'ingestionDataStartElectricity',
  water: 'ingestionDataStartWater',
  'natural-gas': 'ingestionDataStartNaturalGas',
}

export const getProgramStartDate = (sites, resourceType) => {
  const sitesResourceStartDate = sites
    .map((site) => moment(site?.[resourceStartDateKey[resourceType]]))
    .filter((ingestionDate) => ingestionDate.isValid())
  return !sitesResourceStartDate.length
    ? moment('2018', 'YYYY')
    : moment.min(sitesResourceStartDate)
}
