import styled from 'styled-components'

import { zIndices } from '../utils'

interface TSProps {
  children: React.ReactNode
  width?: string
}

export const DefaultModalPopupStyled = styled.div<{
  width?: string
}>`
  color: ${({ theme }) => theme.colors.fontMain};
  backdrop-filter: blur(1px);
  background-color: rgba(200, 200, 200, 0.5);
  font-family: ${({ theme }) => theme.fontFamily};
  letter-spacing: 0.02em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: ${({ width }) => width || '800px'};
  z-index: ${zIndices.siteConfigModal + 1};

  * {
    box-sizing: border-box;
  }
`

const ModalBodyStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  position: relative;
  padding: 30px;
`

export const ModalPopupTitle = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-bottom: 5px;
`

const InnerPopupWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
`

const ModalPopup = ({ children, width }: TSProps) => {
  return (
    <DefaultModalPopupStyled width={width}>
      <ModalBodyStyled>
        <InnerPopupWrapper>{children}</InnerPopupWrapper>
      </ModalBodyStyled>
    </DefaultModalPopupStyled>
  )
}

export default ModalPopup
