import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { find, reject } from 'lodash'

import {
  createSavedFilter,
  deleteSavedFilter,
  fetchSavedFilters,
  updateSavedFilter,
} from './api'
import {
  CreateSavedFilterParams,
  SavedFilter,
  UpdateSavedFilterParams,
} from './types'
import { useCurrentCustomerStore } from '../../globalState/currentCustomer'
import { sortByCaseInsensitive, updateById } from '../../utils/functional'

// Keys
const customerSavedFiltersKey = (customerId: string) => [
  'savedFilters',
  customerId,
]

// Shared options
const sharedOptions = (customerId: string) => {
  return queryOptions({
    queryKey: customerSavedFiltersKey(customerId),
    queryFn: () => fetchSavedFilters(customerId),
    staleTime: 1000 * 60 * 60, // Cache for 1 hr
    enabled: !!customerId,
  })
}

// Queries
export const useSavedFilters = () => {
  const { currentCustomerId } = useCurrentCustomerStore()

  return useQuery({
    ...sharedOptions(currentCustomerId),
    select: (data) => sortByCaseInsensitive<SavedFilter>(data, 'name'),
  })
}

export const useSavedFilter = (id: string | null) => {
  const { currentCustomerId } = useCurrentCustomerStore()

  return useQuery({
    ...sharedOptions(currentCustomerId),
    enabled: !!currentCustomerId && !!id,
    select: (savedFilters) => find(savedFilters, ['id', id]),
  })
}

export const useCreateSavedFilter = () => {
  const queryClient = useQueryClient()
  const { currentCustomerId } = useCurrentCustomerStore()

  return useMutation({
    mutationFn: ({ name, query }: CreateSavedFilterParams) =>
      createSavedFilter({
        name,
        customerId: currentCustomerId,
        parameters: { query },
      }),
    onSuccess: (newSavedFilter) => {
      queryClient.setQueryData(
        customerSavedFiltersKey(currentCustomerId),
        (previous: SavedFilter[]) =>
          sortByCaseInsensitive<SavedFilter>(
            [...previous, newSavedFilter],
            'name'
          )
      )
    },
  })
}

export const useUpdateSavedFilterName = () => {
  const queryClient = useQueryClient()
  const { currentCustomerId } = useCurrentCustomerStore()

  return useMutation({
    mutationFn: ({ id, name }: UpdateSavedFilterParams) =>
      updateSavedFilter({
        id,
        name,
        customerId: currentCustomerId,
      }),
    onSuccess: (updatedQuery) => {
      queryClient.setQueryData(
        customerSavedFiltersKey(currentCustomerId),
        (previous: SavedFilter[]) =>
          updateById<SavedFilter>(previous, updatedQuery.id, updatedQuery)
      )
    },
  })
}

export const useDeleteSavedFilter = () => {
  const queryClient = useQueryClient()
  const { currentCustomerId } = useCurrentCustomerStore()

  return useMutation({
    mutationFn: deleteSavedFilter,
    onSuccess: (_, id) => {
      queryClient.setQueryData(
        customerSavedFiltersKey(currentCustomerId),
        (previous: SavedFilter[]) => reject(previous, ['id', id])
      )
    },
  })
}
