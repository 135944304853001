import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { allProjectApis } from './apis'
import {
  TSOpportunityBatch,
  TSOpportunityBatchesResponse,
  TSOpportunityBatchResponse,
  TSOpportunityComment,
  TSOpportunitySeedResponse,
  TSOpportunitySeedsResponse,
  TSOpportunityTaskResponse,
} from './types'
import {
  enhanceBatch,
  enhanceComment,
  enhanceProject,
  entityById,
  handleCreateBatchComment,
  handleCreateProjectComment,
} from './utils'
import { TSCustomersResponse } from '../../ducks/customers'

// Queries
export const useFetchAllProjectsModuleCustomers = () => {
  return useQuery({
    queryKey: ['allProjectsModuleCustomers'],
    queryFn: allProjectApis.fetchAllProjectsModuleCustomers,
    select: (customers: TSCustomersResponse) => entityById(customers),
  })
}
export const useFetchAllProjects = (customerId) => {
  return useQuery({
    queryKey: ['allProjects', customerId],
    queryFn: () => allProjectApis.fetchAllProjects(customerId),
    enabled: !!customerId,
    select: (projects: TSOpportunitySeedsResponse) =>
      projects.map((project) => enhanceProject(project)),
  })
}
export const useFetchAllBatches = (customerId) => {
  return useQuery({
    queryKey: ['allBatches', customerId],
    queryFn: () => allProjectApis.fetchAllBatches(customerId),
    enabled: !!customerId,
    select: (batches: TSOpportunityBatchesResponse) =>
      batches.map((batch) => enhanceBatch(batch)),
  })
}
export const useFetchLabels = (customerId) => {
  return useQuery({
    queryKey: ['allProjectLabels', customerId],
    queryFn: () => allProjectApis.fetchLabels(customerId),
    enabled: !!customerId,
  })
}
export const useFetchUserEmails = (customerId) => {
  return useQuery({
    queryKey: ['allProjectUserEmails', customerId],
    queryFn: () => allProjectApis.fetchUserEmails(customerId),
    enabled: !!customerId,
  })
}
export const useFetchCustomerSettings = (customerId) => {
  return useQuery({
    queryKey: ['allProjectCustomerSettings', customerId],
    queryFn: () => allProjectApis.fetchCustomerSettings(customerId),
    enabled: !!customerId,
  })
}
export const useFetchMLOV = (customerId) => {
  return useQuery({
    queryKey: ['allProjectsMLOV', customerId],
    queryFn: () => allProjectApis.fetchMasterListOfValues(customerId),
    enabled: !!customerId,
  })
}
export const useFetchProjectComments = (projectId, customerId) => {
  return useQuery({
    queryKey: ['projectComments', projectId],
    queryFn: () => allProjectApis.fetchProjectComments(projectId, customerId),
    enabled: !!(customerId && projectId),
    select: (comments: TSOpportunityComment[]) =>
      comments.map((comment) => enhanceComment(comment)),
  })
}
export const useFetchBatchComments = (batchId, customerId) => {
  return useQuery({
    queryKey: ['batchComments', batchId],
    queryFn: () => allProjectApis.fetchBatchComments(batchId, customerId),
    enabled: !!(customerId && batchId),
    select: (comments: TSOpportunityComment[]) =>
      comments.map((comment) => enhanceComment(comment)),
  })
}
export const useFetchProjectAttachments = (projectId, customerId) => {
  return useQuery({
    queryKey: ['projectAttachments', projectId],
    queryFn: () =>
      allProjectApis.fetchProjectAttachments(projectId, customerId),
    enabled: !!(customerId && projectId),
    // TODO: Confirm if we need to refetch when we have moved from airtable now?
    refetchInterval: 2 * 60 * 60 * 1000,
  })
}
export const useFetchBatchAttachments = (batchId, customerId) => {
  return useQuery({
    queryKey: ['batchAttachments', batchId],
    queryFn: () => allProjectApis.fetchBatchAttachments(batchId, customerId),
    enabled: !!(customerId && batchId),
    // TODO: Confirm if we need to refetch when we have moved from airtable now?
    refetchInterval: 2 * 60 * 60 * 1000,
  })
}
export const useFetchCustomerTasks = (customerId) => {
  return useQuery({
    queryKey: ['customerTasks', customerId],
    queryFn: () => allProjectApis.fetchCustomerTasks(customerId),
    enabled: !!customerId,
  })
}
// Mutations
export const useSetBookmarkForProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['setBookmarkForProject'],
    mutationFn: allProjectApis.setBookmarkForProject,
    onMutate: async ({ projectId, isBookmarked, customerId }) => {
      await queryClient.cancelQueries({
        queryKey: ['allProjects', customerId],
      })
      const previousProjects = queryClient.getQueryData([
        'allProjects',
        customerId,
      ])

      queryClient.setQueryData(
        ['allProjects', customerId],
        (previousProjects: TSOpportunitySeedsResponse) =>
          previousProjects.map((prev) => {
            return projectId == prev.id
              ? { ...prev, bookmark: isBookmarked }
              : prev
          })
      )

      return { previousProjects, customerId }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ['allProjects', context?.customerId],
        context?.previousProjects
      )
      throw new Error('Something went wrong. Please try again.')
    },
  })
}
export const useSetBookmarkForBatch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['setBookmarkForBatch'],
    mutationFn: allProjectApis.setBookmarkForBatch,
    onMutate: async ({ batchId, isBookmarked, customerId }) => {
      await queryClient.cancelQueries({
        queryKey: ['allBatches', customerId],
      })
      const previousProjects = queryClient.getQueryData([
        'allBatches',
        customerId,
      ])

      queryClient.setQueryData(
        ['allBatches', customerId],
        (previousBatches: TSOpportunityBatchResponse[]) =>
          previousBatches.map((prev) => {
            return batchId == prev.id
              ? { ...prev, bookmark: isBookmarked }
              : prev
          })
      )

      return { previousProjects, customerId }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ['allProjects', context?.customerId],
        context?.previousProjects
      )
      throw new Error('Something went wrong. Please try again.')
    },
  })
}
export const useRejectBatch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['rejectBatch'],
    mutationFn: allProjectApis.rejectBatch,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allBatches'] })
    },
  })
}
export const useRejectProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['rejectProject'],
    mutationFn: allProjectApis.rejectProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allProjects'] })
    },
  })
}
export const useDeleteProjectAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['deleteProjectAttachment'],
    mutationFn: allProjectApis.deleteProjectAttachment,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['projectAttachments'] }),
  })
}
export const useDeleteBatchAttachment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['deleteBatchAttachment'],
    mutationFn: allProjectApis.deleteBatchAttachment,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['batchAttachments'] }),
  })
}
export const useUpdateBatch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateBatch'],
    mutationFn: allProjectApis.updateBatch,
    onSuccess: (updatedBatch: TSOpportunityBatchResponse) => {
      const enhancedBatch = enhanceBatch(updatedBatch)
      queryClient.setQueryData(
        ['allBatches', enhancedBatch.customerId],
        (previousBatches: TSOpportunityBatchesResponse) =>
          previousBatches.map((prev) =>
            enhancedBatch.id == prev.id ? enhancedBatch : prev
          )
      )
    },
  })
}
export const useUpdateProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateProject'],
    mutationFn: allProjectApis.updateProject,
    onSuccess: (updatedProject: TSOpportunitySeedResponse) => {
      const enhancedProject = enhanceProject(updatedProject)
      queryClient.setQueryData(
        ['allProjects', updatedProject.customerId],
        (previousProjects: TSOpportunitySeedsResponse) =>
          previousProjects.map((prev) =>
            enhancedProject.id == prev.id ? enhancedProject : prev
          )
      )
    },
  })
}
export const useUpdateBatchMetadata = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateBatchMetadata'],
    mutationFn: allProjectApis.updateBatchMetadata,
    onSuccess: (updatedBatch: TSOpportunityBatchResponse) => {
      const enhancedBatch = enhanceBatch(updatedBatch)
      queryClient.setQueryData(
        ['allBatches', enhancedBatch.customerId],
        (previousBatches: TSOpportunityBatchesResponse) =>
          previousBatches.map((prev) =>
            enhancedBatch.id == prev.id ? enhancedBatch : prev
          )
      )
    },
  })
}
export const useUpdateProjectLabels = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateProjectLabels'],
    mutationFn: allProjectApis.updateProjectLabels,
    onSuccess: (updatedProject: TSOpportunitySeedResponse) => {
      const enhancedProject = enhanceProject(updatedProject)
      queryClient.setQueryData(
        ['allProjects', enhancedProject.customerId],
        (previousProjects: TSOpportunitySeedsResponse) =>
          previousProjects.map((prev) =>
            enhancedProject.id == prev.id ? enhancedProject : prev
          )
      )
    },
  })
}
export const useAssignLabels = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['assignLabels'],
    mutationFn: allProjectApis.assignLabels,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allProjectLabels'] })
      queryClient.invalidateQueries({ queryKey: ['allProjects'] })
      queryClient.invalidateQueries({ queryKey: ['allBatches'] })
    },
  })
}
export const useEditProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['editProject'],
    mutationFn: allProjectApis.editProject,
    onSuccess: (editedProject: TSOpportunitySeedResponse) => {
      const enhancedProject = enhanceProject(editedProject)
      queryClient.setQueryData(
        ['allProjects', enhancedProject.customerId],
        (previousProjects: TSOpportunitySeedsResponse) =>
          previousProjects.map((prev) =>
            enhancedProject.id == prev.id ? enhancedProject : prev
          )
      )
      queryClient.invalidateQueries({ queryKey: ['allProjects'] })
    },
  })
}
export const useCreateMLOVValue = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createMLOVValue'],
    mutationFn: allProjectApis.createValueInMasterListOfValues,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allProjectsMLOV'] })
    },
  })
}
export const useCreateProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createProject'],
    mutationFn: allProjectApis.createProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allProjects'] })
    },
  })
}
export const useUpdateCustomerSettings = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateCustomerSettings'],
    mutationFn: allProjectApis.updateCustomerSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['allProjectCustomerSettings'],
      })
    },
  })
}
export const useUpdateBatchFollowers = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateBatchFollowers'],
    mutationFn: allProjectApis.updateBatchFollowers,
    onSuccess: (updatedBatch: TSOpportunityBatchResponse) => {
      const enhancedBatch = enhanceBatch(updatedBatch)
      queryClient.setQueryData(
        ['allBatches', enhancedBatch.customerId],
        (previousBatches: TSOpportunityBatchesResponse) =>
          previousBatches.map((prev) =>
            enhancedBatch.id == prev.id ? enhancedBatch : prev
          )
      )
    },
  })
}
export const useUpdateProjectFollowers = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateProjectFollowers'],
    mutationFn: allProjectApis.updateProjectFollowers,
    onSuccess: (updatedProject: TSOpportunitySeedResponse) => {
      const enhancedProject = enhanceProject(updatedProject)
      queryClient.setQueryData(
        ['allProjects', enhancedProject.customerId],
        (previousProjects: TSOpportunitySeedsResponse) =>
          previousProjects.map((prev) =>
            enhancedProject.id == prev.id ? enhancedProject : prev
          )
      )
    },
  })
}
export const useCreateBatch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createBatch'],
    mutationFn: allProjectApis.createBatch,
    onSuccess: (updatedBatch: TSOpportunityBatchResponse) => {
      const enhancedBatch = enhanceBatch(updatedBatch)
      queryClient.setQueryData(
        ['allBatches', enhancedBatch.customerId],
        (previousBatches: TSOpportunityBatch[]) =>
          [enhancedBatch].concat(previousBatches)
      )
    },
  })
}
export const useTransitionProject = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['transitionProject'],
    mutationFn: allProjectApis.transitionProject,
    onSuccess: (updatedProject: TSOpportunitySeedResponse) => {
      const enhancedProject = enhanceProject(updatedProject)
      queryClient.setQueryData(
        ['allProjects', enhancedProject.customerId],
        (previousProjects: TSOpportunitySeedsResponse) =>
          previousProjects.map((prev) =>
            enhancedProject.id == prev.id ? enhancedProject : prev
          )
      )
    },
  })
}
export const useTransitionBatch = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['transitionBatch'],
    mutationFn: allProjectApis.transitionBatch,
    onSuccess: (updatedBatch: TSOpportunityBatchResponse) => {
      const enhancedBatch = enhanceBatch(updatedBatch)
      queryClient.setQueryData(
        ['allBatches', enhancedBatch.customerId],
        (previousBatches: TSOpportunityBatchesResponse) =>
          previousBatches.map((prev) =>
            enhancedBatch.id == prev.id ? enhancedBatch : prev
          )
      )
    },
  })
}
export const useCreateCustomerTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createCustomerTask'],
    mutationFn: allProjectApis.createCustomerTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customerTasks'] })
    },
  })
}
export const useUpdateCustomerTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateCustomerTask'],
    mutationFn: allProjectApis.updateCustomerTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customerTasks'] })
    },
  })
}
export const useUpdateCustomerTaskStatus = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateCustomerTaskStatus'],
    mutationFn: allProjectApis.updateCustomerTaskStatus,
    onMutate: async ({ customerId, taskId, status }) => {
      await queryClient.cancelQueries({
        queryKey: ['customerTasks', customerId],
      })
      const previousTasks = queryClient.getQueryData([
        'customerTasks',
        customerId,
      ])

      queryClient.setQueryData(
        ['customerTasks', customerId],
        (previousTasks: TSOpportunityTaskResponse[]) =>
          previousTasks.map((prev) =>
            taskId == prev.id ? { ...prev, status: status } : prev
          )
      )
      return { previousTasks, customerId }
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        ['customerTasks', context?.customerId],
        context?.previousTasks
      )
      throw new Error('Something went wrong. Please try again.')
    },
  })
}
export const useDeleteCustomerTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['deleteCustomerTask'],
    mutationFn: allProjectApis.deleteCustomerTask,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['customerTasks'] }),
  })
}
export const useReorderCustomerTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['reorderCustomerTask'],
    mutationFn: allProjectApis.reorderCustomerTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customerTasks'] })
    },
  })
}

export const useCreateProjectComment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createProjectComment'],
    mutationFn: handleCreateProjectComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projectComments'] })
      queryClient.invalidateQueries({ queryKey: ['projectAttachments'] })
    },
    onError: (error) => {
      throw new Error('Failed to create project comment:', error)
    },
  })
}

export const useCreateBatchComment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['createBatchComment'],
    mutationFn: handleCreateBatchComment,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['batchComments'] })
      queryClient.invalidateQueries({ queryKey: ['batchAttachments'] })
    },
    onError: (error) => {
      throw new Error('Failed to create batch comment:', error)
    },
  })
}
