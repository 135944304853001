import styled from 'styled-components'

import { SubModalHeading, SubModalSubHeading } from './OnboardingModal'
import ESOnboardingError from '../Icons/ESOnboardingError'

const AccountIdErrorContainer = styled.div`
  padding-bottom: 40px;
`

const ErrorText = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
`

const AccountIdErrorModal = () => {
  return (
    <AccountIdErrorContainer>
      <SubModalSubHeading>
        Energy Star Portfolio Manager Onboarding
      </SubModalSubHeading>
      <ESOnboardingError />
      <SubModalHeading>Something went wrong</SubModalHeading>
      <ErrorText>
        Looks like you haven&apos;t added Redaptive as your contact in Energy
        Star Portfolio Manager, follow the steps bellow and try again.
      </ErrorText>
      <SubModalSubHeading>
        1. Please log into Energy Star Portal.
      </SubModalSubHeading>
      <SubModalSubHeading>
        2. Add Redaptive as a Contact (Username: Redaptive_Metering).
      </SubModalSubHeading>
      <SubModalSubHeading>
        3. Wait for your connection request to be accepted (can take a few
        hours).
      </SubModalSubHeading>
      <SubModalSubHeading>
        4. Share your properties with Redaptive.
      </SubModalSubHeading>
      <SubModalSubHeading>5. Come back here and try again.</SubModalSubHeading>
    </AccountIdErrorContainer>
  )
}

export default AccountIdErrorModal
