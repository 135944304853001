import { Flex, Group, Stack, Text } from '@mantine/core'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { ES_COORDINATOR_GROUP_ID } from '../../constants/strings'
import { actions as modalActions } from '../../ducks/modal'
import { TSSiteTabs } from '../../ducks/sites'
import { useEnableEnergyStarPopupStore } from '../../globalState/energyStar'
import { useEnergyStarSitesCountQuery } from '../../queries/energyStar'
import { useUserHasPermissionGroup } from '../../queries/permissions'
import { getUrlSearchParams } from '../../utils/getUrlSearchParams'
import EmptyStateSvg from '../EmptyStateSvg'
import Paper from '../mantine/Paper'
import StyledLink from '../StyledLink'

const NoSitesPlaceholder = () => {
  const dispatch = useDispatch()

  const { data: isESCoordinator } = useUserHasPermissionGroup(
    ES_COORDINATOR_GROUP_ID
  )
  const esSitesCountQuery = useEnergyStarSitesCountQuery()

  const showESEnableSitesPopup =
    isESCoordinator &&
    esSitesCountQuery.isSuccess &&
    !esSitesCountQuery.data?.isEnabled

  const { hasUserDismissedEnableEnergyStarPopup } =
    useEnableEnergyStarPopupStore()

  useEffect(() => {
    if (showESEnableSitesPopup && !hasUserDismissedEnableEnergyStarPopup)
      dispatch(modalActions.showEnableSitesEnergyStarModal())
  }, [dispatch, showESEnableSitesPopup, hasUserDismissedEnableEnergyStarPopup])

  return (
    <Stack h='80vh' align='center' justify='center'>
      <Paper shadow='sm' p='xl' w='700px'>
        <Flex align='center' justify='center' gap='sm'>
          <EmptyStateSvg
            data-testid='empty-state-svg'
            width='300px'
            height='150px'
          />
          <Group>
            <Text fz='xl' fw={600} c='black' data-testid='heading'>
              Welcome to Redaptive ONE
            </Text>
            <Text fz='md' fw={400} c='gray.5' data-testid='sub-heading'>
              Hang tight, you're almost set! We are currently working on the{' '}
              <u>
                <StyledLink
                  href={`/projects/meter-installation-tracker?${getUrlSearchParams(
                    {},
                    TSSiteTabs.METER_INSTALLATION_TRACKER,
                    [],
                    true
                  )}`}
                >
                  meter installation process.
                </StyledLink>
              </u>{' '}
              You'll be notified as soon as the data is ready.
            </Text>
          </Group>
        </Flex>
      </Paper>
    </Stack>
  )
}

export default NoSitesPlaceholder
