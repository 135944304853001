import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import useLogout from './useLogout'
import { isAuthenticated, isErrorUnauthorized } from '../api/utils'
import {
  actions as customerActions,
  selectCustomersEntity,
  TSCustomersEntityState,
} from '../ducks/customers'
import {
  actions as siteActions,
  selectSitesEntity,
  TSSitesEntityState,
} from '../ducks/sites'
import { actions as siteShiftActions } from '../ducks/siteShifts'
import { useCurrentCustomerStore } from '../globalState/currentCustomer'
import { gainsightPXGlobalContext, TSActionTypes } from '../utils/gainsight'

const useAppData = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  // The serialize function here would be responsible for
  // creating an object of { key: value } pairs from the
  // fields in the form that make up the query.
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])
  const { site } = serializeFormQuery

  const {
    siteMeta: { error: siteError, loading: siteLoading },
    site: { customerId },
    meta: { error: sitesError },
  }: TSSitesEntityState = useSelector(selectSitesEntity)

  const { currentCustomerId, setCurrentCustomerId } = useCurrentCustomerStore()
  const {
    items: customers,
    byId: customerById,
    meta: { error: customersError, loading: customersLoading, noCustomers },
  }: TSCustomersEntityState = useSelector(selectCustomersEntity)

  const authenticated = isAuthenticated()
  useEffect(() => {
    const siteIds = site?.split(',').filter((id) => id)

    if (siteIds?.length === 1 && authenticated) {
      dispatch(siteActions.fetchSite(siteIds[0]))
      dispatch(siteShiftActions.fetchSiteShifts(siteIds[0]))
    }
  }, [dispatch, site, authenticated])

  useEffect(() => {
    if (!siteError && !siteLoading && customerId) {
      gainsightPXGlobalContext(TSActionTypes.set, {
        customerId: customerId,
        customerName: customerById[customerId]?.name,
      })
      setCurrentCustomerId(customerId)
    }
  }, [
    siteError,
    siteLoading,
    customersError,
    customerId,
    customerById,
    dispatch,
  ])

  useEffect(() => {
    if (
      !noCustomers &&
      !customers.length &&
      customersLoading &&
      !customersError &&
      authenticated
    ) {
      dispatch(customerActions.fetchCustomers())
    }
  }, [
    customers,
    customersLoading,
    customersError,
    noCustomers,
    authenticated,
    dispatch,
  ])

  useEffect(() => {
    if (
      !noCustomers &&
      !customersLoading &&
      !customersError &&
      currentCustomerId
    ) {
      gainsightPXGlobalContext(TSActionTypes.set, {
        customerId: currentCustomerId,
        customerName: customerById[currentCustomerId]?.name,
      })
      dispatch(siteActions.fetchSites(currentCustomerId))
    }
  }, [
    currentCustomerId,
    customersLoading,
    noCustomers,
    customersError,
    customerById,
    dispatch,
  ])

  const unauthorizedDataAccess = useMemo(() => {
    return isErrorUnauthorized(customersError) ||
      isErrorUnauthorized(siteError) ||
      isErrorUnauthorized(sitesError)
      ? 'Please check your login credentials and try again.'
      : ''
  }, [customersError, siteError, sitesError])

  const logout = useLogout(unauthorizedDataAccess)

  useEffect(() => {
    if (
      isErrorUnauthorized(customersError) ||
      isErrorUnauthorized(siteError) ||
      isErrorUnauthorized(sitesError)
    ) {
      logout()
    }
  }, [customersError, logout, siteError, sitesError])

  useEffect(() => {
    if (
      isErrorUnauthorized(customersError) ||
      isErrorUnauthorized(siteError) ||
      isErrorUnauthorized(sitesError)
    ) {
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, location.pathname, location.search])
}

export default useAppData
