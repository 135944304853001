interface TSProps {
  color?: string
  size?: string
}

const CheckMark = ({ color = '#162447', size = '16' }: TSProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 13 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.7022 0.260447C12.6162 0.17792 12.5139 0.112417 12.4012 0.0677155C12.2885 0.0230142 12.1676
    0 12.0454 0C11.9233 0 11.8024 0.0230142 11.6897 0.0677155C11.577 0.112417 11.4747 0.17792 11.3887
    0.260447L4.49734 6.82889L1.60205 4.06416C1.51277 3.98206 1.40737 3.91751 1.29188 3.87419C1.17638 3.83086
    1.05306 3.80962 0.92894 3.81166C0.804821 3.81371 0.68234 3.839 0.568491 3.8861C0.454642 3.9332 0.351654
    4.00118 0.265407 4.08617C0.17916 4.17116 0.111344 4.27148 0.0658294 4.38141C0.0203151 4.49135 -0.00200593
    4.60874 0.000141452 4.72688C0.00228884 4.84503 0.0288624 4.96161 0.0783446 5.06998C0.127827 5.17835 0.199249
    5.27638 0.288532 5.35848L3.84058 8.73955C3.92657 8.82208 4.02888 8.88758 4.1416 8.93229C4.25433 8.97699
    4.37523 9 4.49734 9C4.61945 9 4.74036 8.97699 4.85308 8.93229C4.9658 8.88758 5.06811 8.82208 5.1541
    8.73955L12.7022 1.55477C12.7961 1.47231 12.871 1.37225 12.9223 1.26086C12.9735 1.14948 13 1.0292
    13 0.907606C13 0.786009 12.9735 0.66573 12.9223 0.554349C12.871 0.442967 12.7961 0.342898 12.7022
    0.260447Z'
      fill={color}
    />
  </svg>
)

export default CheckMark
