import { useNavigate } from 'react-router-dom'

const useLogout = (message = '') => {
  const navigate = useNavigate()
  return () => {
    navigate('/logout', { state: { message: message } })
  }
}

export default useLogout
