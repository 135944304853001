import axios from 'axios'

import {
  TSAddLabelsRequestPayload,
  TSCreateBatchPayload,
  TSCreateCommentPayload,
  TSCreateCustomerTaskPayload,
  TSCreateOpportunityRequestPayload,
  TSDeleteCustomerTaskPayload,
  TSEditBatchFollowersRequestPayload,
  TSEditBatchMetadataPayload,
  TSEditBatchPayload,
  TSEditCustomerSettingsRequestPayload,
  TSEditOpportunityFollowersRequestPayload,
  TSEditOpportunityLabelsPayload,
  TSEditOpportunityPayload,
  TSMasterListOfValuesRequest,
  TSOpportunityComment,
  TSRejectProjectPayload,
  TSReorderCustomerTaskPayload,
  TSTransitionOpportunityPayload,
  TSUpdateCustomerTaskPayload,
  TSUpdateCustomerTaskStatusPayload,
} from './types'
import {
  defaultHeaders,
  gcProposalApiBaseUrl,
  opportunityApiBaseUrl,
  redaptiveGeneralApiBaseUrl,
} from '../../api'
import { handleAxiosError } from '../../api/utils'
import {
  allProjectsModuleCustomers,
  batchAttachments,
  batchComments,
  customerTasks,
  labels,
  mlov,
  opportunityAttachments,
  opportunityBatches,
  opportunityComments,
  opportunitySeeds,
  preSignedUrls,
  userEmails,
} from '../../mockData/opportunities'
import { isVariantActive } from '../../utils/variants'

export const allProjectApis = {
  fetchAllProjectsModuleCustomers: () => {
    if (isVariantActive('mock')) {
      return Promise.resolve(allProjectsModuleCustomers).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/customers`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchAllProjects: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunitySeeds).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/projects?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchAllBatches: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityBatches).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/batches?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchLabels: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(labels).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/metadata/labels?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchUserEmails: (customerId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(userEmails).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const url = `${opportunityApiBaseUrl()}/users?customerId=${customerId}`
    return axios({
      method: 'get',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchCustomerSettings: (customerId) => {
    const url = `${opportunityApiBaseUrl()}/customers/${customerId}/settings`
    return axios({
      method: 'get',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  fetchMasterListOfValues: (customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(mlov).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const url = `${opportunityApiBaseUrl()}/customers/${customerId}/mlov`
    return axios({
      method: 'get',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  setBookmarkForProject: ({
    projectId,
    isBookmarked,
  }: {
    projectId: string
    isBookmarked: boolean
    customerId: string
  }) => {
    const url = `${opportunityApiBaseUrl()}/projects/${projectId}/bookmark`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { status: isBookmarked },
    }).catch(handleAxiosError)
  },
  setBookmarkForBatch: ({
    batchId,
    isBookmarked,
  }: {
    batchId: string
    isBookmarked: boolean
    customerId: string
  }) => {
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/bookmark`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { status: isBookmarked },
    }).catch(handleAxiosError)
  },
  rejectBatch: (payload: TSRejectProjectPayload) => {
    const { opportunityId, reason } = payload
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/reject`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { reason },
    }).catch(handleAxiosError)
  },
  rejectProject: (payload: TSRejectProjectPayload) => {
    const { opportunityId, reason } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/reject`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { reason },
    }).catch(handleAxiosError)
  },
  updateBatch: (payload: TSEditBatchPayload) => {
    const { opportunityIds, batchId } = payload
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/opportunities`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { opportunityIds },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateProject: (payload: TSEditOpportunityPayload) => {
    const { opportunityId, description, title } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { description, title },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  editProject: (payload: TSCreateOpportunityRequestPayload) => {
    const { opportunityId } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}`
    return axios({
      method: 'put',
      url: url,
      headers: defaultHeaders(),
      data: payload,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateBatchMetadata: (payload: TSEditBatchMetadataPayload) => {
    const { batchId, ...restPayload } = payload
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: restPayload,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateProjectLabels: (payload: TSEditOpportunityLabelsPayload) => {
    const { opportunityId, labelIds } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/labels`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { labelIds },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  assignLabels: (payload: TSAddLabelsRequestPayload) => {
    const { customerId, labels, opportunityIds, batchIds } = payload
    const url = `${opportunityApiBaseUrl()}/metadata/labels/assign`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: { customerId, labels, opportunityIds, batchIds },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  createValueInMasterListOfValues: (payload: TSMasterListOfValuesRequest) => {
    const { customerID, type, value } = payload
    const url = `${opportunityApiBaseUrl()}/customers/${customerID}/mlov`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: {
        type,
        value,
      },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  createProject: (payload: TSCreateOpportunityRequestPayload) => {
    const url = `${opportunityApiBaseUrl()}/projects`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: payload,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateCustomerSettings: (payload: TSEditCustomerSettingsRequestPayload) => {
    const { customerId, customerSettings } = payload
    const url = `${opportunityApiBaseUrl()}/customers/${customerId}/settings`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: customerSettings,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateBatchFollowers: (payload: TSEditBatchFollowersRequestPayload) => {
    const { batchId, followers } = payload
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/followers`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { followers },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateProjectFollowers: (
    payload: TSEditOpportunityFollowersRequestPayload
  ) => {
    const { opportunityId, followers } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/followers`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: { followers },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  createBatch: (payload: TSCreateBatchPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityBatches[0]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const url = `${opportunityApiBaseUrl()}/batches`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: payload,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  transitionProject: (payload: TSTransitionOpportunityPayload) => {
    const { opportunityId, ...restOfData } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/transition`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: restOfData,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  transitionBatch: (payload: TSTransitionOpportunityPayload) => {
    const { opportunityId, ...restOfData } = payload
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/transition`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: restOfData,
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  fetchProjectComments: (projectId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityComments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/projects/${projectId}/comments?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchBatchComments: (batchId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(batchComments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/comments?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchProjectAttachments: (projectId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(opportunityAttachments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/projects/${projectId}/attachments?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchBatchAttachments: (batchId: string, customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(batchAttachments).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/attachments?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data.results
      })
      .catch(handleAxiosError)
  },
  fetchCustomerTasks: (customerId: string) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks?customerId=${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  deleteProjectAttachment: ({
    projectId,
    attachmentId,
  }: {
    projectId: string
    attachmentId: string
  }) => {
    const url = `${opportunityApiBaseUrl()}/projects/${projectId}/attachments/${attachmentId}`
    return axios({
      method: 'delete',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  deleteBatchAttachment: ({
    batchId,
    attachmentId,
  }: {
    batchId: string
    attachmentId: string
  }) => {
    const url = `${opportunityApiBaseUrl()}/batches/${batchId}/attachments/${attachmentId}`
    return axios({
      method: 'delete',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  createCustomerTask: ({
    title,
    assignedToEmail,
    customerId,
    description,
    dueDate,
    sequenceNumber,
    projectBatchId,
    projectOpportunityId,
    status,
  }: TSCreateCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[0]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks`
    return axios({
      method: 'post',
      url: url,
      headers: defaultHeaders(),
      data: {
        title,
        assignedToEmail,
        customerId,
        description,
        dueDate,
        sequenceNumber,
        projectBatchId,
        projectOpportunityId,
        status,
      },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateCustomerTask: ({
    id,
    title,
    assignedToEmail,
    description,
    dueDate,
    sequenceNumber,
    status,
  }: TSUpdateCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[0]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${id}`
    return axios({
      method: 'put',
      url: url,
      headers: defaultHeaders(),
      data: {
        title,
        assignedToEmail,
        description,
        dueDate,
        sequenceNumber,
        status,
      },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  updateCustomerTaskStatus: ({
    taskId,
    status,
  }: TSUpdateCustomerTaskStatusPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[1]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${taskId}/status`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: {
        status,
      },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  deleteCustomerTask: ({ taskId }: TSDeleteCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[1]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${taskId}`
    return axios({
      method: 'delete',
      url: url,
      headers: defaultHeaders(),
    })
      .then((response) => {
        return response
      })
      .catch(handleAxiosError)
  },
  reorderCustomerTask: ({
    taskId,
    sequenceNumber,
  }: TSReorderCustomerTaskPayload) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(customerTasks[1]).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${gcProposalApiBaseUrl()}/smart-projects/tasks/${taskId}/re-order`
    return axios({
      method: 'patch',
      url: url,
      headers: defaultHeaders(),
      data: {
        sequenceNumber,
      },
    })
      .then((response) => {
        return response.data
      })
      .catch(handleAxiosError)
  },
  createProjectComment: async (
    payload: TSCreateCommentPayload
  ): Promise<TSOpportunityComment> => {
    const { opportunityId, ...postData } = payload
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/comments`
    const { data } = await axios.post(url, postData, {
      headers: defaultHeaders(),
    })
    return data
  },
  createBatchComment: async (
    payload: TSCreateCommentPayload
  ): Promise<TSOpportunityComment> => {
    const { opportunityId, ...postData } = payload
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/comments`
    const { data } = await axios.post(url, postData, {
      headers: defaultHeaders(),
    })
    return data
  },
  generatePreSignedUrls: async (fileName, projectId) => {
    if (isVariantActive('mock')) {
      return Promise.resolve(preSignedUrls).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }
    const url = `${opportunityApiBaseUrl()}/projects/${projectId}/attachments/pre-signed-url`
    const { data } = await axios.post(
      url,
      { fileName },
      { headers: defaultHeaders() }
    )
    return data
  },
  uploadFileUsingPreSignedUrls: async (
    preSignedUrlToUpload: string,
    file: File
  ) => {
    const partUrl = preSignedUrlToUpload.split('amazonaws.com')[1]
    const url = `${redaptiveGeneralApiBaseUrl() + partUrl}`
    const headers = { 'Content-Type': file.type }
    return axios.put(url, file, { headers })
  },
  addProjectAttachmentMetadata: async (
    opportunityId: string,
    preSignedUrlToDownload: string,
    commentId: string,
    fileName: string
  ) => {
    const url = `${opportunityApiBaseUrl()}/projects/${opportunityId}/attachments`
    const { data } = await axios.post(
      url,
      {
        attachmentUrl: preSignedUrlToDownload,
        commentId,
        fileName: fileName,
      },
      { headers: defaultHeaders() }
    )
    return data
  },
  addBatchAttachmentMetadata: async (
    opportunityId: string,
    preSignedUrlToDownload: string,
    commentId: string,
    fileName: string
  ) => {
    const url = `${opportunityApiBaseUrl()}/batches/${opportunityId}/attachments`
    const { data } = await axios.post(
      url,
      {
        attachmentUrl: preSignedUrlToDownload,
        commentId,
        fileName: fileName,
      },
      { headers: defaultHeaders() }
    )
    return data
  },
}
