const ESOnboardingError = () => {
  return (
    <svg
      width='101'
      height='102'
      viewBox='0 0 101 102'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M49.0297 92C68.9885 92 85.1683 75.6584 85.1683 55.5C85.1683 35.3416 68.9885 19 49.0297 19C29.0709 19 12.8911 35.3416 12.8911 55.5C12.8911 75.6584 29.0709 92 49.0297 92Z'
        fill='url(#paint0_linear_738_40757)'
      />
      <g filter='url(#filter0_d_738_40757)'>
        <g filter='url(#filter1_d_738_40757)'>
          <path
            d='M55.5276 15.4663L24.1914 69.7403C23.9421 70.1748 23.8112 70.6671 23.8118 71.168C23.8123 71.6689 23.9444 72.1609 24.1946 72.5948C24.4449 73.0287 24.8047 73.3893 25.238 73.6406C25.6713 73.8919 26.163 74.0251 26.6639 74.0269H89.3364C89.8373 74.0251 90.3289 73.8919 90.7623 73.6406C91.1956 73.3893 91.5553 73.0287 91.8056 72.5948C92.0559 72.1609 92.1879 71.6689 92.1885 71.168C92.189 70.6671 92.0582 70.1748 91.8089 69.7403L60.4726 15.4663C60.2206 15.0341 59.8597 14.6755 59.4258 14.4263C58.992 14.177 58.5004 14.0459 58.0001 14.0459C57.4998 14.0459 57.0082 14.177 56.5744 14.4263C56.1406 14.6755 55.7797 15.0341 55.5276 15.4663Z'
            fill='#EFCC00'
          />
        </g>
        <path
          d='M53.9688 61.8127C53.9688 61.0154 54.2053 60.236 54.6482 59.573C55.0912 58.9101 55.7208 58.3934 56.4574 58.0883C57.194 57.7832 58.0046 57.7033 58.7865 57.8589C59.5685 58.0144 60.2868 58.3984 60.8506 58.9621C61.4144 59.5259 61.7983 60.2442 61.9539 61.0262C62.1094 61.8082 62.0296 62.6187 61.7245 63.3553C61.4194 64.092 60.9027 64.7216 60.2397 65.1645C59.5768 65.6075 58.7974 65.8439 58.0001 65.8439C57.4646 65.8667 56.9302 65.7781 56.4308 65.5836C55.9314 65.389 55.4778 65.0929 55.0988 64.7139C54.7198 64.3349 54.4237 63.8814 54.2292 63.3819C54.0347 62.8825 53.946 62.3481 53.9688 61.8127ZM55.4335 55.5508L54.4123 35.3945H61.4804L60.4995 55.5508H55.4335Z'
          fill='#353535'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_738_40757'
          x='11'
          y='0'
          width='94'
          height='94'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_738_40757'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_738_40757'
            result='shape'
          />
        </filter>
        <filter
          id='filter1_d_738_40757'
          x='21.8118'
          y='14.0459'
          width='72.3767'
          height='63.9814'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_738_40757'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_738_40757'
            result='shape'
          />
        </filter>
        <linearGradient
          id='paint0_linear_738_40757'
          x1='49.0297'
          y1='19'
          x2='49.0297'
          y2='92'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E3ECFA' />
          <stop offset='1' stopColor='#DAE7FF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ESOnboardingError
