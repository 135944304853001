import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { keyBy, reject } from 'lodash'

import {
  createSiteShift,
  deleteSiteShift,
  fetchSiteShifts,
  updateSiteShift,
} from './api'
import {
  TSCreateSiteShiftPayload,
  TSDeleteSiteShiftPayload,
  TSSiteShift,
  TSSiteShiftResponse,
  TSSiteShiftsResponse,
  TSUpdateSiteShiftPayload,
} from './types'
import { DAYS_OF_WEEK } from '../../constants'
import OperatingHoursHelper from '../../utils/operatingHoursHelper'

// Helpers
export const enhanceSiteShift = (
  siteShift: TSSiteShiftResponse
): TSSiteShift => {
  const day = DAYS_OF_WEEK.indexOf(siteShift.startDay)
  const startHour = parseInt(siteShift.startTime.split(':')[0])
  const startMinute = parseInt(siteShift.startTime.split(':')[1])
  const durationInMinutes = siteShift.durationInMinutes

  const endDayTime = OperatingHoursHelper.getShiftEndDayTime({
    day,
    startHour,
    startMinute,
    durationInMinutes,
  })

  return {
    ...siteShift,
    ...endDayTime,
    day: day,
    startHour: startHour,
    startMinute: startMinute,
  }
}

// Keys
const siteShiftsKey = (siteId: string) => ['shifts', siteId]

const sharedOptions = (siteId: string) =>
  queryOptions({
    queryKey: siteShiftsKey(siteId),
    queryFn: () => fetchSiteShifts(siteId),
    staleTime: 1000 * 60 * 60, // Cache for 1 hr
    enabled: !!siteId,
  })

// Queries
export const useShifts = (siteId: string) => {
  return useQuery({
    ...sharedOptions(siteId),
    select: (data) => (data as TSSiteShiftsResponse).map(enhanceSiteShift),
  })
}

export const useShiftsById = (siteId: string) => {
  return useQuery({
    ...sharedOptions(siteId),
    select: (data) => keyBy(data as TSSiteShiftResponse[], 'id'),
  })
}

export const useCreateShift = (siteId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: TSCreateSiteShiftPayload) => createSiteShift(payload),
    onSuccess: (newShift) => {
      queryClient.setQueryData(
        siteShiftsKey(siteId),
        (previous: TSSiteShiftResponse[]) => [...previous, newShift]
      )
    },
  })
}

export const useUpdateShift = (siteId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: TSUpdateSiteShiftPayload) => updateSiteShift(payload),
    onSuccess: (updatedShift: TSSiteShiftResponse) => {
      queryClient.setQueryData(
        siteShiftsKey(siteId),
        (previous: TSSiteShiftResponse[]) =>
          previous.map((shift) =>
            shift.id === updatedShift.id ? updatedShift : shift
          )
      )
    },
  })
}

export const useDeleteShift = (siteId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: TSDeleteSiteShiftPayload) => deleteSiteShift(payload),
    onSuccess: (_, shift) => {
      queryClient.setQueryData(
        siteShiftsKey(siteId),
        (previous: TSSiteShiftResponse[]) =>
          reject(previous, ['id', shift.shiftId])
      )
    },
  })
}
