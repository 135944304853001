import * as yup from 'yup'

import { titleCase } from '../utils'

const humanize = (str: string) => {
  const key = str.split('.').slice(-1)[0]

  return titleCase(key).replace(/\sIds?$/u, '')
}
const fieldNameMessage =
  (msg) =>
  ({ label, path }) =>
    `${label ?? humanize(path)} ${msg}`

yup.setLocale({
  mixed: {
    default: fieldNameMessage('is invalid'),
    required: fieldNameMessage('is required'),
    notNull: fieldNameMessage('is required'),
  },
  number: {
    min: fieldNameMessage('must be at least ${min}'),
    max: fieldNameMessage('must be no more than ${max}'),
    lessThan: fieldNameMessage('must be less than ${max}'),
    moreThan: fieldNameMessage('must be more than ${max}'),
    positive: fieldNameMessage('must be a positive number'),
  },
})
