import { isNil, round } from 'lodash'

import { NumberConverter, QuantityTypes } from './types'
import { useDisplayUnitsPreference } from '../../../queries/settings'

const useConvertQuantity = (): NumberConverter => {
  const { data: displayUnits } = useDisplayUnitsPreference()

  return (value, quantityType, options) => {
    const { precision = 2 } = options || {}
    if (isNil(value)) return undefined

    if (displayUnits === 'imperial') {
      return round(value, precision)
    } else {
      return round(convertToMetric(value, quantityType), precision)
    }
  }
}

const convertToMetric = (value: number, quantityType: QuantityTypes) => {
  switch (quantityType) {
    case 'sqft':
    case 'area':
      return value * 0.092903
    case 'CCF':
    case 'gas':
      return value * 2.832
    case 'gal':
    case 'gallons':
    case 'water':
      return value * 3.78541
    default:
      return value
  }
}

export default useConvertQuantity
