import styled from 'styled-components'

import Button from './Button'
import CloseButtonComponent from './mantine/CloseButton'
import Title from './Title'
export interface TSModalProps {
  title: string
  body: string
  primaryText: string
  secondaryText?: string
  handlePrimaryAction: () => void
  handleSecondaryAction?: () => void
  handleOnClose: () => void
  loading?: boolean
}

const ConfirmModalWrapperStyled = styled.div`
  align-items: center;
  backdrop-filter: blur(4px);
  background: rgba(200, 200, 200, 0.5);
  border-radius: 0.5rem;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 300;

  * {
    box-sizing: border-box;
  }
`

const ConfirmModalStyled = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  grid-template-rows: auto 1fr auto;
  display: grid;
  text-align: center;
  width: 550px;
`

const ConfirmModalTitleStyled = styled(Title)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 0;
  padding-bottom: 24px;
`

const ConfirmModalBodyStyled = styled.div`
  background-color: #fff;
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: 10px;
  padding: 12px 16px;
`

const BodyText = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 50px;
`

const ConfirmModalButtonsStyled = styled.div`
  height: 56px;
  justify-content: flex-end;

  button:first-child {
    margin-right: 24px;
  }
`

const HeadWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
`

const CloseButtonComponentStyled = styled(CloseButtonComponent)`
  margin: 5px;
`

const ConfirmModal = ({
  body,
  handlePrimaryAction,
  handleSecondaryAction,
  handleOnClose = () => ({}),
  loading,
  title,
  primaryText,
  secondaryText = 'Cancel',
}: TSModalProps) => (
  <ConfirmModalWrapperStyled>
    <ConfirmModalStyled>
      <HeadWrapperStyled>
        <CloseButtonComponentStyled
          variant='filled'
          c='blue.5'
          title='Close modal'
          size='lg'
          onClick={handleOnClose}
        />
      </HeadWrapperStyled>
      <ConfirmModalBodyStyled>
        <ConfirmModalTitleStyled>{title}</ConfirmModalTitleStyled>
        <BodyText>{body}</BodyText>
        <ConfirmModalButtonsStyled>
          {secondaryText && (
            <Button onClick={handleSecondaryAction} buttonType='error'>
              {secondaryText}
            </Button>
          )}
          <Button
            onClick={handlePrimaryAction}
            buttonType='primary'
            loading={loading}
          >
            {primaryText}
          </Button>
        </ConfirmModalButtonsStyled>
      </ConfirmModalBodyStyled>
    </ConfirmModalStyled>
  </ConfirmModalWrapperStyled>
)

export default ConfirmModal
