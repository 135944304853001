const jsonQueryFn =
  <T>(url) =>
  async (): Promise<T> => {
    const response = await global.fetch(url)
    if (!response.ok) {
      throw new Error('Network response was not ok') // React Query requires a thrown error if the response is not ok
    }
    return response.json()
  }

export default jsonQueryFn
