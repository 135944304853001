import { Divider, Title } from '@mantine/core'
import { every, omit } from 'lodash'

import { MenuItem } from '.'
import NavLink from './NavLink'

const ExpandedNavItem = (item: MenuItem) => {
  switch (item.component) {
    case 'title':
      return (
        <Title order={3} size='h4' c='#A4A3A9' px={8}>
          <>{item.label}</>
        </Title>
      )
    case 'navLink': {
      if (item.children && every(item.children, (c) => c.isHidden)) {
        return null
      }

      return (
        <NavLink {...omit(item, 'component')}>
          {item.children?.map((c) => (
            <NavLink key={c.label} {...omit(c, 'component')} />
          ))}
        </NavLink>
      )
    }
    case 'divider':
      return <Divider color='#E9E9E9' my='md' />
    default:
      return null
  }
}

export default ExpandedNavItem
