import { TSCustomersResponse } from '../../ducks/customers'
import {
  TSFiltersState,
  TSLabels,
  TSOpportunityAttachment,
  TSOpportunityComment,
  TSOpportunitySeedsResponse,
  TSOpportunityTaskResponse,
  TSProjectStage,
} from '../../queries/allProjects/types'

const allProjectsModuleCustomers: TSCustomersResponse = [
  {
    id: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    name: 'Avantor Inc',
    notifyDataBehind: true,
    validName: 'Avantor Inc',
  },
  {
    id: '532da230-fb2b-4197-856c-ca6534a0d6dc',
    name: 'Redaptive',
    notifyDataBehind: true,
    validName: 'Redaptive',
  },
]

const mlov = [
  {
    id: 'rec2DPaGonhaCFuYG',
    value: 'HVAC Controls',
    type: 'ECM_TYPE',
    customerId: '',
    sequenceNumber: null,
    created: '',
    createdBy: '',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'rec4rNHaQS6IREcbZ',
    value: 'Demo Field',
    type: 'CUSTOM_FIELD_TITLE',
    customerId: '532da230-fb2b-4197-856c-ca6534a0d6dc',
    sequenceNumber: null,
    metadata:
      '{"id":"customField-317a1632-9e13-4583-8c6b-42860594bb6c","title":"Demo Field","helperText":"Brand new field def","unit":"pieces"}',
    created: '2023-11-27T09:31:57.708Z',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'rec54PuzDM8GJudx4',
    value: 'kWh',
    type: 'UNIT',
    customerId: '',
    sequenceNumber: null,
    created: '',
    createdBy: '',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'rec62cPpBqhTziAeT',
    value: 'Solar',
    type: 'ECM_TYPE',
    customerId: '',
    sequenceNumber: null,
    created: '',
    createdBy: '',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'rec6dqxzAzKjXEhgl',
    value: 'TestUnit1',
    type: 'UNIT',
    customerId: '532da230-fb2b-4197-856c-ca6534a0d6dc',
    sequenceNumber: null,
    created: '2023-11-09T11:28:40.437Z',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'rec8B2U32wQb1GRJO',
    value: 'MT CO₂e',
    type: 'UNIT',
    customerId: '',
    sequenceNumber: null,
    created: '',
    createdBy: '',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'rec9BW1XI9TMAqJJT',
    value: 'Charging Station',
    type: 'ECM_TYPE',
    customerId: '532da230-fb2b-4197-856c-ca6534a0d6dc',
    sequenceNumber: null,
    created: '2023-11-09T11:52:00.792Z',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modified: '',
    modifiedBy: '',
  },
  {
    id: 'recEhlGRasl925Zwi',
    value: 'Lighting',
    type: 'ECM_TYPE',
    customerId: '',
    sequenceNumber: null,
    created: '',
    createdBy: '',
    modified: '',
    modifiedBy: '',
  },
]

const opportunitySeeds: TSOpportunitySeedsResponse = [
  {
    id: '1',
    displayId: '00001',
    title: 'Opportunity Title',
    siteId: '22a67cb1-4654-4f7d-96d3-48e660f38209',
    customerId: '532da230-fb2b-4197-856c-ca6534a0d6dc',
    projectStage: TSProjectStage.NEW,
    siteName: '340 Brannan St. Suite 104 (Lab 01) - San Francisco - CA',
    description: 'a good one',
    address1: '340 Brannan Street',
    address2: '',
    city: 'San Francisco',
    state: 'CA',
    country: 'US',
    ecmType: 'Plumbing',
    simplePaybackPeriod: 15,
    annualEnergySavedOrGeneratedKwh: 20,
    annualNetCostSavings: 30,
    annualPayment: 2,
    totalContractValue: 25,
    totalNetCost: 50,
    annualAvoidedEnergyCost: 50,
    annualAvoidedMaintenanceCost: 50,
    annualAvoidedOtherCost: 50,
    annualAvoidedTotalCost: 50,
    contractRate: 60,
    scheduleTermInMonths: 20,
    ecmTypeSpecificFields: {
      systemSize: '10',
    },
    annualAvoidedCo2e: 232,
    bookmark: false,
    approvalStatus: 'Approved',
    hide: false,
    hideReason: '',
    postalcode: '123',
    labelIds: ['recXXX124XXX', 'recXXX421XXX'],
    followers: ['sagar.zond@redaptiveinc.com', 'admin@redaptiveinc.com'],
    customFields: [
      {
        id: 'uuid1',
        title: 'Field1',
        value: 'field1 value',
        unit: 'field1 unit',
        isCustomField: true,
        isPrimary: false,
      },
      {
        id: 'uuid2',
        title: 'Field2',
        value: 'field2 value',
        unit: 'field2 unit',
        isCustomField: true,
        isPrimary: false,
      },
    ],
    sequenceDetails: [
      {
        id: 'field1',
        sequenceNumber: 0,
      },
      {
        id: 'field2',
        sequenceNumber: 1,
      },
      {
        id: 'field3',
        sequenceNumber: 2,
      },
    ],
  },
]

const labels: TSLabels = [
  {
    id: 'recXXX123XXX',
    text: 'Preliminary Estimate',
    color: 'ffa1a1',
    title: 'Estimate Precision',
  },
  {
    id: 'recXXX124XXX',
    text: 'Fast Payback',
    color: 'ffd9a1',
    title: 'Other',
  },
]

const userEmails = [
  {
    email: 'jessie.mansperger@redaptiveinc.com',
  },
  {
    email: 'billy.arlew+123@redaptiveinc.com',
  },
]

const opportunityBatches = [
  {
    id: 'recXX123XX',
    displayId: '00043',
    title: 'HVAC Deluxe Upgrade',
    description: 'All the high efficiency HVAC equipment',
    customerId: '1343d8e9-d10f-4725-8888-99fa8f399b74',
    ecmType: 'Replace',
    kwhSavings: 1,
    projectCost: 2,
    annualAvoidedCo2e: 2,
    usdPerKwh: 2,
    totalContractValue: 23,
    termLength: 90,
    annualPayment: 234,
    bookmark: false,
    hide: false,
    locked: false,
    labelIds: [],
    hideReason: null,
    created: '2018-01-12 02:57:00',
    modified: '2018-01-12 02:57:00',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modifiedBy: 'sagar.zond@redaptiveinc.com',
    opportunityIds: ['recXXX123XXX', 'recXXX124XXX'],
  },
]

const opportunityComments: TSOpportunityComment[] = [
  {
    id: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    comment: 'Wow I can really save some energy!',
    commentAddedBy: 'qibli@example.com',
    created: '2023-01-01', // TODO: check this date format
    modified: '2023-02-01',
    createdBy: 'maki@example.com',
  },
  {
    id: '324fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    comment: 'Definitely want to pursue this one!',
    commentAddedBy: 'maki@example.com',
    created: '2023-01-01', // TODO: check this date format
    modified: '2023-02-01',
    createdBy: 'maki@example.com',
  },
]

const batchComments: TSOpportunityComment[] = [
  {
    id: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    comment: 'Wow I can really save some energy!',
    commentAddedBy: 'qibli@example.com',
    created: '2023-01-01', // TODO: check this date format
    modified: '2023-02-01',
    createdBy: 'maki@example.com',
  },
  {
    id: '324fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    comment: 'Definitely want to pursue this one!',
    commentAddedBy: 'maki@example.com',
    created: '2023-01-01', // TODO: check this date format
    modified: '2023-02-01',
    createdBy: 'maki@example.com',
  },
]

const opportunitiesWithBatches = [
  {
    id: 'recRcjqbQgYY32Ocd',
    customerId: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    bookmark: false,
    hide: false,
    hideReason: '',
    ecmType: 'batch',
    kwhSavings: '',
    projectCost: '',
    annualAvoidedCo2e: '',
    usdPerKwh: '',
    totalContractValue: '',
    termLength: '',
    annualPayment: '',
    displayId: '00016',
    title: 'Test Batch 1',
    description: 'Some Description',
    opportunityIds: [
      'reczDV7TSx2d6e4FX',
      'recj0KFIW5P2lrp7o',
      'recLscfTrajzXSFK4',
    ],
    locked: false,
    created: '2023-07-19T15:31:05.997Z',
    createdBy: 'ummar.hamid@redaptiveinc.com',
    modified: '',
    modifiedBy: '',
    childSeeds: [
      {
        id: 'reczDV7TSx2d6e4FX',
        customerId: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
        bookmark: true,
        hide: false,
        hideReason: '',
        ecmType: 'HVAC Scheduling',
        kwhSavings: '79',
        projectCost: '0',
        annualAvoidedCo2e: '0.0194932147036832',
        usdPerKwh: '0.1547',
        totalContractValue: '',
        termLength: '',
        annualPayment: '',
        recordId: 'reczDV7TSx2d6e4FX',
        displayId: '014344',
        source: 'schedule_savings_estimator_v21',
        siteId: 'ab9a0d08-a300-4a0e-a35a-0d841640f5a2',
        siteName: '67 Winthrop Road - Chester - CT',
        salesforceSiteId: '23449',
        address1: '67 Winthrop Road',
        address2: '',
        city: 'Chester',
        state: 'CT',
        postalcode: '06412',
        country: 'US',
      },
      {
        id: 'recj0KFIW5P2lrp7o',
        customerId: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
        bookmark: false,
        hide: false,
        hideReason: '',
        ecmType: 'Lighting Scheduling',
        kwhSavings: '59.04005357142857',
        projectCost: '0',
        annualAvoidedCo2e: '0.0145582236394596',
        usdPerKwh: '0.1547',
        totalContractValue: '',
        termLength: '',
        annualPayment: '',
        recordId: 'recj0KFIW5P2lrp7o',
        displayId: '014296',
        source: 'schedule_savings_estimator_v5',
        siteId: 'ab9a0d08-a300-4a0e-a35a-0d841640f5a2',
        siteName: '67 Winthrop Road - Chester - CT',
        salesforceSiteId: '23449',
        address1: '67 Winthrop Road',
        address2: '',
        city: 'Chester',
        state: 'CT',
        postalcode: '06412',
        country: 'US',
      },
      {
        id: 'recLscfTrajzXSFK4',
        customerId: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
        bookmark: true,
        hide: false,
        hideReason: '',
        ecmType: 'HVAC Scheduling',
        kwhSavings: '8243',
        projectCost: '0',
        annualAvoidedCo2e: '3.0553673952905',
        usdPerKwh: '0.0646',
        totalContractValue: '',
        termLength: '',
        annualPayment: '',
        recordId: 'recLscfTrajzXSFK4',
        displayId: '014325',
        source: 'schedule_savings_estimator_v2',
        siteId: '680a1222-c8a7-4e0c-8e04-3cde9912d3eb',
        siteName: '140 521 US-90 ALT - Missouri City - TX',
        salesforceSiteId: '20827',
        address1: '140 521 US-90 ALT',
        address2: '',
        city: 'Missouri City',
        state: 'TX',
        postalcode: '77489',
        country: 'US',
      },
    ],
    labelIds: [],
  },
]

const opportunityAttachments: TSOpportunityAttachment[] = [
  {
    recordId: 'recNrFbtaoaTMFF2B',
    id: '7',
    attachmentType: 'application/pdf',
    attachmentUrl: `https://v5.airtableusercontent.com/
    v1/17/17/1686276000000/
    AAKHGaDdmEI_2nt6_Rgq-A/
    Lmywg-f7FcbaTRacKgzWWpJDw1V_7073MqpCMefR_m2NrrDcGBTdw_y
    _JFs8JtsUbY4rMwIHnvwSwLoX0cRRU8_sRkzOjuq72Sv_nhIJ_-k/bK_1O-QLHzUZeF5w13TF1hZx_-NCrh0PdNFEssN2xiw`,
    fileName: 'final-html.pdf',
    commentId: '',
    created: '2023-06-01T18:18:40.149Z',
    createdBy: 'sagar.zond@redaptiveinc.com',
  },
]

const batchAttachments: TSOpportunityAttachment[] = [
  {
    recordId: 'recNrFbtaoaTMFF2B',
    id: '7',
    attachmentType: 'application/pdf',
    attachmentUrl: `https://v5.airtableusercontent.com/
    v1/17/17/1686276000000/
    AAKHGaDdmEI_2nt6_Rgq-A/
    Lmywg-f7FcbaTRacKgzWWpJDw1V_7073MqpCMefR_m2NrrDcGBTdw_y
    _JFs8JtsUbY4rMwIHnvwSwLoX0cRRU8_sRkzOjuq72Sv_nhIJ_-k/bK_1O-QLHzUZeF5w13TF1hZx_-NCrh0PdNFEssN2xiw`,
    fileName: 'final-html.pdf',
    commentId: '',
    created: '2023-06-01T18:18:40.149Z',
    createdBy: 'sagar.zond@redaptiveinc.com',
  },
]

const currentFilters: TSFiltersState = {
  selectedAddresses: { values: [], isActive: false },
  selectedEcmTypes: { values: [], isActive: false },
  selectedProjectStages: { values: [], isActive: false },
  selectedLabels: { values: [], isActive: false },
  costSavings: { values: [0, 10000000], isActive: false },
  co2Savings: { values: [0, 10000000], isActive: false },
  simplePaybackPeriod: { values: [0, 10000000], isActive: false },
  selectedTaskFilter: { value: '', isActive: false },
  bookmarkedOnly: false,
  selectedOnly: false,
  searchKey: '',
}

const preSignedUrls: {
  preSignedUrlToUpload: string
  preSignedUrlToDownload: string
} = {
  preSignedUrlToUpload: `https://redaptive-general-dev.s3.us-east-2.amazonaws.com/
    opportunity-feed/final-html.pdf?X-Amz-Security-Token=IQoJb
    3JpZ2luX2VjEIr%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3Q
    tMiJGMEQCIChZxpYfGJSQZXop7N4qiQBOyBY%2Fh%2BdS4dQyc76NUNZNA`,
  preSignedUrlToDownload: `https://redaptive-general-dev.s3.us-east-2.amazonaws.com/
  opportunity-feed/final-html.pdf?X-Amz-Security-Token=IQoJb3JpZ2luX2Vj`,
}

const customerTasks: Array<TSOpportunityTaskResponse> = [
  {
    id: 'ab5f377d-b493-48e2-810a-635d4063f261',
    title: 'This is task title 1',
    description: 'The description of task',
    assignedToEmail: 'sagar.zond@redaptiveinc.com',
    dueDate: new Date('2023-10-10'),
    projectOpportunityId: 'The project of task',
    projectBatchId: 'The batch id of the task',
    customerId: 'The customer id of the task',
    sequenceNumber: 1,
    created: '2018-01-12 02:57:00',
    modified: '2018-01-12 02:57:00',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modifiedBy: 'sagar.zond@redaptiveinc.com',
    status: 'IN_PROGRESS',
  },
  {
    id: 'ab5f377d-b493-48e2-810a-635d4063f262',
    title: 'This is task title 2',
    description: 'The description of task',
    assignedToEmail: '',
    dueDate: new Date('2023-10-10'),
    projectOpportunityId: 'The project of task',
    projectBatchId: 'The batch id of the task',
    customerId: 'The customer id of the task',
    sequenceNumber: 2,
    created: '2018-01-12 02:57:00',
    modified: '2018-01-12 02:57:00',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modifiedBy: 'sagar.zond@redaptiveinc.com',
    status: 'COMPLETED',
  },
  {
    id: 'ab5f377d-b493-48e2-810a-635d4063f266',
    title: 'This is task title 3',
    description: 'The description of task',
    assignedToEmail: 'sagar.zond@redaptiveinc.com',
    dueDate: new Date('2023-10-10'),
    projectOpportunityId: 'The project of task',
    projectBatchId: 'The batch id of the task',
    customerId: 'The customer id of the task',
    sequenceNumber: 3,
    created: '2018-01-12 02:57:00',
    modified: '2018-01-12 02:57:00',
    createdBy: 'sagar.zond@redaptiveinc.com',
    modifiedBy: 'sagar.zond@redaptiveinc.com',
    status: 'COMPLETED',
  },
]

export {
  allProjectsModuleCustomers,
  batchAttachments,
  batchComments,
  currentFilters,
  customerTasks,
  labels,
  mlov,
  opportunitiesWithBatches,
  opportunityAttachments,
  opportunityBatches,
  opportunityComments,
  opportunitySeeds,
  preSignedUrls,
  userEmails,
}
