import { Image } from '@mantine/core'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { selectCustomersEntity } from '../../ducks/customers'
import { useCurrentCustomerStore } from '../../globalState/currentCustomer'
import r1Logo from '../../logos/redaptive-one-logo.svg'

const Logo = () => {
  const { currentCustomerId } = useCurrentCustomerStore()
  const { items: customers } = useSelector(selectCustomersEntity)
  const currentCustomer = customers.find((c) => c.id === currentCustomerId)

  return (
    <Link to='/' data-gainsight-id='primary-nav-redaptive-logo'>
      <Image
        src={currentCustomer?.customerLogoUrl || r1Logo}
        alt={
          currentCustomer?.customerLogoUrl ? currentCustomer.name : 'Redaptive'
        }
        w='unset'
        maw={230}
        mah={32}
      />
    </Link>
  )
}

export default Logo
