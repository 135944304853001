import axios from 'axios'
import moment from 'moment'

import { defaultHeaders, denaliApiBaseUrl } from '../../api'
import {
  buildURLSearchQuery,
  getDateRange,
  getRequestRange,
  handleAxiosError,
} from '../../api/utils'
import { DATE_FORMAT_DATA_API_REQUEST } from '../../constants'
import { voltageResponse } from '../../mockData/singleSiteAnalysis'
import { UsageDataResponse } from '../../queries/resourceUsage'
import { isVariantActive } from '../../utils/variants'
import { MeasurementTypes } from '../types'
import { TSSiteVoltageDataRequest } from './singleSiteAnalysis'

export const API = {
  fetchSiteVoltageData(request: TSSiteVoltageDataRequest) {
    const { site, fromDate, toDate, period } = request
    const { startDate, endDate } = getDateRange(fromDate, toDate, period)

    const dateRange = getRequestRange(
      moment(startDate, DATE_FORMAT_DATA_API_REQUEST),
      moment(endDate, DATE_FORMAT_DATA_API_REQUEST)
    )

    const query =
      buildURLSearchQuery(dateRange, request).toString() +
      `&measurementTypes=${MeasurementTypes.RMS_VOLTAGE}`

    if (isVariantActive('mock')) {
      const usageMockData = voltageResponse // make dynamic w/ dates?
      return Promise.resolve(usageMockData).then(
        (data) => new Promise((resolve) => setTimeout(() => resolve(data), 200))
      )
    }

    const url = `${denaliApiBaseUrl()}/sites/${site}/data/electricity?${decodeURIComponent(
      query
    )}`

    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: { data: UsageDataResponse }) => data)
      .catch(handleAxiosError)
  },
}
