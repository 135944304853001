import { Stepper as MaintineStepper } from '@mantine/core'
import { Dispatch, SetStateAction } from 'react'

export interface StepperStep {
  label: string
  description?: string
  content: React.ReactNode
}

interface StepperProps {
  steps: StepperStep[]
  active: number
  setActive?: Dispatch<SetStateAction<number>>
  completeChildren?: React.ReactNode
  allowNextStepsSelect?: boolean
}

const Stepper = ({
  steps,
  active,
  setActive,
  completeChildren,
  allowNextStepsSelect = false,
}: StepperProps) => {
  return (
    <MaintineStepper
      active={active}
      onStepClick={(...args) => {
        if (setActive) setActive(...args)
      }}
      allowNextStepsSelect={allowNextStepsSelect}
    >
      {steps.map(({ label, description, content }) => (
        <MaintineStepper.Step
          key={label}
          label={label}
          description={description}
        >
          {content}
        </MaintineStepper.Step>
      ))}
      {completeChildren && (
        <MaintineStepper.Completed>
          {completeChildren}
        </MaintineStepper.Completed>
      )}
    </MaintineStepper>
  )
}

export default Stepper
