import Highcharts, {
  AxisLabelsFormatterContextObject,
  Options,
  TooltipFormatterContextObject,
} from 'highcharts'
import DraggablePoints from 'highcharts/modules/draggable-points'
import Exporting from 'highcharts/modules/exporting'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'

import { DATE_FORMAT_TOOLTIP } from '../../constants'
import { Resolution } from '../../ducks/types'
import {
  formatTimestamp,
  getXAxisDateFormat,
  getXAxisTickIntervals,
} from '../../utils/chartHelper'
import { config as baseConfig } from '../../utils/chartsDefaultConfig'
import { useQuantity } from '../mantine/Quantity'

Exporting(Highcharts)
NoDataToDisplay(Highcharts)
DraggablePoints(Highcharts)

interface TSProps extends HighchartsReact.Props {
  chartRef: any
  startDate: moment.Moment
  endDate: moment.Moment
  resolution: Resolution
  ts: Array<string>
  voltageData: Array<number>
  avgVoltage: number
  timezone: string
  title?: string
}

const VoltageAnalysisChart = ({
  chartRef,
  startDate,
  endDate,
  resolution,
  ts,
  voltageData,
  avgVoltage,
  timezone,
  title,
  ...rest
}: TSProps) => {
  const { xAxisMajorTickInterval, xAxisMinorTickInterval } =
    getXAxisTickIntervals(startDate, endDate, resolution)
  const xAxisDateFormat = getXAxisDateFormat()
  const quantity = useQuantity()

  const seriesData: any[] = [
    {
      name: '',
      data: voltageData,
    },
  ]

  const options: Options = {
    ...baseConfig,
    chart: {
      ...baseConfig.chart,
      type: 'line',
      height: '150px',
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [...seriesData],
    tooltip: {
      ...baseConfig.tooltip,
      outside: false,
      useHTML: true,
      backgroundColor: '#fff',
      stickOnContact: true,
      followPointer: false,
      style: {
        pointerEvents: 'auto',
      },
      shared: true,
      formatter(this: TooltipFormatterContextObject) {
        return (
          '<div style="overflow-y: auto; min-height: auto; max-height: 400px; width: 300px">' +
          '<span>' +
          `${moment.tz(this.x, timezone).format(DATE_FORMAT_TOOLTIP)}` +
          '</span>' +
          this.points?.reduce(function (s, point) {
            return (
              s +
              '<br/>' +
              '<span style="' +
              `background-color: ${point.color};` +
              'border-radius: 2px;' +
              'display: inline-block;' +
              'height: 10px;' +
              'width: 4px;' +
              'margin-right: 4px;' +
              '"></span><span style="' +
              'font-size: 12px;' +
              '">' +
              point.series.name +
              ': ' +
              quantity(point.y, 'V')
            )
          }, '') +
          '</div>'
        )
      },
    },
    xAxis: {
      ...baseConfig.xAxis,
      maxPadding: 0,
      minPadding: 0,
      minorTickInterval: xAxisMinorTickInterval,
      minorTicks: true,
      tickInterval: xAxisMajorTickInterval,
      categories: ts,
      labels: {
        formatter(this: AxisLabelsFormatterContextObject) {
          return formatTimestamp(this.value as string, xAxisDateFormat)
        },
      },
    },
    yAxis: {
      ...baseConfig.yAxis,
      title: {
        text: `Voltage`,
      },
      plotLines: [
        {
          width: 2,
          dashStyle: 'Dash',
          value: avgVoltage,
        },
      ],
    },
    exporting: {
      ...baseConfig.exporting,
      buttons: {
        ...baseConfig.exporting?.buttons,
        contextButton: {
          ...baseConfig.exporting?.buttons?.contextButton,
          y: -15,
        },
      },
    },
  }

  return (
    <HighchartsReact
      id={title}
      highcharts={Highcharts}
      options={options as unknown as Options}
      ref={chartRef}
      {...rest}
    />
  )
}

export default VoltageAnalysisChart
