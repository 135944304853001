import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface CurrentCustomerState {
  currentCustomerId: string
  setCurrentCustomerId: (customerId: string) => void
}

export const useCurrentCustomerStore = create<
  CurrentCustomerState,
  [['zustand/persist', CurrentCustomerState]]
>(
  persist(
    (set) => ({
      currentCustomerId: '',
      setCurrentCustomerId: (customerId: string) =>
        set({ currentCustomerId: customerId }),
    }),
    {
      name: 'current-customer',
    }
  )
)
