import AngleLeftIcon from '@iconscout/react-unicons/icons/uil-angle-left'
import AngleRightIcon from '@iconscout/react-unicons/icons/uil-angle-right'
import { ActionIcon } from '@mantine/core'
import clsx from 'clsx'

import styles from './NewMainNav.module.css'

type NavToggleButtonProps = {
  expanded: boolean
  toggle: () => void
}

const NavToggleButton = ({ expanded, toggle }: NavToggleButtonProps) => {
  return (
    <ActionIcon
      className={clsx(styles.navToggleButton, 'navToggleButton')}
      onClick={toggle}
      variant='outline'
    >
      {expanded ? <AngleLeftIcon /> : <AngleRightIcon />}
    </ActionIcon>
  )
}

export default NavToggleButton
