import {
  CheckIcon,
  Combobox,
  ComboboxOptionProps,
  ComboboxParsedItem,
  ComboboxParsedItemGroup,
  Group,
  isOptionsGroup,
  Text,
} from '@mantine/core'

const Item = ({
  selected,
  label,
  ...rest
}: {
  selected?: boolean
  label: string
} & ComboboxOptionProps) => {
  return (
    <Combobox.Option {...rest} active={selected}>
      <Group gap='sm' wrap='nowrap'>
        <div style={{ width: 12 }}>{selected && <CheckIcon size={12} />}</div>
        <Text lineClamp={2}>{label}</Text>
      </Group>
    </Combobox.Option>
  )
}

const OptionGroup = ({
  group,
  isItemSelected,
}: {
  group: ComboboxParsedItemGroup
  isItemSelected: (item: ComboboxParsedItem) => boolean
}) => {
  return (
    <Combobox.Group label={group.group} key={group.group}>
      {group.items.map((item) => (
        <Item
          label={item.label}
          value={item.value}
          key={item.value}
          selected={isItemSelected(item)}
        />
      ))}
    </Combobox.Group>
  )
}

const MultiSelectOptions = ({
  options,
  isItemSelected,
  showSelectAll,
  limit,
}: {
  options: ComboboxParsedItem[]
  isItemSelected: (item: ComboboxParsedItem) => boolean
  showSelectAll: boolean
  limit?: number
}) => {
  const optionsTruncated = limit && options.length > limit
  const limitedOptions = optionsTruncated ? options.slice(0, limit) : options

  return (
    <Combobox.Options mah={300} style={{ overflowY: 'auto' }}>
      {showSelectAll && (
        <Item
          label='Select All'
          value='*'
          key='*'
          selected={isItemSelected({ label: 'Select All', value: '*' })}
        />
      )}
      {limitedOptions.map((groupOrItem) => {
        if (isOptionsGroup(groupOrItem)) {
          return (
            <OptionGroup
              group={groupOrItem}
              key={groupOrItem.group}
              isItemSelected={isItemSelected}
            />
          )
        } else {
          return (
            <Item
              value={groupOrItem.value}
              label={groupOrItem.label}
              key={groupOrItem.value}
              selected={isItemSelected(groupOrItem)}
            />
          )
        }
      })}
      {optionsTruncated && (
        <Item value='' label='Search to see more options…' disabled />
      )}
    </Combobox.Options>
  )
}

export default MultiSelectOptions
