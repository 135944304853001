import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component'
import clsx from 'clsx'
import { forwardRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ParentNavLink } from '.'
import styles from './NewMainNav.module.css'

type NavButtonProps = Omit<ParentNavLink, 'component'> & {
  onClick?: () => void
} & PolymorphicComponentProps<'button', ActionIconProps>

const _NavButton = (
  {
    badge,
    className,
    gainsightId,
    icon,
    isActiveMatcher,
    isHidden,
    label,
    onClick,
    to,
    ...rest
  }: NavButtonProps,
  ref
) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  if (isHidden) return null

  let navigateTo
  if (to) {
    navigateTo = () => {
      navigate(to)
    }
  }

  let active = false
  if (isActiveMatcher) {
    active = isActiveMatcher.test(pathname)
  } else {
    const pathWithOptionalTrailingSlash = new RegExp(`^${to}/?$`)
    active = pathWithOptionalTrailingSlash.test(pathname)
  }

  return (
    <Tooltip label={label} position='right' ref={ref} zIndex={1001}>
      <ActionIcon
        data-active={active}
        data-gainsight-id={gainsightId}
        onClick={onClick ?? navigateTo}
        ref={ref}
        size={38}
        variant='subtle'
        {...rest}
        className={clsx(styles.actionIcon, className)}
      >
        {badge && (
          <span
            className={styles.marker}
            style={{ backgroundColor: badge.color }}
          />
        )}
        {icon}
      </ActionIcon>
    </Tooltip>
  )
}

const NavButton = forwardRef(_NavButton) // Necessary to work as Mantine Popover target

export default NavButton
