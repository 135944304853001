import { isNil } from 'lodash'

import { useFormatNumber } from './FormatNumber'
import { NumberFormatter, NumberFormatterParams } from './types'
import useConvertQuantity from './useConvertQuantity'

const ConvertAndFormat = ({
  value,
  quantityType,
  precision = 2,
}: NumberFormatterParams) => {
  const convertAndFormat = useConvertAndFormat()

  return convertAndFormat(value, quantityType, { precision })
}

export const useConvertAndFormat = (): NumberFormatter => {
  const convertQuantity = useConvertQuantity()
  const formatNumber = useFormatNumber()

  return (value, quantityType, options) => {
    if (isNil(value)) return undefined

    return formatNumber(convertQuantity(value, quantityType, options))
  }
}

export default ConvertAndFormat
