import styled from 'styled-components'

import { SelectStyles } from './ListSelector'
import Paginator, { MainContentStyled, PaginatorStyled } from './Paginator'

export type TSPaginationSettings = {
  currentPage: number // starts at 0
  rowsPerPage: number // default = 10
  totalRows: number // depends on data being paged
}

const SmartPaginatorStyled = styled.div`
  ${PaginatorStyled} {
    flex-direction: row;
  }

  ${MainContentStyled} {
    flex-grow: 1;
  }

  ${SelectStyles} {
    margin-top: 0;
    margin-right: 0;
  }
`

type TSProps = {
  paginationSettings: TSPaginationSettings
  setPaginationSettings: (TSPaginationSettings) => void
  pageSize?: number
  pageSizeChoices?: Array<number>
}

const getNumberOfPages = (paginationSettings: TSPaginationSettings) => {
  return Math.ceil(
    paginationSettings.totalRows / paginationSettings.rowsPerPage
  )
}

// b/c it's 0-indexed, the page count != max possible pageNumber
const getMaxPage = (paginationSettings: TSPaginationSettings) => {
  const numPages = getNumberOfPages(paginationSettings)
  return numPages == 0 ? 0 : numPages - 1
}

export const getFirstVisibleRow = (
  paginationSettings: TSPaginationSettings
) => {
  const firstRow =
    paginationSettings.rowsPerPage * paginationSettings.currentPage
  return firstRow > paginationSettings.totalRows
    ? paginationSettings.totalRows
    : firstRow
}

export const getLastVisibleRow = (paginationSettings: TSPaginationSettings) => {
  const lastRow =
    getFirstVisibleRow(paginationSettings) +
    (paginationSettings.rowsPerPage - 1)
  return lastRow > paginationSettings.totalRows
    ? paginationSettings.totalRows
    : lastRow
}

const isPrevEnabled = (paginationSettings: TSPaginationSettings) => {
  return paginationSettings.currentPage > 0
}

const isNextEnabled = (paginationSettings: TSPaginationSettings) => {
  return (
    paginationSettings.totalRows - 1 > getLastVisibleRow(paginationSettings)
  )
}

const goToPreviousPage = (
  paginationSettings: TSPaginationSettings,
  setPaginationSettings: (paginationSettings: TSPaginationSettings) => void
) => {
  setPaginationSettings({
    ...paginationSettings,
    currentPage:
      paginationSettings.currentPage <= 1
        ? 0
        : paginationSettings.currentPage - 1,
  })
}

const goToNextPage = (
  paginationSettings: TSPaginationSettings,
  setPaginationSettings: (paginationSettings: TSPaginationSettings) => void
) => {
  const maxPage = getNumberOfPages(paginationSettings)
  setPaginationSettings({
    ...paginationSettings,
    currentPage:
      paginationSettings.currentPage >= maxPage
        ? maxPage
        : paginationSettings.currentPage + 1,
  })
}

// newPage is the 0-based index, not the number that shows on the page
const goToPage = (
  newPage: number,
  paginationSettings: TSPaginationSettings,
  setPaginationSettings: (paginationSettings: TSPaginationSettings) => void
) => {
  const maxPage = getMaxPage(paginationSettings)
  const newSettings = {
    ...paginationSettings,
    currentPage: newPage < 0 ? 0 : newPage,
  }
  if (newPage > maxPage) {
    newSettings.currentPage = maxPage
  }
  setPaginationSettings(newSettings)
}

// handles a change to the number of rows visible per page
const updatePageSize = (
  newPageSize: number,
  paginationSettings: TSPaginationSettings,
  setPaginationSettings: (paginationSettings: TSPaginationSettings) => void
) => {
  const newSettings = {
    ...paginationSettings,
    rowsPerPage: newPageSize,
  }
  const maxPage = getMaxPage(newSettings)
  if (newSettings.currentPage > maxPage) {
    newSettings.currentPage = maxPage
  }
  setPaginationSettings(newSettings)
}

const SmartPaginator = (props: TSProps) => {
  const { paginationSettings, setPaginationSettings, pageSizeChoices } = props
  return (
    <SmartPaginatorStyled>
      <Paginator
        pageSize={paginationSettings.rowsPerPage}
        pageSizeChoices={pageSizeChoices}
        gotoPage={(newPage: number) => {
          goToPage(newPage, paginationSettings, setPaginationSettings)
        }}
        canNextPage={isNextEnabled(paginationSettings)}
        nextPage={() => {
          goToNextPage(paginationSettings, setPaginationSettings)
        }}
        canPreviousPage={isPrevEnabled(paginationSettings)}
        previousPage={() => {
          goToPreviousPage(paginationSettings, setPaginationSettings)
        }}
        pageIndex={paginationSettings.currentPage}
        pageOptions={{ length: getNumberOfPages(paginationSettings) }}
        setPageSize={(newPageSize: number) => {
          updatePageSize(newPageSize, paginationSettings, setPaginationSettings)
        }}
      />
    </SmartPaginatorStyled>
  )
}

export default SmartPaginator
