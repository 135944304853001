import { TSCustomersResponse } from '../../ducks/customers'

export const TWENTY_FOUR_HOUR_FITNESS_ID =
  '9cdd900e-473c-4f79-98de-1a873bb52ead'
const customers: TSCustomersResponse = [
  {
    customerLogoUrl:
      'https://logo-denali-dev.s3.amazonaws.com/r1_logo/40368e2d-b776-43ba-9046-63de2e206732/Bridge_industrial-bridge_industrial.png',
    id: TWENTY_FOUR_HOUR_FITNESS_ID,
    name: '24 Hour Fitness',
    notifyDataBehind: true,
    validName: '24 Hour Fitness',
    initials: '2H',
    logoBackgroundColor: '#ff0000',
  },
  {
    id: '5ff953f9-eb68-4d65-b8b8-591a4be92ef5',
    name: 'Able Manufacturing',
    notifyDataBehind: true,
    validName: 'Able Manufacturing',
    initials: '',
    logoBackgroundColor: '#ff0000',
  },
  {
    id: '730af796-9fc9-473c-8868-f5b37d40c73c',
    name: 'Access Corp',
    notifyDataBehind: true,
    validName: 'Access Corp',
    initials: 'AC',
    logoBackgroundColor: '',
  },
  {
    id: '6c0520ca-9b2d-47b4-a372-5b1266c5a5ff',
    name: 'Albertsons',
    notifyDataBehind: true,
    validName: 'Albertsons',
    initials: 'A',
    logoBackgroundColor: '',
  },
  {
    id: 'b2ba5c89-611e-4bc1-8f56-47a20e3ff398',
    name: 'ALG',
    notifyDataBehind: true,
    validName: 'ALG',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '168f736f-23d5-4d69-869f-3d162bb465ac',
    name: 'ALG Senior',
    notifyDataBehind: true,
    validName: 'ALG Senior',
    initials: 'AS',
    logoBackgroundColor: '',
  },
  {
    id: '6944d15e-2fdf-4ce6-8af0-8b4219fd8f10',
    name: 'AllianceHealth',
    notifyDataBehind: true,
    validName: 'AllianceHealth',
    initials: 'AH',
    logoBackgroundColor: '',
  },
  {
    id: 'abbbf24a-6bfb-453b-8b17-a8eb71ba7a44',
    name: 'Ally Energy Solutions',
    notifyDataBehind: true,
    validName: 'Ally Energy Solutions',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '651ec833-6756-4ef0-a90d-7b97fb0c5543',
    name: 'American Golf',
    notifyDataBehind: true,
    validName: 'American Golf',
    initials: 'AG',
    logoBackgroundColor: '',
  },
  {
    id: '6abe4bc1-14f3-4874-b2df-4965a9db26ac',
    name: 'Antelope Valley Hospital',
    notifyDataBehind: true,
    validName: 'Antelope Valley Hospital',
    initials: '',
    logoBackgroundColor: '#9DBD79',
  },
  {
    id: '13e72426-509e-44cf-9fe1-fc15df4fd9b8',
    name: 'Aramark',
    externalId: '3341',
    notifyDataBehind: true,
    validName: 'Aramark',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '7c494449-0ddd-4680-a896-ccbef2fac0d3',
    name: 'AstraZeneca',
    notifyDataBehind: true,
    validName: 'AstraZeneca',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'e8df0b2e-6b30-42a6-beb8-6cbdac052807',
    name: 'ATD',
    notifyDataBehind: true,
    validName: 'ATD',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '202009c6-a567-42e0-b554-839393b6e320',
    name: 'AT&T',
    notifyDataBehind: true,
    validName: 'AT&T',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'd39fe31f-22ef-41f0-ab74-0b00ebb24f3b',
    name: 'Avantor Inc',
    notifyDataBehind: true,
    validName: 'Avantor Inc',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '3e1241f5-fa6d-4bfe-9c55-ec4369657e28',
    name: 'Baker Hughes',
    notifyDataBehind: true,
    validName: 'Baker Hughes',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'ebb49eb9-2c16-4da4-b4bd-d24daa522aa0',
    name: 'Bank of America',
    notifyDataBehind: true,
    validName: 'Bank of America',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '7ef55344-3ed6-4277-9d0c-2fe36af2371f',
    name: 'Bentley Mills',
    notifyDataBehind: true,
    validName: 'Bentley Mills',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'f6e7cee7-0922-4296-b73c-07ff6df3cb0a',
    name: 'Berry Global',
    notifyDataBehind: true,
    validName: 'Berry Global',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '3f98a193-e0bf-44aa-81bb-5359f952b147',
    name: 'BHA',
    notifyDataBehind: true,
    validName: 'BHA',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '5c4d0d62-f053-4030-bfab-f9e5ef7d9772',
    name: 'Blue Star Properties',
    notifyDataBehind: true,
    validName: 'Blue Star Properties',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '39e905c0-e1bc-4e6a-bde5-ba9c29d36e82',
    name: 'Bright Horizons',
    notifyDataBehind: true,
    validName: 'Bright Horizons',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '24c7185c-5b4d-4ddb-beb7-7f54d861db06',
    name: 'Brooks Sports Inc',
    notifyDataBehind: true,
    validName: 'Brooks Sports Inc',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'aab16b36-90a6-41f4-aef9-5a284b5bfd0b',
    name: 'Bullion Primary School',
    notifyDataBehind: true,
    validName: 'Bullion Primary School',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'a9426115-0199-4698-a16f-0b6d7170ff0f',
    name: 'Bumper to Bumper',
    notifyDataBehind: true,
    validName: 'Bumper to Bumper',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '8e5995e5-e5e6-4826-8ded-b235f5f6842b',
    name: 'Cabinetworks Group',
    notifyDataBehind: true,
    validName: 'Cabinetworks Group',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '08d24672-a955-486a-a423-50d3399b0ea1',
    name: 'Cafaro Company',
    notifyDataBehind: true,
    validName: 'Cafaro Company',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '256a92ea-c144-4727-923a-e4228fbdc9fb',
    name: 'Caliber Collision',
    notifyDataBehind: true,
    validName: 'Caliber Collision',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '6521ab06-9dab-4a15-97ed-b2f3faf899d4',
    name: 'Cascade Energy',
    notifyDataBehind: true,
    validName: 'Cascade Energy',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '292d7bd2-c3c6-4e67-8178-b38574a47500',
    name: 'CED Sample',
    notifyDataBehind: true,
    validName: 'CED Sample',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'f5a4921b-4f77-44b0-8731-4f5c9ced0819',
    name: 'Cinemark',
    notifyDataBehind: true,
    validName: 'Cinemark',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '2f3e316c-a243-414f-8188-933640a4d4e1',
    name: 'Cintas',
    notifyDataBehind: true,
    validName: 'Cintas',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '54800a44-f72d-4bb7-b154-9aa6bfc48d64',
    name: 'City of Reno',
    notifyDataBehind: true,
    validName: 'City of Reno',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '0774e68b-663a-4906-9196-aae0a5ed3c83',
    name: 'CityVet',
    notifyDataBehind: true,
    validName: 'CityVet',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '860a4e8d-feaa-44eb-af33-9b60e4ac18a3',
    name: 'Clark Art Institute',
    notifyDataBehind: true,
    validName: 'Clark Art Institute',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'f0f94215-e29d-478c-92b6-36c7af52764b',
    name: 'Cobalt Boats',
    notifyDataBehind: true,
    validName: 'Cobalt Boats',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'a41a55de-3d5c-4507-86ee-0c13610ea81f',
    name: 'CommScope',
    notifyDataBehind: true,
    validName: 'CommScope',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'ce739160-a1e2-45d3-827c-882484d4038b',
    name: 'Constellation',
    notifyDataBehind: true,
    validName: 'Constellation',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'a77c6545-90c0-403a-a84c-00cef51a8a2b',
    name: 'Craftworks',
    notifyDataBehind: true,
    validName: 'Craftworks',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '3f318428-4b99-4885-909d-257d22ee1a22',
    name: 'CSG',
    notifyDataBehind: true,
    validName: 'CSG',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '471d0b01-e6cc-424d-8235-1da0f8447f6a',
    name: 'CSX Corporation',
    notifyDataBehind: true,
    validName: 'CSX Corporation',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '9122b42b-4354-403f-a23c-46cdf8de374f',
    name: 'Cyrus One',
    notifyDataBehind: true,
    validName: 'Cyrus One',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: 'ca45d69a-56bc-4668-95e5-b9e928bb9ae7',
    name: 'Dalkia HQ',
    notifyDataBehind: true,
    validName: 'Dalkia HQ',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '6a8dd8ce-0728-43c9-a214-4f6697395bec',
    name: 'Darlington Manufacturing',
    notifyDataBehind: true,
    validName: 'Darlington Manufacturing',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '2ccd91cb-6839-4962-a029-6d0445a96106',
    name: 'DaVita',
    notifyDataBehind: true,
    validName: 'DaVita',
    initials: '',
    logoBackgroundColor: '',
  },
  {
    id: '2d5bc56a-c8a6-419a-b401-57244555c53d',
    name: 'Default',
    notifyDataBehind: true,
    validName: 'Default',
  },
  {
    id: '6b39304c-d558-47fe-bbc5-a842797761a2',
    name: 'Dimora',
    notifyDataBehind: true,
    validName: 'Dimora',
  },
  {
    id: 'f7464564-d358-4f2c-8277-00969d580324',
    name: 'DS Services',
    notifyDataBehind: true,
    validName: 'DS Services',
  },
  {
    id: 'e9e6aea8-b734-466f-88ad-f73c90bf1146',
    name: 'Duke Realty',
    notifyDataBehind: true,
    validName: 'Duke Realty',
  },
  {
    id: '818e7944-b30f-4521-a87f-e49729dea2f9',
    name: 'East Newton High School - Granby MO',
    notifyDataBehind: true,
    validName: 'East Newton High School - Granby MO',
  },
  {
    id: 'abe67b50-6864-458c-9490-b64d246934ce',
    name: 'Engie',
    notifyDataBehind: true,
    validName: 'Engie',
  },
  {
    id: '4daedc07-7ed2-43b7-841c-c9b8e43e9ccd',
    name: 'ePac',
    notifyDataBehind: true,
    validName: 'ePac',
  },
  {
    id: '6f58f473-7d33-4eb0-8538-5bb0910cadf4',
    name: 'Evangel University',
    notifyDataBehind: true,
    validName: 'Evangel University',
  },
  {
    id: 'a8b204bc-ff6e-46aa-a5fa-0b19514fcddd',
    name: 'FedEx',
    notifyDataBehind: true,
    validName: 'FedEx',
  },
  {
    id: 'b863b18a-bce9-42c2-b330-273e63985637',
    name: 'Foley Estates',
    notifyDataBehind: true,
    validName: 'Foley Estates',
  },
  {
    id: '7a05a135-a091-48ec-98e9-24e0f58867e5',
    name: 'Food 4 Less',
    notifyDataBehind: true,
    validName: 'Food 4 Less',
  },
  {
    id: '4c5a64bf-2917-4ee4-ab6d-71968ee39ceb',
    name: 'Forrest Creasy',
    notifyDataBehind: true,
    validName: 'Forrest Creasy',
  },
  {
    id: '109c3c8e-ef83-4aad-ae4f-9e0c44f04d38',
    name: 'FSG',
    notifyDataBehind: true,
    validName: 'FSG',
  },
  {
    id: 'dca6be41-9ccc-471b-b80f-aa1c420fb459',
    name: 'Gatehouse',
    notifyDataBehind: true,
    validName: 'Gatehouse',
  },
  {
    id: 'c79539b9-a487-45e1-b3b0-9f00c375a026',
    name: 'General Motors Tonawanda',
    notifyDataBehind: true,
    validName: 'General Motors Tonawanda',
  },
  {
    id: 'ce3ee424-ddd5-4255-90b7-c3e8806964a9',
    name: 'GKN Aerospace',
    notifyDataBehind: true,
    validName: 'GKN Aerospace',
  },
  {
    id: 'd0b9cbe2-11a4-480d-a414-ccda3aa412d5',
    name: 'Glazer’s Beer & Beverage',
    notifyDataBehind: true,
    validName: 'Glazer’s Beer & Beverage',
  },
  {
    id: '59dd7da1-2ea5-42de-ad4a-e357e82c0e4c',
    name: 'Golden Chick',
    notifyDataBehind: true,
    validName: 'Golden Chick',
  },
  {
    id: '17cf0d19-ea69-4086-91c5-5cfd3425473a',
    name: 'Goodyear',
    notifyDataBehind: true,
    validName: 'Goodyear',
  },
  {
    id: '3ffef0d8-f8ca-4e51-ad07-536a916a1182',
    name: 'Grand Lake Casino',
    notifyDataBehind: true,
    validName: 'Grand Lake Casino',
  },
  {
    id: 'c808320c-8fee-4448-8064-6923e0f48fd1',
    name: 'Griffith Foods',
    notifyDataBehind: true,
    validName: 'Griffith Foods',
  },
  {
    id: '893a0e8f-fdf9-43ad-a9b3-e2cee6acf508',
    name: 'Halliburton',
    notifyDataBehind: true,
    validName: 'Halliburton',
  },
  {
    id: 'caf6998c-5a1c-4504-921c-ba3305125e44',
    name: 'Harps Food',
    notifyDataBehind: true,
    validName: 'Harps Food',
  },
  {
    id: '2d231fbd-fc4f-4613-9c23-4da700df547c',
    name: 'Holiday Retirement',
    notifyDataBehind: true,
    validName: 'Holiday Retirement',
  },
  {
    id: '136289f0-0c2f-4e6e-8da3-bfbb63d2fd83',
    name: 'IBM',
    notifyDataBehind: true,
    validName: 'IBM',
  },
  {
    id: 'ced11727-f252-47a4-9ee6-ab2682cdea4d',
    name: 'INAP',
    notifyDataBehind: true,
    validName: 'INAP',
  },
  {
    id: '0cb57208-d38e-49f0-b491-8b80487265ad',
    name: 'Institute for Advanced Study',
    notifyDataBehind: true,
    validName: 'Institute for Advanced Study',
  },
  {
    id: '805ef5ad-d27f-45a7-8b7e-d18042630c63',
    name: 'International Electron',
    notifyDataBehind: true,
    validName: 'International Electron',
  },
  {
    id: '5ccd2b72-f879-4ec2-8c2e-28338123bc1a',
    name: 'Iron Mountain',
    notifyDataBehind: true,
    validName: 'Iron Mountain',
  },
  {
    id: 'de42e009-f388-4094-b015-6e4bdc7b3d0e',
    name: 'Iron Mountain Europe',
    notifyDataBehind: true,
    validName: 'Iron Mountain Europe',
  },
  {
    id: 'd2dd236b-abe6-408c-8dfd-bf9bd0cf505c',
    name: 'Iron Mountain UK',
    notifyDataBehind: true,
    validName: 'Iron Mountain UK',
  },
  {
    id: 'd3cac4f7-b843-41c5-a245-588099bf26b1',
    name: 'JCI Industries Inc',
    notifyDataBehind: true,
    validName: 'JCI Industries Inc',
  },
  {
    id: '7e5d2581-305b-43dc-b4b7-d5a32073f083',
    name: 'Jones College',
    notifyDataBehind: true,
    validName: 'Jones College',
  },
  {
    id: 'e9c15201-948b-419d-80f5-271464758d04',
    name: 'KAR AUCTION SERVICES INC.',
    notifyDataBehind: true,
    validName: 'KAR AUCTION SERVICES INC.',
  },
  {
    id: '6dbe07ce-afc3-46bd-aa82-a648ad158a6f',
    name: 'Kerry - IsoAge Technologies',
    notifyDataBehind: true,
    validName: 'Kerry - IsoAge Technologies',
  },
  {
    id: '591c700e-b659-4685-bd85-0ceef142bea5',
    name: 'King Saver Foods',
    notifyDataBehind: true,
    validName: 'King Saver Foods',
  },
  {
    id: '8ca86ae4-2e3e-4bc8-99cb-3e3af8be01ce',
    name: 'LA Specialty',
    notifyDataBehind: true,
    validName: 'LA Specialty',
  },
  {
    id: '683ce559-709a-492d-beb8-986fc3b73d59',
    name: 'Leggett & Platt',
    notifyDataBehind: true,
    validName: 'Leggett & Platt',
  },
  {
    id: 'df99c77a-c1f5-4587-b1bb-c5750e2636f2',
    name: 'Light Serve',
    notifyDataBehind: true,
    validName: 'Light Serve',
  },
  {
    id: 'd26d5503-5269-4ffe-b3dc-7d3bf1242783',
    name: 'Living Spaces',
    notifyDataBehind: true,
    validName: 'Living Spaces',
  },
  {
    id: '8c56f9fd-30b7-41bf-af7b-b7f2f82f88d0',
    name: 'LoneStar Auto',
    notifyDataBehind: true,
    validName: 'LoneStar Auto',
  },
  {
    id: '2cce12fe-d722-458c-badc-74093a6cec65',
    name: 'Louis Vuitton',
    notifyDataBehind: true,
    validName: 'Louis Vuitton',
  },
  {
    id: 'a49e9d31-26ff-40b2-98a9-6a6b2b7cb044',
    name: 'Marriott',
    notifyDataBehind: true,
    validName: 'Marriott',
  },
  {
    id: '9702b3a4-1471-4666-82fe-a8f696405cee',
    name: 'Masonite',
    notifyDataBehind: true,
    validName: 'Masonite',
  },
  {
    id: 'a13e9d4c-cace-4aa9-a9d5-835168becc7e',
    name: 'Mears Floral',
    notifyDataBehind: true,
    validName: 'Mears Floral',
  },
  {
    id: 'f7581b91-178d-41a7-b3ef-14a85264ec89',
    name: 'Medical Center of Arkansas',
    notifyDataBehind: true,
    validName: 'Medical Center of Arkansas',
  },
  {
    id: '49eeb44e-fe1e-46db-9210-582d67669096',
    name: 'National Flight Academy',
    notifyDataBehind: true,
    validName: 'National Flight Academy',
  },
  {
    id: 'abdf30ae-da94-4735-8aac-c7ad87f9781c',
    name: 'Navitas Equity Solutions',
    notifyDataBehind: true,
    validName: 'Navitas Equity Solutions',
  },
  {
    id: '95e35bda-04b0-42f3-bf7e-e1dec2cf6979',
    name: 'New Senior - Blue Harbor',
    notifyDataBehind: true,
    validName: 'New Senior - Blue Harbor',
  },
  {
    id: '10e4a172-0387-4c8e-baff-3310b4d498f0',
    name: 'New Senior - Grace Management',
    notifyDataBehind: true,
    validName: 'New Senior - Grace Management',
  },
  {
    id: 'a2d7e6e2-cafa-4ace-ad96-8b4ed0ca7907',
    name: 'New Senior - Holiday Retirement',
    notifyDataBehind: true,
    validName: 'New Senior - Holiday Retirement',
  },
  {
    id: '80c30bf3-3f6c-42ef-9805-9e2aac6a9dff',
    name: 'New Senior - Watermark Retirement',
    notifyDataBehind: true,
    validName: 'New Senior - Watermark Retirement',
  },
  {
    id: 'a04e9b55-ae98-4a97-a5fb-c0a481d7b1d1',
    name: 'NGC Renewables',
    notifyDataBehind: true,
    validName: 'NGC Renewables',
  },
  {
    id: 'e7b56bbb-3acc-416b-a8dd-27c63a373e1e',
    name: 'OzarkLED',
    notifyDataBehind: true,
    validName: 'OzarkLED',
  },
  {
    id: '07257b12-cf21-4c8e-b220-006d8c387be0',
    name: 'Parts Authority',
    notifyDataBehind: true,
    validName: 'Parts Authority',
  },
  {
    id: '2f085745-9b8c-4a17-bf48-3c2e63eda050',
    name: 'PCA',
    notifyDataBehind: true,
    validName: 'PCA',
  },
  {
    id: 'dad3f0a6-f318-418a-a2a8-c943ed71e0d8',
    name: 'Pennington Seed',
    notifyDataBehind: true,
    validName: 'Pennington Seed',
  },
  {
    id: '84a0889e-3734-4426-9bca-667799dc5a65',
    name: 'PPG',
    notifyDataBehind: true,
    validName: 'PPG',
  },
  {
    id: '99651c00-33a8-499a-9439-cf89eb3c9442',
    name: 'Price Cutter',
    notifyDataBehind: true,
    validName: 'Price Cutter',
  },
  {
    id: '083d91da-8557-4a88-9733-d3a1f6f906e8',
    name: 'Prologis - LightSmart: Amazon',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Amazon',
  },
  {
    id: '902501ca-0264-4827-869e-9e0c39ff05b3',
    name: 'Prologis - LightSmart: Amcor Rigid Plastics',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Amcor Rigid Plastics',
  },
  {
    id: '1b0499b5-9f83-443a-8439-9d8610524cd3',
    name: 'Prologis - LightSmart: Batavia Container',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Batavia Container',
  },
  {
    id: '0691e462-74cd-4c6b-85d1-183b3adbd4a5',
    name: 'Prologis - LightSmart: Bay Valley Foods',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Bay Valley Foods',
  },
  {
    id: '47d9135b-e26a-42df-91b3-f081917505db',
    name: 'Prologis - LightSmart: B&G Foods, Inc.',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: B&G Foods, Inc.',
  },
  {
    id: '3bf5af65-ae90-439b-9932-8894fc44c459',
    name: 'Prologis - LightSmart: Bosch',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Bosch',
  },
  {
    id: '01fc452a-04a7-43a9-9bc4-7a7945104bfc',
    name: 'Prologis - LightSmart: Bright Event Rentals',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Bright Event Rentals',
  },
  {
    id: 'b62e21de-4436-4ba9-ac3a-48ccf9336e8c',
    name: 'Prologis - LightSmart: Coaster Company of America',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Coaster Company of America',
  },
  {
    id: '92b48140-0c3e-48f4-bef0-5efbced685b9',
    name: 'Prologis - LightSmart: Converse',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Converse',
  },
  {
    id: 'd764090b-29c8-4ed1-9d1b-e8340535a0d2',
    name: 'Prologis - LightSmart: Cooper Tire & Rubber Company',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Cooper Tire & Rubber Company',
  },
  {
    id: '4555d621-d0ff-4cef-a386-a44f87c64c3b',
    name: 'Prologis - LightSmart: Crossville',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Crossville',
  },
  {
    id: 'bddeeace-b878-46ce-9c68-5cc6b0387ece',
    name: 'Prologis - LightSmart: CTDI',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: CTDI',
  },
  {
    id: 'bff327be-13ed-45e8-bad7-b3d60e48ff9e',
    name: 'Prologis - LightSmart: DHL / Toto',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: DHL / Toto',
  },
  {
    id: 'ea4e3d8b-b7b4-46b5-9270-1431ab8aae56',
    name: 'Prologis - LightSmart: DSV Solutions',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: DSV Solutions',
  },
  {
    id: '0f7109a5-ecca-455a-9334-9a834c269d71',
    name: 'Prologis - LightSmart: Eaton Lighting',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Eaton Lighting',
  },
  {
    id: '29b4deec-8594-489a-8bb6-9f7d9e49b3fa',
    name: 'Prologis - LightSmart: EM Solutions Inc',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: EM Solutions Inc',
  },
  {
    id: '037c8938-b690-482c-a5ec-ee13976f5fce',
    name: 'Prologis - LightSmart: General Mills',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: General Mills',
  },
  {
    id: '5bea68da-b99b-4f84-a0b1-8a4c65e34587',
    name: 'Prologis - LightSmart: Geodis',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Geodis',
  },
  {
    id: '35aa570d-2d03-4d21-bc70-966dab424f68',
    name: 'Prologis - LightSmart: Great Central Transport',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Great Central Transport',
  },
  {
    id: '8eae1b20-cd1e-4189-b11e-ac9147451e1d',
    name: 'Prologis - LightSmart: Hawthorne Hydroponics',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Hawthorne Hydroponics',
  },
  {
    id: '10493677-32f5-4669-a4c5-828c73536860',
    name: 'Prologis - LightSmart: HD Supply',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: HD Supply',
  },
  {
    id: '1799b114-04f3-451c-b24d-545ba428257b',
    name: 'Prologis - LightSmart: Head USA',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Head USA',
  },
  {
    id: 'ec14029d-44a6-419c-8c3b-eff4da8d03a1',
    name: 'Prologis - LightSmart: Javelin Logistics',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Javelin Logistics',
  },
  {
    id: '5a002b75-3ebb-4975-84e0-be7cc8942645',
    name: 'Prologis - LightSmart: J&D Refrigerated Services',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: J&D Refrigerated Services',
  },
  {
    id: 'ffabd528-8d11-48ef-aa1c-8b386850f3de',
    name: 'Prologis - LightSmart: Johnstone Supply',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Johnstone Supply',
  },
  {
    id: '37919b00-6e10-4bd3-8217-06fdf83dcab2',
    name: 'ProLogis - LightSmart: Kellogg',
    notifyDataBehind: true,
    validName: 'ProLogis - LightSmart: Kellogg',
  },
  {
    id: '80785f57-80c5-463f-832a-788dbb6e486a',
    name: 'ProLogis - LightSmart: Kellogg Tracy',
    notifyDataBehind: true,
    validName: 'ProLogis - LightSmart: Kellogg Tracy',
  },
  {
    id: '6a169b89-5a10-45f4-95f4-31612ccabfe8',
    name: 'Prologis - LightSmart: Kintetsu',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Kintetsu',
  },
  {
    id: '09522c64-7c35-4cc5-b146-e8cd1bd78db5',
    name: 'Prologis - LightSmart: Lancaster',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Lancaster',
  },
  {
    id: 'dfc22829-c73f-406b-b092-9b82f5cb747f',
    name: 'Prologis - LightSmart: Masterbrand Cabinets',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Masterbrand Cabinets',
  },
  {
    id: '81637ec9-278a-419c-89c6-4143fb5f60ba',
    name: 'Prologis - LightSmart: Mizuho OSI',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Mizuho OSI',
  },
  {
    id: 'a1853421-2d55-4122-8a6c-398b699e1df6',
    name: 'Prologis - LightSmart: NFI',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: NFI',
  },
  {
    id: '6e701260-01a4-4d3e-81ac-fc6151389a5d',
    name: 'Prologis - LightSmart: Northern Refrigerated',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Northern Refrigerated',
  },
  {
    id: '4342ec5c-2d71-4353-bf67-80c3b656a089',
    name: 'Prologis - LightSmart: Office Depot',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Office Depot',
  },
  {
    id: 'e81dc66e-a5c3-4dea-86a1-aaf594aff7d5',
    name: 'Prologis - LightSmart: Orion Logistics',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Orion Logistics',
  },
  {
    id: 'ac400845-d851-420a-b396-22f41f1ceb8f',
    name: 'Prologis - LightSmart: Pacific Produce',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Pacific Produce',
  },
  {
    id: 'f5f296e2-39ce-4eb1-99ec-b1f2e50214d2',
    name: 'Prologis - LightSmart: Ricoh America',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Ricoh America',
  },
  {
    id: '747a7c01-053f-4ecb-acf8-aed729c0b203',
    name: 'Prologis - LightSmart: Royal Interpack',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Royal Interpack',
  },
  {
    id: '135552cc-95c4-4c6a-a176-15c2d3b951b1',
    name: 'Prologis - LightSmart: RTC Aerospace',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: RTC Aerospace',
  },
  {
    id: 'bf6a8650-d02a-4d77-bc65-2698e9d3c37d',
    name: 'Prologis - LightSmart: Sandvik',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Sandvik',
  },
  {
    id: '23715f52-005e-4bf7-b50d-7efee8592636',
    name: 'Prologis - LightSmart: Source Logistics',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Source Logistics',
  },
  {
    id: 'e1d2cda3-3b67-456d-b1f6-e729e95feedb',
    name: 'Prologis - LightSmart: Starbucks',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Starbucks',
  },
  {
    id: '13a3463c-1320-487d-ab41-b1ac14c76f7b',
    name: 'Prologis - LightSmart: Sygma Network',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Sygma Network',
  },
  {
    id: '418d31bc-a82b-4787-acaf-ecfa08fa65bf',
    name: 'Prologis - LightSmart: Walmart Inc',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Walmart Inc',
  },
  {
    id: '6f29732a-a994-48ef-87ca-214f4002d06f',
    name: 'Prologis - LightSmart: Windkits',
    notifyDataBehind: true,
    validName: 'Prologis - LightSmart: Windkits',
  },
  {
    id: 'e9def66b-6578-444f-8ba9-244357471c5a',
    name: 'Prospect Medical',
    notifyDataBehind: true,
    validName: 'Prospect Medical',
  },
  {
    id: 'a72cf346-34a3-44f9-b66f-6172d70f98d8',
    name: 'Pyramid Foods',
    notifyDataBehind: true,
    validName: 'Pyramid Foods',
  },
  {
    id: '3584811b-9f53-4276-b2ba-e8b778c6c2e5',
    name: 'Quikrete',
    notifyDataBehind: true,
    validName: 'Quikrete',
  },
  {
    id: '532da230-fb2b-4197-856c-ca6534a0d6dc',
    name: 'Redaptive',
    notifyDataBehind: true,
    validName: 'Redaptive',
  },
  {
    id: '2f54e162-c2a8-466d-899b-743207926952',
    name: 'Redaptive Headquarters',
    notifyDataBehind: false,
    validName: 'Redaptive Headquarters',
  },
  {
    id: '8fd3fd50-6ee0-4359-9fd2-a70a4e9455b4',
    name: 'Redaptive Test Meters',
    notifyDataBehind: false,
    validName: 'Redaptive Test Meters',
  },
  {
    id: 'aa95a584-6821-4da5-99fb-6db421e6d082',
    name: 'Riverside University Health System',
    notifyDataBehind: true,
    validName: 'Riverside University Health System',
  },
  {
    id: 'b681468d-3522-486b-bab0-d926e51bd4c3',
    name: 'Robert Skrzypinski',
    notifyDataBehind: true,
    validName: 'Robert Skrzypinski',
  },
  {
    id: '07575d14-66db-415c-a1c6-722c3a7b74e5',
    name: 'Ryerson Steel',
    notifyDataBehind: true,
    validName: 'Ryerson Steel',
  },
  {
    id: 'f997d990-392c-4609-a37c-598a0d164cee',
    name: 'Saint-Gobain',
    notifyDataBehind: true,
    validName: 'Saint-Gobain',
  },
  {
    id: '86c2a0d8-43e2-4209-b58b-71ec1936d6cf',
    name: 'Saint-Gobain - Huntsville',
    notifyDataBehind: true,
    validName: 'Saint-Gobain - Huntsville',
  },
  {
    id: 'a0c33027-f2ad-40e3-bdf5-fb024d01c0c6',
    name: 'Schlumberger',
    notifyDataBehind: true,
    validName: 'Schlumberger',
  },
  {
    id: '732fd6b0-b65a-4e30-a30a-24c5ee80d373',
    name: 'Sentient Buildings HQ',
    notifyDataBehind: true,
    validName: 'Sentient Buildings HQ',
  },
  {
    id: '7c817a96-8bc4-4d55-b389-de7c60a7e776',
    name: 'Service King',
    notifyDataBehind: true,
    validName: 'Service King',
  },
  {
    id: 'd041f220-f5cf-49f9-b38e-71ccd91514d9',
    name: 'Seville Care Center',
    notifyDataBehind: true,
    validName: 'Seville Care Center',
  },
  {
    id: '4c1c0e2c-60cc-451a-80e4-8188a14459b2',
    name: 'Signature Flight',
    notifyDataBehind: true,
    validName: 'Signature Flight',
  },
  {
    id: 'aea974b9-5d1b-4082-848e-0e339b61e592',
    name: 'Sika',
    notifyDataBehind: true,
    validName: 'Sika',
  },
  {
    id: 'b9414141-db56-456b-9c30-b004c81028fe',
    name: 'Siloam Springs Regional Hospital',
    notifyDataBehind: true,
    validName: 'Siloam Springs Regional Hospital',
  },
  {
    id: '4b5708b7-50e0-4dd2-95a6-1d1de5b8f97c',
    name: 'Simmons Jan Hatchery',
    notifyDataBehind: true,
    validName: 'Simmons Jan Hatchery',
  },
  {
    id: '82d7e08c-c51b-45be-b49d-4a63e9f51f72',
    name: 'Simmons Pet Food Inc',
    notifyDataBehind: true,
    validName: 'Simmons Pet Food Inc',
  },
  {
    id: '3171a645-108e-4c7e-9e11-250f78f6f6d2',
    name: 'Smart & Final',
    notifyDataBehind: true,
    validName: 'Smart & Final',
  },
  {
    id: '7cab578a-debd-44b8-a13e-49907ab752be',
    name: 'Sonepar Gresham ',
    notifyDataBehind: true,
    validName: 'Sonepar Gresham ',
  },
  {
    id: '8b6df2a2-69c5-4c18-b4c6-3b390444641e',
    name: 'SRS',
    externalId: '113968',
    notifyDataBehind: true,
    validName: 'SRS',
  },
  {
    id: 'c9798d35-be37-4b6c-bc1e-c15e9418b560',
    name: 'Stag Industrial',
    notifyDataBehind: true,
    validName: 'Stag Industrial',
  },
  {
    id: 'ce9745d4-90dd-49ed-8376-2bebaa400a22',
    name: 'State on Campus Morgantown',
    notifyDataBehind: true,
    validName: 'State on Campus Morgantown',
  },
  {
    id: '12e71dec-7f72-41fc-a0fc-ae0f3fe35532',
    name: 'S&T Bank',
    notifyDataBehind: true,
    validName: 'S&T Bank',
  },
  {
    id: '4e4bb442-ff19-4ddb-82c3-d7cacb3cae5a',
    name: 'Suncor',
    notifyDataBehind: true,
    validName: 'Suncor',
  },
  {
    id: '03cf955f-96c5-44dd-95f5-fbbbd0d7cdbe',
    name: '_Suspended - Pending Fix',
    notifyDataBehind: true,
    validName: '_Suspended - Pending Fix',
  },
  {
    id: '35d56ba2-df4b-4ce2-a7bd-7748f0230f5a',
    name: '*Suspended - Pending Fix',
    notifyDataBehind: true,
    validName: '*Suspended - Pending Fix',
  },
  {
    id: '7a674224-4081-434e-b44c-aee61be39dbd',
    name: 'Swanson Group',
    notifyDataBehind: true,
    validName: 'Swanson Group',
  },
  {
    id: 'de708e6c-6135-4201-9a83-006cec4e5ae8',
    name: 'Swedish Medical Center',
    notifyDataBehind: true,
    validName: 'Swedish Medical Center',
  },
  {
    id: '0de58b1f-428a-4a23-b2af-64d38e06e827',
    name: 'Sysco',
    notifyDataBehind: true,
    validName: 'Sysco',
  },
  {
    id: 'ce32d2bc-5fd1-489b-befe-0a7e3b66db36',
    name: 'Tesla',
    notifyDataBehind: true,
    validName: 'Tesla',
  },
  {
    id: '2440a1ec-75fe-4edb-bd0f-2c0624e50d48',
    name: 'Thomas Duke',
    notifyDataBehind: true,
    validName: 'Thomas Duke',
  },
  {
    id: 'dc92c79c-a3fa-4ff6-ab72-f54738e7236a',
    name: "Tito's Vodka",
    notifyDataBehind: true,
    validName: "Tito's Vodka",
  },
  {
    id: '8d98098a-a62c-400d-ab93-93c6f60c7a98',
    name: 'Trane',
    notifyDataBehind: true,
    validName: 'Trane',
  },
  {
    id: 'ccb0034d-02d1-4ecf-baf2-a6d6cfab4ab6',
    name: 'Trinchero Family Estates',
    notifyDataBehind: true,
    validName: 'Trinchero Family Estates',
  },
  {
    id: '2d3cd92f-21cd-4eda-98f0-f489e091f5ca',
    name: 'Verizon',
    notifyDataBehind: true,
    validName: 'Verizon',
  },
  {
    id: '154401bd-2021-413a-8092-0d7cc11d4740',
    name: 'Watkins Lithographic Inc',
    notifyDataBehind: true,
    validName: 'Watkins Lithographic Inc',
  },
  {
    id: '95ee934d-ae1d-4020-99a0-ebe7fe69e021',
    name: 'Weaber',
    notifyDataBehind: true,
    validName: 'Weaber',
  },
  {
    id: '64d20ee7-ca1b-4eac-b171-9897c02003b3',
    name: 'Wells Enterprises',
    notifyDataBehind: true,
    validName: 'Wells Enterprises',
  },
  {
    id: 'e10a50a2-209f-40eb-b436-b760dfc8fc37',
    name: 'Wesco',
    notifyDataBehind: true,
    validName: 'Wesco',
  },
  {
    id: '7fdc42b3-dcf8-465d-9385-7192fcdb3cf5',
    name: 'Whirlpool',
    notifyDataBehind: true,
    validName: 'Whirlpool',
  },
  {
    id: 'c79786b2-f3be-4fb2-9778-e3d0a14b7d22',
    name: 'Wiese USA',
    notifyDataBehind: true,
    validName: 'Wiese USA',
  },
  {
    id: 'cc7f17db-6718-43d6-8dae-331b1d80619a',
    name: 'XL Parts',
    notifyDataBehind: true,
    validName: 'XL Parts',
  },
  {
    id: 'bac174e5-e82c-4928-94fe-a71ec98237f9',
    name: 'YMCA',
    notifyDataBehind: true,
    validName: 'YMCA',
  },
]

export default customers
