export const customerStatusMockData = {
  integrationEnabled: true,
  enabledElectricSites: 5,
  enabledWaterSites: 2,
  enabledNaturalGasSites: 4,
  energyStarCustomerId: 12345,
}

export const benchmarkingMockData = [
  {
    id: '9a9217d9-244a-4b50-9f58-f61ab0e84e43',
    created: '2023-12-18T17:23:47.721+00:00',
    createdBy: null,
    modified: '2023-12-18T17:23:47.721+00:00',
    modifiedBy: null,
    siteId: '9a9217d9-244a-4b50-9f58-f61ab0e84e44',
    siteName: 'Site 1',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-07',
    siteEui: '1.00',
    sourceEui: 2.0,
    nationalMedianSiteEui: 3.0,
    nationalMedianSourceEui: 4.0,
    nationalMedianSiteEuiDiff: 5.0,
    nationalMedianSourceEuiDiff: 6.0,
    energyStarScore: null,
    nationalMedianEnergyStarSource: 40,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.0,
  },
  {
    id: '7b5c8f2a-1a89-42d3-8f79-5c4eab69d1a1',
    created: '2023-12-19T09:45:22.512+00:00',
    createdBy: null,
    modified: '2023-12-19T09:45:22.512+00:00',
    modifiedBy: null,
    siteId: '7b5c8f2a-1a89-42d3-8f79-5c4eab69d1a2',
    siteName: 'Site 2',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-08',
    siteEui: '1.20',
    sourceEui: 2.5,
    nationalMedianSiteEui: 3.5,
    nationalMedianSourceEui: 4.5,
    nationalMedianSiteEuiDiff: 5.5,
    nationalMedianSourceEuiDiff: 6.5,
    energyStarScore: 60,
    nationalMedianEnergyStarSource: 45,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.1,
  },
  {
    id: 'e3c91d4b-4f58-43a2-9d3b-6e789fc70dab',
    created: '2023-12-19T10:15:33.721+00:00',
    createdBy: null,
    modified: '2023-12-19T10:15:33.721+00:00',
    modifiedBy: null,
    siteId: 'e3c91d4b-4f58-43a2-9d3b-6e789fc70dac',
    siteName: 'Site 3',
    meterDetails: {
      created: null,
    },
    yearMonth: '2023-09',
    siteEui: '1.40',
    sourceEui: 3.0,
    nationalMedianSiteEui: 4.0,
    nationalMedianSourceEui: 5.0,
    nationalMedianSiteEuiDiff: 6.0,
    nationalMedianSourceEuiDiff: 7.0,
    energyStarScore: 70,
    nationalMedianEnergyStarSource: 55,
    eligibleForCertification: 'Yes',
    totalGhgEmissions: 0.2,
  },
]

export const meterMappingMockData = [
  {
    siteName: 'Site B',
    rdpSiteId: 'e027596a-bab5-4b34-b6f6-29363116efb1',
    esPropertyName: 'Property B',
    esPropertyId: 111111,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: '72b11fc9-60b9-4f40-b1fe-96f48218a75b',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: 'cc283087-3608-45d2-afb3-b4f49a51a2ec',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: '2bcf95e9-bd48-443e-84a1-5fb8cf37c2b3',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
  {
    siteName: 'Site B',
    rdpSiteId: '3efcd452-4554-42a2-8d47-d738e952ff6e',
    esPropertyName: null,
    esPropertyId: null,
    esMeters: [
      {
        esMeterId: null,
        esMeterType: 'electric',
        esMeterEnabled: true,
        esMeterActive: false,
      },
      {
        esMeterId: null,
        esMeterType: 'water',
        esMeterEnabled: false,
        esMeterActive: true,
      },
      {
        esMeterId: null,
        esMeterType: 'naturalGas',
        esMeterEnabled: true,
        esMeterActive: false,
      },
    ],
  },
]

export const pendingPropertiesData = [
  {
    created: '2024-10-15T13:04:03.331Z',
    createdBy: 'akanksha.kutumbale@redaptiveinc.com',
    modified: '2024-10-17T11:14:33.246Z',
    modifiedBy: 'sushma.rakshit@redaptiveinc.com',
    id: '5c231039-baa5-47ec-bff0-727e7c48a9e9',
    accountId: 448785,
    propertyId: 19116566,
    propertyName: 'test MC 3',
    street: '123 South St',
    city: 'Edmonton',
    state: 'AB',
    postalCode: 'T5G 2S7',
    country: 'CA',
    isActive: false,
    isAccepted: true,
  },
  {
    created: '2024-10-22T09:41:21.211Z',
    createdBy: 'akanksha.kutumbale@redaptiveinc.com',
    modified: null,
    modifiedBy: null,
    id: '1431dbf5-6efc-456e-acd3-4bbc66f52403',
    accountId: 448785,
    propertyId: 19128172,
    propertyName: 'test 1360',
    street: 'test 1437-1',
    city: ' 1437-1  ',
    state: 'AL',
    postalCode: '45111 ',
    country: 'US',
    isActive: false,
    isAccepted: true,
  },
]
