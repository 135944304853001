import { createContext, useCallback } from 'react'

import { Period } from '../../../constants'
import { Grouping, Resolution, ResourceType } from '../../../ducks/types'
import { useSites } from '../../../queries/sites'
import {
  DataExplorerFilterParams,
  SetDataExplorerFiltersFn,
  UpdateFn,
} from '../types'
import {
  useDataExplorerSearchParams,
  useFiltersToSearchParams,
  useSearchParamsToFilters,
} from './searchParams'

export const defaultFilters = {
  grouping: Grouping.SITE,
  period: Period.LAST_30_DAYS,
  resolution: Resolution.DAILY,
  resourceType: ResourceType.ELECTRICITY,
  siteIds: [],
}

export const FiltersContext =
  createContext<DataExplorerFilterParams>(defaultFilters)

export const useSelectedSiteIds = () => {
  const [activeFilters] = useActiveFilters()
  const { data: sites = [] } = useSites()
  return activeFilters.siteIds.length
    ? activeFilters.siteIds
    : sites.map((s) => s.id)
}

const useActiveFilters: () => [
  DataExplorerFilterParams,
  SetDataExplorerFiltersFn,
] = () => {
  const [searchParams, setSearchParams] = useDataExplorerSearchParams()
  const searchParamsToFilters = useSearchParamsToFilters()
  const filtersToSearchParams = useFiltersToSearchParams()

  const activeFilters = searchParamsToFilters(searchParams)

  const setActiveFilters: SetDataExplorerFiltersFn = useCallback(
    (updateFn: UpdateFn) => {
      setSearchParams((prev) => {
        const query = updateFn(searchParamsToFilters(prev))
        return filtersToSearchParams(query)
      })
    },
    [filtersToSearchParams, searchParamsToFilters, setSearchParams]
  )

  return [activeFilters, setActiveFilters]
}

export default useActiveFilters
