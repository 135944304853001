import { SimpleGrid, Stack, useMantineTheme } from '@mantine/core'
import { sum } from 'lodash'
import { useContext, useMemo, useRef } from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import styled from 'styled-components'

import { QueryError } from './QueryError'
import Card from '../../components/Card'
import OperatingHoursBarChart from '../../components/CircuitsUsageDataView/OperatingHoursBarChart'
import OperatingHoursHeatMapChart from '../../components/CircuitsUsageDataView/OperatingHoursHeatmapChart'
import Currency from '../../components/Currency'
import NoPreviewIcon from '../../components/Icons/NoPreviewIcon'
import { Alert } from '../../components/mantine/Alert/Alert'
import Quantity from '../../components/mantine/Quantity'
import MetricCard from '../../components/MetricCard'
import Spinner from '../../components/Spinner'
import StyledLink from '../../components/StyledLink'
import { DEFAULT_ELECTRIC_UTILITY_RATE } from '../../constants'
import { REDAPTIVE_ONE_PAGE_TITLE } from '../../constants/strings'
import { Grouping } from '../../ducks/types'
import { useDocumentTitle } from '../../hooks/useSetDocumentTitle'
import { useResourceUsageData } from '../../queries/resourceUsage'
import { useShifts } from '../../queries/shifts'
import { useSitesById } from '../../queries/sites'
import { formatNumberToDecimals } from '../../utils'
import OperatingHoursHelper from '../../utils/operatingHoursHelper'
import { noDataMessage } from '../DataExplorer'
import useProgramStartDate, {
  useIsDateRangeValid,
} from './Filters/useProgramStartDate'
import InvalidDateRangeAlert from './InvalidDateRangeAlert'
import OperatingHoursLegend from './OperatingHoursLegend'
import { FiltersContext, useSelectedSiteIds } from './utils/filterParams'

const ZoomHelpTextStyled = styled.div`
  font-size: 12px;
  text-align: right;
`

const ChartCard = styled(Card)<{ blur?: boolean }>`
  ${({ blur }) => blur && 'filter: blur(9px);'};
`

export const TitleStyled = styled.h1`
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  margin-left: 24px;
`

const NoPreviewContainerStyled = styled.div`
  bottom: 350px;
  color: ${({ theme }) => theme.colors.fontMain};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 20px;
  font-weight: 600;
  height: 0;
  line-height: 36px;
  margin: 24px;
  position: relative;
  text-align: center;
`

const AlertContentStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin: 0 2px;
  }
`

const OperatingHoursPage = () => {
  useDocumentTitle(`Operating Hours | ${REDAPTIVE_ONE_PAGE_TITLE}`)
  const theme = useMantineTheme()
  const activeFilters = useContext(FiltersContext)
  const programStartDate = useProgramStartDate(activeFilters.resourceType)
  const isDateRangeValid = useIsDateRangeValid(activeFilters)
  const selectedSiteIds = useSelectedSiteIds()

  const sitesQuery = useSitesById()
  const usageQuery = useResourceUsageData(activeFilters, programStartDate)

  const { data: siteShifts = [] } = useShifts(selectedSiteIds[0])
  const firstSelectedSite = sitesQuery?.data?.[selectedSiteIds[0]]
  const { electricUtilityRate } = firstSelectedSite || {}

  const operatingHoursConsumptionChartRef = useRef()
  const operatingHoursBarChartRef = useRef()

  const noPreviewHeatMap = useMemo(() => {
    return activeFilters.siteIds.length !== 1
  }, [activeFilters.siteIds])

  const totalUsage = sum(
    usageQuery.data?.groups?.map((group) => group.totalUsage)
  )

  const opHoursByHourDayOfWeek =
    OperatingHoursHelper.calcOpHoursByHourDayOfWeek(siteShifts)
  const groupUsageByTime =
    usageQuery.data?.groups?.map((group) => {
      const usageByTime = Object.fromEntries(
        usageQuery.data.ts.map((time, i) => {
          return [time, group.usage?.[i]]
        })
      )
      return { ...group, usageByTime }
    }) ?? []
  const groupUsageByHourDayOfWeek =
    OperatingHoursHelper.groupUsageByHourDayOfWeek(groupUsageByTime)
  const groupOutsideOpHoursUsage =
    OperatingHoursHelper.groupOutsideOpHoursUsage({
      groupUsageByHourDayOfWeek,
      grouping: activeFilters.grouping as Grouping,
      sitesById: sitesQuery.data ?? {},
      siteShifts,
    })

  const outsideOpHoursSpend = OperatingHoursHelper.totalOutsideOpHoursSpend({
    groupOutsideOpHoursUsage,
    sitesById: sitesQuery.data ?? {},
    grouping: activeFilters.grouping,
    siteId: activeFilters.siteIds,
  })

  const totalUsageByHourDayOfWeek =
    OperatingHoursHelper.totalUsageByHourDayOfWeek(groupOutsideOpHoursUsage)
  const overallMaxDayHourUsage = OperatingHoursHelper.overallMaxHourlyUsage(
    totalUsageByHourDayOfWeek
  )

  const {
    groupWithMaxOutsideOpHoursUsage,
    totalOutsideOpHourUsage,
    isDefaultOpHoursUsed,
  } = OperatingHoursHelper.outsideOpHoursUsageTotals(groupOutsideOpHoursUsage)
  const outsideOpHoursPercentUsage =
    (totalOutsideOpHourUsage / totalUsage) * 100

  const electricUtilityRateSubHeading =
    activeFilters.siteIds.length === 1 ? (
      <>
        Based on:{' '}
        {
          <Currency
            amount={electricUtilityRate || DEFAULT_ELECTRIC_UTILITY_RATE}
            precision={2}
          />
        }{' '}
        utility rate.
      </>
    ) : (
      'Based on individual site utility rates.'
    )

  const noUsageData =
    usageQuery.isSuccess && usageQuery.data?.groups.length === 0

  return (
    <Stack pt={0} p='2xl' gap={24}>
      {(sitesQuery.isLoading || usageQuery.isLoading) && <Spinner centered />}
      {usageQuery.isError && (
        <QueryError
          status={usageQuery.error.status}
          message={usageQuery.error.message}
        />
      )}
      {!isDateRangeValid && sitesQuery.isSuccess && usageQuery.isSuccess && (
        <InvalidDateRangeAlert />
      )}
      {noUsageData && <Alert title={noDataMessage} type='orange' />}

      {isDefaultOpHoursUsed && isDateRangeValid && (
        <Alert
          title={
            <>
              Operating Hours have not been added for some selected site(s).
              Default M-F 6AM-6PM operating hours were used for these
              calculations.
            </>
          }
          type='orange'
        >
          <AlertContentStyled>
            Visit the{' '}
            <StyledLink style={{ margin: '0 3px ' }} href='/portfolio/sites'>
              Site Page
            </StyledLink>{' '}
            for each site to add Operating Hours.
          </AlertContentStyled>
        </Alert>
      )}

      {sitesQuery.isSuccess &&
        usageQuery.isSuccess &&
        !noUsageData &&
        isDateRangeValid && (
          <Stack style={{ padding: 0 }}>
            <SimpleGrid cols={{ base: 1, sm: 4 }} spacing='lg'>
              <MetricCard
                title='Metered Consumption Out. Hours'
                value={
                  <Quantity
                    quantityType='kWh'
                    value={totalOutsideOpHourUsage}
                  />
                }
              />
              <MetricCard
                title='Metered Spend Out. Hours'
                subtitle={electricUtilityRateSubHeading}
                value={<Currency amount={outsideOpHoursSpend} precision={2} />}
              />
              <MetricCard
                title='Largest Consumer Out. Hours'
                value={groupWithMaxOutsideOpHoursUsage?.groupName}
              />
              <MetricCard
                title='% Consumption Out. Hours'
                value={`${formatNumberToDecimals(
                  outsideOpHoursPercentUsage,
                  0
                )}%`}
              />
            </SimpleGrid>

            <ChartCard
              blur={noPreviewHeatMap}
              data-gainsight-id='average-consumption-by-hour'
            >
              <OperatingHoursHeatMapChart
                chartRef={operatingHoursConsumptionChartRef}
                loading={usageQuery.isLoading}
                operatingDaysAndHours={opHoursByHourDayOfWeek}
                title='Average Consumption by Hour'
                usageByDayAndHour={totalUsageByHourDayOfWeek}
                maxUsage={overallMaxDayHourUsage}
              />
              <OperatingHoursLegend />
              <ZoomHelpTextStyled>
                Click-and-drag to zoom. Shift-drag to pan.
              </ZoomHelpTextStyled>
            </ChartCard>
            {noPreviewHeatMap && (
              <NoPreviewContainerStyled>
                <NoPreviewIcon size='56' color={theme.colors.blue[5]} />
                <div>
                  This chart is only available for single-site analysis.
                </div>
                <div>Select a single site to view this chart.</div>
              </NoPreviewContainerStyled>
            )}
            <ChartCard data-gainsight-id='average-consumption-bar-chart'>
              <OperatingHoursBarChart
                chartRef={operatingHoursBarChartRef}
                title='Consumption Inside vs. Outside Operating Hours by Group'
                groupDayHourUsage={groupOutsideOpHoursUsage}
                loading={usageQuery.isLoading}
              />
              <ZoomHelpTextStyled>
                {isDefaultOpHoursUsed && (
                  <>
                    * Default operating hours used.
                    <br />
                  </>
                )}
                Click-and-drag to zoom. Shift-drag to pan.
              </ZoomHelpTextStyled>
            </ChartCard>
          </Stack>
        )}
    </Stack>
  )
}

export default OperatingHoursPage
