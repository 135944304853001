import { queryOptions, useQuery } from '@tanstack/react-query'

import { useCurrencyPreference } from './settings'
import jsonQueryFn from './utils/jsonQueryFn'

const ABSTRACT_API_URL = `https://exchange-rates.abstractapi.com/v1/live/?api_key=${
  import.meta.env.VITE_APP_ABSTRACT_API_KEY
}`

export type AbstractApiCurrencies =
  | 'EUR'
  | 'JPY'
  | 'BGN'
  | 'CZK'
  | 'DKK'
  | 'GBP'
  | 'HUF'
  | 'PLN'
  | 'RON'
  | 'SEK'
  | 'CHF'
  | 'ISK'
  | 'NOK'
  | 'HRK'
  | 'RUB'
  | 'TRY'
  | 'AUD'
  | 'BRL'
  | 'CAD'
  | 'CNY'
  | 'HKD'
  | 'IDR'
  | 'ILS'
  | 'INR'
  | 'KRW'
  | 'MXN'
  | 'MYR'
  | 'NZD'
  | 'PHP'
  | 'SGD'
  | 'THB'
  | 'ZAR'
  | 'ARS'
  | 'DZD'
  | 'MAD'
  | 'TWD'
  | 'BTC'
  | 'ETH'
  | 'BNB'
  | 'DOG'
  | 'XRP'
  | 'BCH'
  | 'LTC'
  | 'USD'

type ExchangeRatesResponse = {
  base: AbstractApiCurrencies
  last_updated: number
  exchange_rates: Record<AbstractApiCurrencies, number>
}

export const exchangeRateQuery = ({ sourceCurrency }) => {
  return queryOptions({
    queryKey: ['exchange-rates', sourceCurrency],
    queryFn: jsonQueryFn<ExchangeRatesResponse>(
      `${ABSTRACT_API_URL}&base=${sourceCurrency}`
    ),
    staleTime: 1000 * 60 * 60, // Cache for 1hr
  })
}

export const selectExchangeRate =
  (targetCurrency: AbstractApiCurrencies) => (data: ExchangeRatesResponse) =>
    data.exchange_rates[targetCurrency]

export const useExchangeRate = (
  sourceCurrency: AbstractApiCurrencies = 'USD'
) => {
  const { data: targetCurrency } = useCurrencyPreference()
  return useQuery({
    ...exchangeRateQuery({ sourceCurrency }),
    select: selectExchangeRate(targetCurrency),
  })
}
