import { QuantityTypes, UnitParams } from './types'
import { useDisplayUnitsPreference } from '../../../queries/settings'

export const Unit = ({ quantityType }: UnitParams) => {
  const unit = useUnit()

  return unit(quantityType)
}

export const useUnit = () => {
  const { data: displayUnits } = useDisplayUnitsPreference()

  return (quantityType: QuantityTypes) => {
    if (displayUnits === 'imperial') {
      return imperialUnitDisplay(quantityType)
    } else {
      return metricUnitDisplay(quantityType)
    }
  }
}

const metricUnitDisplay = (quantityType: QuantityTypes) => {
  switch (quantityType) {
    case 'MT':
      return 'MT'
    case 'V':
      return 'V'
    case 'kW':
      return 'kW'
    case 'kWh':
    case 'electric':
      return 'kWh'
    case 'MWh':
      return 'MWh'
    case 'sqft':
    case 'area':
      return 'm²'
    case 'CCF':
    case 'gas':
      return 'm³'
    case 'gal':
    case 'gallons':
    case 'water':
      return 'L'
    default:
      return quantityType
  }
}

const imperialUnitDisplay = (quantityType: QuantityTypes) => {
  switch (quantityType) {
    case 'V':
      return 'V'
    case 'MT':
      return 'MT'
    case 'kW':
      return 'kW'
    case 'kWh':
    case 'electric':
      return 'kWh'
    case 'MWh':
      return 'MWh'
    case 'sqft':
    case 'area':
      return 'ft²'
    case 'CCF':
    case 'gas':
      return 'CCF'
    case 'gal':
    case 'gallons':
    case 'water':
      return 'gal'
    default:
      return quantityType
  }
}
