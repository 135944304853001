import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { actions as modalActions } from '../../../ducks/modal'
import { useEnableEnergyStarPopupStore } from '../../../globalState/energyStar'
import {
  useEnergyStarSitesCountQuery,
  useUpdateEnergyStarCustomerMutation,
} from '../../../queries/energyStar'
import { colors } from '../../../themes/redaptiveOne'
import Button from '../../mantine/Button'
import ModalCustom from '../../mantine/Modal'
import showCustomNotification from '../../mantine/Notifications'
import { getEnableCustomerPayload, MainContainer } from '../OnboardingModal'

const HeadingText = styled.div`
  color: ${colors.deepBlue100};
  font-size: 22px;
  font-weight: 700;
  margin: 20px 0px;
`

const SubHeadingText = styled.p`
  margin: 10px;
`

const ButtonContainer = styled.div`
  margin: 30px 0px;
  display: flex;
  gap: 20px;
`

const PropertiesConfirmationModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const esCustomerUpdateMutation = useUpdateEnergyStarCustomerMutation()
  const esSitesCountQuery = useEnergyStarSitesCountQuery()

  const { dismissEnableEnergyStarPopup } = useEnableEnergyStarPopupStore()

  const isLoading =
    esCustomerUpdateMutation.isPending || esSitesCountQuery.isFetching

  const handleCustomerUpdate = async (siteMappingEnabled: boolean) => {
    if (!esSitesCountQuery.data?.energyStarCustomerId) return
    try {
      await esCustomerUpdateMutation.mutateAsync(
        getEnableCustomerPayload({
          energyStarId:
            esSitesCountQuery.data?.energyStarCustomerId?.toString(),
          siteMappingEnabled,
        })
      )
    } catch {
      showCustomNotification({
        title: 'Error',
        message:
          'Something went wrong while enabling energy star. Please try again',
        color: 'red',
      })
    }
  }

  const onCloseClick = async () => {
    if (!esSitesCountQuery.data?.energyStarCustomerId) return
    dispatch(modalActions.hideModal())
    await handleCustomerUpdate(true)
    dismissEnableEnergyStarPopup()
  }

  const onEnableNowClick = async () => {
    if (!esSitesCountQuery.data?.energyStarCustomerId) return
    await handleCustomerUpdate(false)

    navigate('portfolio/sustainability-reporting/connect-properties')
    dispatch(modalActions.hideModal())
    dismissEnableEnergyStarPopup()
  }

  return (
    <ModalCustom
      opened
      onClose={onCloseClick}
      withCloseButton={false}
      size='md'
      closeOnClickOutside={false}
    >
      <MainContainer>
        <HeadingText data-testid='heading-text'>
          Do you want to enable properties now?
        </HeadingText>
        <SubHeadingText data-testid='sub-heading-text'>
          A Redaptive representative can perform the next steps on your behalf.
          If you still want to proceed with enabling the individual sites, click
          &apos;Enable now&apos;.
        </SubHeadingText>

        <ButtonContainer>
          <Button
            size='sm'
            variant='outline'
            onClick={onCloseClick}
            disabled={isLoading}
            gainsightTagId='enable-properties-cancel-button'
          >
            Cancel
          </Button>
          <Button
            size='sm'
            disabled={isLoading}
            onClick={() => onEnableNowClick()}
            gainsightTagId='enable-properties-enable-now-button'
          >
            Enable Now
          </Button>
        </ButtonContainer>
      </MainContainer>
    </ModalCustom>
  )
}

export default PropertiesConfirmationModal
