/* eslint-disable @typescript-eslint/no-unused-vars */
import { combineReducers } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

import { handleSagaError } from '../../api/utils'
import { UsageDataResponse } from '../../queries/resourceUsage'
import { TSState } from '../../reducers/rootReducer'
import { filterDemoCircuitData } from '../../utils/demo'
import { ItemUsage, Resolution, ResourceType, TSMetaState } from '../types'
import {
  TSResourceItemUsage,
  TSResourceMetaDataResponse,
  TSResourceUsageDataRequest,
} from './electric'
import { API, enhanceGroupUsageData } from './utils'

interface TSGasUsageMetaState extends TSMetaState {
  noGasDataAvailable: boolean
}

export interface TSGasUsageEntityState {
  gas: Array<TSResourceItemUsage>
  gasResponseMetaData: TSResourceMetaDataResponse
  meta: TSGasUsageMetaState
}

export const types = {
  FETCH_GAS_USAGE_DATA: 'FETCH_GAS_USAGE_DATA',
  FETCH_GAS_USAGE_DATA_SUCCESS: 'FETCH_GAS_USAGE_DATA_SUCCESS',
  FETCH_GAS_USAGE_META_DATA_RESPONSE_SUCCESS:
    'FETCH_GAS_USAGE_META_DATA_RESPONSE_SUCCESS',
  FETCH_GAS_USAGE_DATA_ERROR: 'FETCH_GAS_USAGE_DATA_ERROR',
  RESET_GAS_USAGE_DATA: 'RESET_GAS_USAGE_DATA',
}

export const actions = {
  fetchGasUsageData: (request: TSResourceUsageDataRequest) => ({
    type: types.FETCH_GAS_USAGE_DATA,
    ...request,
  }),
  resetGasUsageData: () => ({
    type: types.RESET_GAS_USAGE_DATA,
  }),
}

export const initialState: TSGasUsageEntityState = {
  gas: [],
  gasResponseMetaData: {
    ts: [],
    grouping: '',
    from: '',
    to: '',
    resolution: Resolution.HOURLY,
  },
  meta: {
    noGasDataAvailable: false,
    loading: true,
    error: '',
  },
}

function entityItems(action, state) {
  const newItems: Array<ItemUsage> = Object.values(action.payload)
  return state
    .filter(
      (item) => !newItems.find((newItem) => newItem.groupId === item.groupId)
    )
    .concat(newItems)
}

function gas(state = initialState.gas, action) {
  switch (action.type) {
    case types.FETCH_GAS_USAGE_DATA:
    case types.RESET_GAS_USAGE_DATA:
      return initialState.gas
    case types.FETCH_GAS_USAGE_DATA_SUCCESS:
      return entityItems(action, state)
    default:
      return state
  }
}

function gasResponseMetaData(state = initialState.gasResponseMetaData, action) {
  switch (action.type) {
    case types.FETCH_GAS_USAGE_DATA:
    case types.RESET_GAS_USAGE_DATA:
      return initialState.gasResponseMetaData
    case types.FETCH_GAS_USAGE_META_DATA_RESPONSE_SUCCESS:
      return action.payload
    default:
      return state
  }
}

function meta(state = initialState.meta, action): TSGasUsageMetaState {
  switch (action.type) {
    case types.FETCH_GAS_USAGE_DATA:
      return {
        ...state,
        loading: true,
        error: '',
        noGasDataAvailable: false,
      }
    case types.FETCH_GAS_USAGE_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        noGasDataAvailable: false,
      }
    case types.FETCH_GAS_USAGE_DATA_SUCCESS:
      return {
        ...state,
        error: '',
        loading: false,
        noGasDataAvailable: !action.payload[0],
      }
    default:
      return state
  }
}

export default combineReducers({
  gas,
  gasResponseMetaData,
  meta,
})

export const selectNaturalGasUsageEntity = (
  state: TSState
): TSGasUsageEntityState => state.entities.naturalGasUsage

// sagas
function* fetchGasUsageSaga({
  type,
  ...request
}: TSResourceUsageDataRequest & { type: string }): Generator<any, void, any> {
  try {
    const gasUsageData: UsageDataResponse = yield call(API.fetchUsageData, {
      ...request,
      resourceType: ResourceType.NATURAL_GAS,
    })

    // pass through demo filter, will apply any relevant filters if this is a demo user
    // do the filter before enhancing to make sure any masked fields used in the enhance are covered
    filterDemoCircuitData(gasUsageData)

    const { data, ...metaData } = gasUsageData

    yield put({
      type: types.FETCH_GAS_USAGE_DATA_SUCCESS,
      payload: data.map((group) => {
        return enhanceGroupUsageData(group, ResourceType.NATURAL_GAS)
      }),
    })
    yield put({
      type: types.FETCH_GAS_USAGE_META_DATA_RESPONSE_SUCCESS,
      payload: metaData,
    })
  } catch (e) {
    yield handleSagaError(types.FETCH_GAS_USAGE_DATA_ERROR, e as Error)
  }
}

export const sagas = [takeLatest(types.FETCH_GAS_USAGE_DATA, fetchGasUsageSaga)]
