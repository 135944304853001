import { SVGProps } from 'react'

interface TSProps {
  color?: string
  size?: string
}
type Props = SVGProps<SVGSVGElement> & TSProps

const DownloadIcon = ({ color = '#162447', size = '20', ...props }: Props) => (
  <svg
    height={size}
    viewBox='0 0 16 16'
    width={size}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.9999 9.3335C13.8231 9.3335 13.6535 9.40373 13.5285 9.52876C13.4035 9.65378 13.3333 9.82335 13.3333 10.0002V12.6668C13.3333 12.8436 13.263 13.0132 13.138 13.1382C13.013 13.2633 12.8434 13.3335 12.6666 13.3335H3.33325C3.15644 13.3335 2.98687 13.2633 2.86185 13.1382C2.73682 13.0132 2.66659 12.8436 2.66659 12.6668V10.0002C2.66659 9.82335 2.59635 9.65378 2.47132 9.52876C2.3463 9.40373 2.17673 9.3335 1.99992 9.3335C1.82311 9.3335 1.65354 9.40373 1.52851 9.52876C1.40349 9.65378 1.33325 9.82335 1.33325 10.0002V12.6668C1.33325 13.1973 1.54397 13.706 1.91904 14.081C2.29411 14.4561 2.80282 14.6668 3.33325 14.6668H12.6666C13.197 14.6668 13.7057 14.4561 14.0808 14.081C14.4559 13.706 14.6666 13.1973 14.6666 12.6668V10.0002C14.6666 9.82335 14.5963 9.65378 14.4713 9.52876C14.3463 9.40373 14.1767 9.3335 13.9999 9.3335ZM7.52659 10.4735C7.58999 10.5342 7.66475 10.5818 7.74658 10.6135C7.82639 10.6488 7.91267 10.667 7.99992 10.667C8.08717 10.667 8.17345 10.6488 8.25325 10.6135C8.33509 10.5818 8.40985 10.5342 8.47325 10.4735L11.1399 7.80683C11.2655 7.68129 11.336 7.51103 11.336 7.3335C11.336 7.15596 11.2655 6.9857 11.1399 6.86016C11.0144 6.73463 10.8441 6.6641 10.6666 6.6641C10.489 6.6641 10.3188 6.73463 10.1933 6.86016L8.66658 8.3935V2.00016C8.66658 1.82335 8.59635 1.65378 8.47132 1.52876C8.3463 1.40373 8.17673 1.3335 7.99992 1.3335C7.82311 1.3335 7.65354 1.40373 7.52851 1.52876C7.40349 1.65378 7.33325 1.82335 7.33325 2.00016V8.3935L5.80658 6.86016C5.74443 6.798 5.67063 6.7487 5.58942 6.71506C5.5082 6.68142 5.42116 6.6641 5.33325 6.6641C5.24535 6.6641 5.1583 6.68142 5.07709 6.71506C4.99587 6.7487 4.92208 6.798 4.85992 6.86016C4.79776 6.92232 4.74845 6.99611 4.71481 7.07733C4.68117 7.15854 4.66386 7.24559 4.66386 7.3335C4.66386 7.4214 4.68117 7.50845 4.71481 7.58966C4.74845 7.67088 4.79776 7.74467 4.85992 7.80683L7.52659 10.4735Z'
      fill={color}
    />
  </svg>
)

export default DownloadIcon
