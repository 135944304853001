import { SVGProps } from 'react'

const BookmarkFullIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill='none'
      {...props}
    >
      <path
        d='M8.74609 11.3047C8.67448 11.3047 8.60413 11.2858 8.54211 11.25L5.02734 9.23449L1.51258 11.25C1.45024 11.2857 1.3796 11.3045 1.30774 11.3043C1.23588 11.3042 1.16532 11.2851 1.10313 11.2491C1.04093 11.2132 0.989291 11.1614 0.953367 11.0992C0.917444 11.037 0.898502 10.9664 0.898438 10.8945V0.722656C0.898438 0.613876 0.94165 0.509551 1.01857 0.432632C1.09549 0.355713 1.19981 0.3125 1.30859 0.3125H8.74609C8.85487 0.3125 8.9592 0.355713 9.03612 0.432632C9.11304 0.509551 9.15625 0.613876 9.15625 0.722656V10.8945C9.15625 11.0033 9.11304 11.1076 9.03612 11.1846C8.9592 11.2615 8.85487 11.3047 8.74609 11.3047Z'
        fill='currentColor'
      />
      <path
        d='M8.33594 10.1863L5.23133 8.40625C5.16931 8.37043 5.09896 8.35157 5.02734 8.35156C4.95573 8.35157 4.88538 8.37043 4.82336 8.40625L1.71875 10.1863V1.13281M8.74609 11.3047C8.67448 11.3047 8.60413 11.2858 8.54211 11.25L5.02734 9.23449L1.51258 11.25C1.45024 11.2857 1.3796 11.3045 1.30774 11.3043C1.23588 11.3042 1.16532 11.2851 1.10313 11.2491C1.04093 11.2131 0.989291 11.1614 0.953367 11.0992C0.917444 11.037 0.898502 10.9664 0.898438 10.8945V0.722656C0.898438 0.613876 0.94165 0.509551 1.01857 0.432632C1.09549 0.355713 1.19981 0.3125 1.30859 0.3125H8.74609C8.85487 0.3125 8.9592 0.355713 9.03612 0.432632C9.11304 0.509551 9.15625 0.613876 9.15625 0.722656V10.8945C9.15625 11.0033 9.11304 11.1076 9.03612 11.1846C8.9592 11.2615 8.85487 11.3047 8.74609 11.3047Z'
        stroke='currentColor'
        strokeWidth='0.4'
      />
    </svg>
  )
}

export default BookmarkFullIcon
