interface TSProps {
  color?: string
  size?: string
}

const ArrowBackIcon = ({ color = '#162447', size = '16' }: TSProps) => (
  <svg fill='none' height={size} viewBox='0 0 16 16' width={size}>
    <path
      d='M15.8332 10H4.1665 M9.99984 15.8337L4.1665 10.0003L9.99984 4.16699'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default ArrowBackIcon
