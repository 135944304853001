import { SVGProps } from 'react'

type IconProps = SVGProps<SVGSVGElement> & {
  size: number
}

const FlagAlert = ({ size, ...rest }: IconProps) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='currentColor'
      {...rest}
    >
      <path
        id='Vector'
        d='M12.9766 7.45839L15.2939 4.85143C15.3923 4.74072 15.4593 4.59966 15.4865 4.44611C15.5136 4.29255 15.4997 4.13338 15.4464 3.98874C15.3932 3.84409 15.303 3.72045 15.1873 3.63346C15.0716 3.54648 14.9355 3.50004 14.7964 3.50003H5.70369C5.61126 3.49998 5.51974 3.52041 5.43434 3.56018C5.34894 3.59994 5.27135 3.65825 5.20599 3.73177C5.14064 3.8053 5.08881 3.89259 5.05346 3.98866C5.01812 4.08473 4.99995 4.1877 5 4.29167V16.4306C5 16.6406 5.07414 16.8419 5.2061 16.9904C5.33807 17.1388 5.51706 17.2223 5.70369 17.2223C5.89032 17.2223 6.0693 17.1388 6.20127 16.9904C6.33323 16.8419 6.40737 16.6406 6.40737 16.4306V11.4167H14.7964C14.9355 11.4167 15.0716 11.3702 15.1873 11.2832C15.303 11.1963 15.3932 11.0726 15.4464 10.928C15.4997 10.7833 15.5136 10.6242 15.4865 10.4706C15.4593 10.317 15.3923 10.176 15.2939 10.0653L12.9766 7.45839ZM6.40737 9.83339V5.08337H13.0976L11.484 6.89864C11.4186 6.97213 11.3668 7.05939 11.3314 7.15543C11.296 7.25148 11.2778 7.35442 11.2778 7.45838C11.2778 7.56234 11.296 7.66528 11.3314 7.76132C11.3668 7.85737 11.4186 7.94463 11.484 8.01812L13.0976 9.83339H6.40737Z'
      />
    </svg>
  )
}
export default FlagAlert
