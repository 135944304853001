import styled from 'styled-components'

import { SitesListSelectorStyled } from '../../components/MultiSiteSelector'
import { zIndices } from '../../utils'
import { DatePickerStyles, DatePickerTextWrapperStyled } from '../DatePicker'
import { SpinnerWrapperCenteredStyled } from '../Spinner'

export const DefaultModalStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  backdrop-filter: blur(0.5px);
  background-color: rgba(215, 215, 215, 0.6);
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.02em;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${zIndices.siteConfigModal};

  * {
    box-sizing: border-box;
  }
`

export const ModalHeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  gap: 20px;
`

export const ModalWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CloseModalButtonStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  height: 40px;
  padding: 10px;
  width: 40px;

  span {
    display: block;
    margin: 3px 0;
    height: 2px;
    width: 20px;
    background: #fff;
    border-radius: 2px;
  }

  span {
    &:nth-child(1) {
      transform: rotate(135deg) translate(4px, -3px);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: rotate(-135deg) translate(4px, 4px);
    }
  }
`

export const ModalContentStyled = styled.div`
  height: 100%;
  overflow: auto;
  padding: 24px;

  ${SpinnerWrapperCenteredStyled} {
    height: auto;
    padding: 40px;
  }

  ${DatePickerStyles} {
    width: 100%;
  }

  ${DatePickerTextWrapperStyled} {
    justify-content: space-between;
    margin: 10px;
  }

  ${SitesListSelectorStyled} {
    .Select__menu-outer {
      width: 100%;
    }
  }
`

export const ModalHeaderInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`

export const ModalBodyStyled = styled.div<{
  backgroundColor?: string
  width?: string
  background?: string
}>`
  background-color: ${({ backgroundColor }) => backgroundColor || '#EDEDED'};
  background: ${({ background }) => background || '#EDEDED'};
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: ${({ width }) => width || '800px'};
`

export const ModalTitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
`

export const ModalTitleWithSubTitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-bottom: 5px;
`

export const ModalSubTitleStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontMain};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
`

export const ModalFooterStyled = styled.div`
  border-top: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  min-height: 82px;
  margin-top: auto;
  padding: 20px;

  > button {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 16px;
    font-weight: 400;
    margin: 10px;
  }
`
