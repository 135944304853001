import moment from 'moment'
import { Dispatch, SetStateAction, useMemo } from 'react'

import DateRange from '../../../components/mantine/DateRangePicker'
import {
  DATE_YEAR_MONTH_DAY_FORMAT,
  dateRangeOptions,
  LAST_30_DAYS,
  LAST_7_DAYS,
  LAST_MONTH,
  Period,
  THIS_MONTH,
} from '../../../constants'
import { useSitesById } from '../../../queries/sites'
import { DataExplorerFilterParams } from '../types'
import { getProgramStartDate } from '../utils'

type DateRangeProps = {
  pendingFilters: DataExplorerFilterParams
  setPendingFilters: Dispatch<SetStateAction<DataExplorerFilterParams>>
}

const DateRangePicker = ({
  pendingFilters,
  setPendingFilters,
}: DateRangeProps) => {
  const { data: sitesById = {} } = useSitesById()
  const selectedSites = pendingFilters.siteIds.map(
    (siteId) => sitesById[siteId]
  )

  const relativeDatesOptions = useMemo(() => {
    const dataExplorerDateRanges = [
      LAST_7_DAYS,
      LAST_30_DAYS,
      LAST_MONTH,
      THIS_MONTH,
    ]
    return dateRangeOptions.filter((option) =>
      dataExplorerDateRanges.includes(option.value)
    )
  }, [])

  // Site Resource Start Date
  const programStartDate = getProgramStartDate(
    selectedSites,
    pendingFilters.resourceType
  )

  // From and To Dates
  const fromToDates =
    pendingFilters.period === Period.CUSTOM
      ? {
          fromDate: moment(pendingFilters.fromDate).toDate(),
          toDate: moment(pendingFilters.toDate).toDate(),
        }
      : {}
  const toDateStr = (date: Date) =>
    date ? moment(date).format(DATE_YEAR_MONTH_DAY_FORMAT) : undefined
  const handleUpdateDateRange = ({
    startDate,
    endDate,
    selectedOptionDate: period = Period.CUSTOM,
  }) => {
    const newParams = { period }
    if (newParams.period === Period.CUSTOM) {
      newParams['fromDate'] = toDateStr(startDate)
      newParams['toDate'] = toDateStr(endDate)
    } else {
      newParams['fromDate'] = null
      newParams['toDate'] = null
    }
    setPendingFilters((prev) => ({ ...prev, ...newParams }))
  }

  return (
    <DateRange
      endDate={fromToDates.toDate}
      gainsightTagId='sites-query-filter-selector-date-range'
      label='Date Range'
      maxDays={pendingFilters.resolution === '1day' ? 365 : 30}
      options={relativeDatesOptions}
      period={pendingFilters.period}
      programStartDate={programStartDate}
      startDate={fromToDates.fromDate}
      updateDateRange={handleUpdateDateRange}
      styles={{ input: { paddingLeft: 8 } }}
      rightSectionWidth={30}
    />
  )
}

export default DateRangePicker
