import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { selectSitesEntity } from '../ducks/sites'
import { breakpoints } from '../themes'
import ListSelector, { allOption, SelectStyles, TSOption } from './ListSelector'
import Spinner from './Spinner'
import { getSitesOptionsItems } from '../utils/formUtils'
import { gainsightPXGlobalContext, TSActionTypes } from '../utils/gainsight'

export const SitesListSelectorStyled = styled(ListSelector)`
  .Select__menu-outer {
    border: 0;
    width: 400px;
    z-index: 1001;

    @media (max-width: ${breakpoints.lg}) {
      right: 0;
      width: 100%;
    }
  }

  ${SelectStyles} {
    .Select__option {
      > div > div {
        p {
          height: 40px;
        }
      }
    }
  }
`

const MultiSiteSelector = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const serializeFormQuery = useMemo(() => {
    return Object.fromEntries(searchParams)
  }, [searchParams])

  const {
    byId: sitesById,
    items: sites,
    meta: { loading: sitesLoading },
  } = useSelector(selectSitesEntity)
  const [selectedSiteIdsInternal, setSelectedSiteIdsInternal]: [
    string[],
    (Array) => void,
  ] = useState([''])
  const [menuOpen, setMenuOpen]: [boolean, (boolean) => void] = useState(false)

  const selectedSiteIds = useMemo(
    () => serializeFormQuery.site?.split(',').filter((id) => id) ?? [],
    [serializeFormQuery]
  )

  useEffect(() => {
    setSelectedSiteIdsInternal(selectedSiteIds)
    if (!!sites.length && !sitesLoading) {
      gainsightPXGlobalContext(TSActionTypes.set, {
        siteId: selectedSiteIds[0]
          ? selectedSiteIds
          : sites.map((site) => site.id),
        siteName: selectedSiteIds[0]
          ? selectedSiteIds
              .map((id) => (sitesById[id] ? sitesById[id].display : ''))
              .filter((siteName) => siteName)
          : sites.map((site) => site.display),
      })
    }
  }, [sitesById, sites, sitesLoading, selectedSiteIds])

  const sortedSitesItems = useMemo(() => getSitesOptionsItems(sites), [sites])

  const selectedSitesItems = useMemo(
    () =>
      sortedSitesItems.filter((item) =>
        selectedSiteIdsInternal.includes(item.id)
      ),
    [sortedSitesItems, selectedSiteIdsInternal]
  )

  const unselectedSitesItems = useMemo(
    () =>
      sortedSitesItems.filter(
        (item) => !selectedSiteIdsInternal.includes(item.id)
      ),
    [sortedSitesItems, selectedSiteIdsInternal]
  )

  const sitesItems = useMemo(
    () => [...selectedSitesItems, ...unselectedSitesItems],
    [selectedSitesItems, unselectedSitesItems]
  )

  const handleSitesValueUpdate = useCallback(
    (newValues: Array<TSOption>, actionMeta: any) => {
      const {
        action,
        option: { value },
      } = actionMeta
      let selectedSitesId = newValues.map((item) => item.value)
      if (value === allOption.value) {
        if (action === 'deselect-option') {
          selectedSitesId = []
        } else {
          selectedSitesId = sitesItems.map((item) => item.id)
        }
      }
      setSelectedSiteIdsInternal(
        selectedSitesId.filter((id) => id !== allOption.value)
      )
    },
    [sitesItems]
  )

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true)
  }, [])

  const handleMenuClose = useCallback(() => {
    // update site(s) id on query params
    const updatedSite = selectedSiteIdsInternal
      .filter((siteId) => siteId !== allOption.value)
      .join(',')

    setSearchParams({
      ...Object.fromEntries(searchParams),
      site: updatedSite,
    })
    setMenuOpen(false)
  }, [selectedSiteIdsInternal, setSearchParams, searchParams])

  return sitesItems.length ? (
    <SitesListSelectorStyled
      closeMenuOnSelect={false}
      gainsightTagId='sites-query-filter-selector'
      isMulti
      items={sitesItems}
      key='multi-site-selection'
      menuIsOpen={menuOpen}
      notSetLabelText='Selected All'
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      selectedItems={selectedSitesItems}
      unsettable={false}
      updateValueMulti={handleSitesValueUpdate}
    />
  ) : (
    <Spinner size='sm' centered />
  )
}

export default MultiSiteSelector
