import axios from 'axios'
import { combineReducers } from 'redux'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { apiBaseUrl, defaultHeaders } from '../../api'
import { handleAxiosError, handleSagaError } from '../../api/utils'
import { AbstractApiCurrencies } from '../../queries/exchangeRates'
import { filterDemoInvoiceDetails, filterDemoInvoices } from '../../utils/demo'

export type TSInvoiceSummaryResponse = {
  description?: string
  id: string
  invoiceAmount?: number
  invoiceNumber?: string
  invoiceDate?: string
  opportunityType?: string
  projectType?: string
  remainingCommitment?: number
  savedEnergy?: number
  status?: string
  currency?: string
  detail?: TSInvoiceResponse
}

export type TSInvoiceSummary = {
  siteId: string
  siteName: string
} & TSInvoiceSummaryResponse

export type TSInvoiceBillItems = {
  amount: number
  description: string
  quantity: number
  rate: number
  units: string
}[]

export type TSInvoiceResponse = {
  billItems: TSInvoiceBillItems
  contractSummary: {
    priorCommitment?: number
    remainingCommitment?: number
    savedEnergy?: number
  }
  customerName?: string
  invoiceDate?: string
  invoiceDueDate?: string
  opportunityId?: string
  opportunityName?: string
  savingsSummary: {
    netCostSavings?: number
    savedEnergy?: number
    utilityBillSavings?: number
    utilityRate?: number
  }
  salesTax?: number
  siteCode?: string
  status?: string
  currency?: AbstractApiCurrencies
}

export type TSInvoiceDetail = {
  id: string
} & TSInvoiceResponse

export type TSInvoice = {
  detail?: TSInvoiceDetail
  id: string
  summary: TSInvoiceSummary
}

export type TSFetchInvoicesBySiteAction = {
  siteId: string
}

export type TSFetchInvoicesByCustomerAction = {
  customerId: string
}

export type TSFetchInvoiceAction = {
  invoiceId: string
}

export type TSInvoiceSummariesBySiteResponse = {
  siteId: string
  siteName: string
  invoices: TSInvoiceSummaryResponse[]
}[]

type TSInvoicesEntityMeta = {
  error: string
  errorDetail: string
  loading: boolean
  loadingDetail: boolean
  pdfDownloading: boolean
  errorPdfDownloading: string
}

export interface TSInvoicesEntityState {
  byId: {
    [id: string]: TSInvoice
  }
  allIds: string[]
  meta: TSInvoicesEntityMeta
}

export type TSStateInvoices = {
  entities: {
    invoices: TSInvoicesEntityState
  }
}

// Action Types
export const types = {
  FETCH_INVOICE: 'FETCH_INVOICE',
  FETCH_INVOICE_ERROR: 'FETCH_INVOICE_ERROR',
  FETCH_INVOICE_SUCCESS: 'FETCH_INVOICE_SUCCESS',
  FETCH_INVOICES_BY_CUSTOMER: 'FETCH_INVOICES_BY_CUSTOMER',
  FETCH_INVOICES_BY_CUSTOMER_ERROR: 'FETCH_INVOICES_BY_CUSTOMER_ERROR',
  FETCH_INVOICES_BY_CUSTOMER_SUCCESS: 'FETCH_INVOICES_BY_CUSTOMER_SUCCESS',
  FETCH_INVOICES_BY_SITE: 'FETCH_INVOICES_BY_SITE',
  FETCH_INVOICES_BY_SITE_ERROR: 'FETCH_INVOICES_BY_SITE_ERROR',
  FETCH_INVOICES_BY_SITE_SUCCESS: 'FETCH_INVOICES_BY_SITE_SUCCESS',
  DOWNLOAD_INVOICE_PDF: 'DOWNLOAD_INVOICE_PDF',
  DOWNLOAD_INVOICE_SUCCESS: 'DOWNLOAD_INVOICE_PDF_SUCCESS',
  DOWNLOAD_INVOICE_ERROR: 'DOWNLOAD_INVOICE_PDF_ERROR',
}

export const selectInvoicesMeta = (
  state: TSStateInvoices
): TSInvoicesEntityMeta => state.entities.invoices.meta

export const selectInvoicesById = (
  state: TSStateInvoices
): { [id: string]: TSInvoice } => state.entities.invoices.byId

export const selectInvoices = (state: TSStateInvoices): TSInvoice[] =>
  state.entities.invoices.allIds.map<TSInvoice>(
    (id) => state.entities.invoices.byId[id]
  )

export const selectInvoice = (
  state: TSStateInvoices,
  invoiceId: string
): TSInvoice => state.entities.invoices.byId[invoiceId]

// Action Creators
export const actions: any = {
  fetchInvoice: (request: TSFetchInvoiceAction) => ({
    type: types.FETCH_INVOICE,
    request,
  }),
  fetchInvoicesByCustomer: (request: TSFetchInvoicesByCustomerAction) => ({
    type: types.FETCH_INVOICES_BY_CUSTOMER,
    request,
  }),
  fetchInvoicesBySite: (request: TSFetchInvoicesBySiteAction) => ({
    type: types.FETCH_INVOICES_BY_SITE,
    request,
  }),
  downloadInvoiceById: (request: TSFetchInvoiceAction) => ({
    type: types.DOWNLOAD_INVOICE_PDF,
    request,
  }),
}

export const initialState: TSInvoicesEntityState = {
  byId: {},
  allIds: [],
  meta: {
    error: '',
    errorDetail: '',
    loading: false,
    loadingDetail: false,
    pdfDownloading: false,
    errorPdfDownloading: '',
  },
}

function entityById(action, state) {
  return {
    ...state,
    ...action.invoices.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.id]: cur,
      }),
      {}
    ),
  }
}

function entityByIdInvoiceDetail(action: { invoice: TSInvoiceDetail }, state) {
  const { invoice } = action
  return {
    ...state,
    [invoice.id]: {
      ...state[invoice.id],
      detail: invoice,
      id: invoice.id,
    },
  }
}

function entityAllIds(action, state) {
  const newIds = action.invoices.map((invoice) => invoice.id)
  return [...new Set(state.concat(newIds))] as string[]
}

function entityAllIdsInvoiceDetail(action, state) {
  const newIds = [action.invoice.id]
  return [...new Set(state.concat(newIds))] as string[]
}

function byId(state: { [id: string]: TSInvoice } = {}, action: any) {
  switch (action.type) {
    case types.FETCH_INVOICE:
      return state
    case types.FETCH_INVOICES_BY_CUSTOMER:
    case types.FETCH_INVOICES_BY_SITE:
      return {}
    case types.FETCH_INVOICE_SUCCESS:
      return entityByIdInvoiceDetail(action, state)
    case types.FETCH_INVOICES_BY_CUSTOMER_SUCCESS:
    case types.FETCH_INVOICES_BY_SITE_SUCCESS:
      return entityById(action, state)
    default:
      return state
  }
}

function allIds(state: string[] = [], action: any) {
  switch (action.type) {
    case types.FETCH_INVOICE:
      return state
    case types.FETCH_INVOICES_BY_CUSTOMER:
    case types.FETCH_INVOICES_BY_SITE:
      return []
    case types.FETCH_INVOICE_SUCCESS:
      return entityAllIdsInvoiceDetail(action, state)
    case types.FETCH_INVOICES_BY_CUSTOMER_SUCCESS:
    case types.FETCH_INVOICES_BY_SITE_SUCCESS:
      return entityAllIds(action, state)
    default:
      return state
  }
}

function meta(state = initialState.meta, action) {
  switch (action.type) {
    case types.FETCH_INVOICE:
      return {
        ...state,
        loadingDetail: true,
        errorDetail: '',
      }
    case types.FETCH_INVOICES_BY_CUSTOMER:
    case types.FETCH_INVOICES_BY_SITE:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case types.DOWNLOAD_INVOICE_PDF:
      return {
        ...state,
        pdfDownloading: true,
        errorPdfDownloading: '',
      }
    case types.FETCH_INVOICE_ERROR:
      return {
        ...state,
        loadingDetail: false,
        errorDetail: action.error,
      }
    case types.FETCH_INVOICES_BY_CUSTOMER_ERROR:
    case types.FETCH_INVOICES_BY_SITE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case types.DOWNLOAD_INVOICE_ERROR:
      return {
        ...state,
        errorPdfDownloading: action.error,
        pdfDownloading: false,
      }
    case types.FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        loadingDetail: false,
      }
    case types.FETCH_INVOICES_BY_CUSTOMER_SUCCESS:
    case types.FETCH_INVOICES_BY_SITE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case types.DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        pdfDownloading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  meta,
})

// API
export const API = {
  fetchInvoice: ({ invoiceId }: TSFetchInvoiceAction) => {
    const url = `${apiBaseUrl()}/invoices/${invoiceId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: any) => data)
      .catch(handleAxiosError)
  },
  fetchInvoicesByCustomer: ({
    customerId,
  }: TSFetchInvoicesByCustomerAction) => {
    const url = `${apiBaseUrl()}/invoices/customer/${customerId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: any) => data)
      .catch(handleAxiosError)
  },
  fetchInvoicesBySite: ({ siteId }: TSFetchInvoicesBySiteAction) => {
    const url = `${apiBaseUrl()}/invoices/site/${siteId}`
    return axios
      .get(url, { headers: defaultHeaders() })
      .then(({ data }: any) => data)
      .catch(handleAxiosError)
  },
  downloadInvoiceById: async ({ invoiceId }: TSFetchInvoiceAction) => {
    const url = `${apiBaseUrl()}/invoices/${invoiceId}/invoice-pdf`
    return axios
      .get(url, {
        headers: { ...defaultHeaders(), 'Content-type': 'application/pdf' },
        responseType: 'blob',
      })
      .then(({ data }: { data: Blob; headers }) => {
        const url = URL.createObjectURL(data)
        window.open(url, '_blank')
      })
      .catch(handleAxiosError)
  },
}

const enhanceInvoiceSummariesResponse = (
  response: TSInvoiceSummariesBySiteResponse
): TSInvoice[] => {
  const invoices: any = []
  response.forEach(({ siteId, siteName, invoices: siteInvoices }) => {
    siteInvoices.forEach((invoice: TSInvoiceSummaryResponse) => {
      invoices.push({
        id: invoice.id,
        summary: {
          ...invoice,
          siteId,
          siteName,
        },
      })
    })
  })

  return invoices
}

const enhanceInvoiceDetailResponse = (
  response: TSInvoiceResponse,
  id: string
): TSInvoiceDetail => ({
  ...response,
  id,
})

function* fetchInvoiceSaga({
  request,
}: {
  request: TSFetchInvoiceAction
  type: string
}): Generator<any, void, any> {
  try {
    const { invoiceId } = request
    const response: TSInvoiceResponse = yield call(API.fetchInvoice, request)
    let invoice = enhanceInvoiceDetailResponse(response, invoiceId)
    invoice = filterDemoInvoiceDetails(invoice)
    yield put({
      type: types.FETCH_INVOICE_SUCCESS,
      invoice,
    })
  } catch (error: any) {
    yield handleSagaError(types.FETCH_INVOICE_ERROR, error)
  }
}

function* fetchInvoicesByCustomerSaga({
  request,
}: {
  request: TSFetchInvoicesByCustomerAction
  type: string
}): Generator<any, void, any> {
  try {
    const response: TSInvoiceSummariesBySiteResponse = yield call(
      API.fetchInvoicesByCustomer,
      request
    )
    let invoices = enhanceInvoiceSummariesResponse(response)
    invoices = filterDemoInvoices(invoices)
    yield put({
      type: types.FETCH_INVOICES_BY_CUSTOMER_SUCCESS,
      invoices,
    })
  } catch (error: any) {
    yield handleSagaError(types.FETCH_INVOICES_BY_CUSTOMER_ERROR, error)
  }
}

function* fetchInvoicesBySiteSaga({
  request,
}: {
  request: TSFetchInvoicesBySiteAction
  type: string
}): Generator<any, void, any> {
  try {
    const response: TSInvoiceSummariesBySiteResponse = yield call(
      API.fetchInvoicesBySite,
      request
    )
    let invoices = enhanceInvoiceSummariesResponse(response)
    invoices = filterDemoInvoices(invoices)
    yield put({
      type: types.FETCH_INVOICES_BY_SITE_SUCCESS,
      invoices,
    })
  } catch (error: any) {
    yield handleSagaError(types.FETCH_INVOICES_BY_SITE_ERROR, error)
  }
}
function* downloadInvoiceByIdSaga({
  request,
}: {
  request: TSFetchInvoiceAction
  type: string
}): Generator<any, void, any> {
  try {
    const response: TSInvoiceSummariesBySiteResponse = yield call(
      API.downloadInvoiceById,
      request
    )
    yield put({
      type: types.DOWNLOAD_INVOICE_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield handleSagaError(types.DOWNLOAD_INVOICE_ERROR, error)
  }
}

export const sagas = [
  takeEvery(types.FETCH_INVOICE, fetchInvoiceSaga),
  takeEvery(types.DOWNLOAD_INVOICE_PDF, downloadInvoiceByIdSaga),
  takeLatest(types.FETCH_INVOICES_BY_CUSTOMER, fetchInvoicesByCustomerSaga),
  takeLatest(types.FETCH_INVOICES_BY_SITE, fetchInvoicesBySiteSaga),
]
