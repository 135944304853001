import { queryOptions, useQuery } from '@tanstack/react-query'
import _, { camelCase, omit, orderBy, partition, pick, trim } from 'lodash'
import { useEffect, useState } from 'react'

import { fetchResourceMetadata } from './api'
import { MetadataParams } from './types'
import { TSCircuitsMetaResponse } from '../../ducks/circuit/circuitMeta'
import { useCurrentCustomerStore } from '../../globalState/currentCustomer'
import { filterDemoUsageMetaData } from '../../utils/demo'

// Helpers
const enhanceResourceMetadata = (data: TSCircuitsMetaResponse) => {
  filterDemoUsageMetaData(data)
  const enhancedFields = data.results.map((field) => ({
    ...field,
    id: field.id ?? camelCase(field.name),
    fieldName:
      field.fieldType === 'categoryValue'
        ? `categoryValue-${field.id}`
        : field.fieldType,
    values: orderBy(field.values, (value) => trim(value.name.toLowerCase())),
  }))
  const [customFields, builtInFields] = partition(enhancedFields, {
    fieldType: 'categoryValue',
  })
  return [
    ...builtInFields,
    ...orderBy(customFields, (field) => trim(field.name.toLowerCase())),
  ]
}

const useQueryParams = (options: Omit<MetadataParams, 'customerId'>) => {
  const { currentCustomerId } = useCurrentCustomerStore()
  const filteredOptions = pick(
    options,
    'siteIds',
    'resourceType',
    'panel',
    'buildingSystem',
    'equipment'
  )
  const queryParams = { ...filteredOptions, customerId: currentCustomerId }

  return queryParams
}

const sharedOptions = (queryParams: MetadataParams) =>
  queryOptions({
    queryKey: [
      'resourceMetadata',
      queryParams.resourceType,
      omit(queryParams, 'resourceType'),
    ],
    queryFn: () => fetchResourceMetadata(queryParams),
    staleTime: 1000 * 60 * 60, // Cache for 1 hr
    enabled: !!queryParams.customerId,
  })

// Queries
export const useResourceMetadata = (
  options: Omit<MetadataParams, 'customerId'>
) => {
  const queryParams = useQueryParams(options)

  return useQuery({
    ...sharedOptions(queryParams),
    select: enhanceResourceMetadata,
  })
}

export const useResourceMetadataField = (
  options: Omit<MetadataParams, 'customerId'>,
  fieldType: string
) => {
  const queryParams = useQueryParams(options)
  return useQuery({
    ...sharedOptions(queryParams),
    select: (data) =>
      _(enhanceResourceMetadata(data)).find(['fieldType', fieldType]),
  })
}

export const useCachedResourceMetadata = (
  options: Omit<MetadataParams, 'customerId'>
) => {
  const metadataQuery = useResourceMetadata(options)
  const [cachedMetadata, updateCachedMetadata] = useState(metadataQuery.data)
  // Only update metadata when the new query returns data
  useEffect(() => {
    if (metadataQuery.data?.length) {
      updateCachedMetadata(metadataQuery.data)
    }
  }, [metadataQuery.data])

  return { ...metadataQuery, data: cachedMetadata }
}
