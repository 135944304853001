import { DEV_ENV, PROD_ENV, QA_ENV, STAGE_ENV } from '../constants/api'
import { getSessionToken } from '../globalState/session'

export function apiBaseUrl() {
  const {
    VITE_APP_DEPLOY_ENV,
    VITE_APP_DB_PREVIEW_API_HOST_DEV,
    VITE_APP_DB_PREVIEW_API_HOST_PROD,
    VITE_APP_DB_PREVIEW_API_HOST_QA,
    VITE_APP_DB_PREVIEW_API_HOST_STAGE,
    VITE_APP_PR_BASE_BRANCH,
  } = import.meta.env

  const apiBaseEnvironmentsUrl = {
    [DEV_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_DB_PREVIEW_API_HOST_DEV !==
        'https://<jira ticket number>.preview-api-dev.redaptiveinc.com'
        ? `${VITE_APP_DB_PREVIEW_API_HOST_DEV}/api`
        : 'https://api-dev.redaptiveinc.com/api',
    [QA_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_DB_PREVIEW_API_HOST_QA !==
        'https://<jira ticket number>.preview-api-qa.redaptiveinc.com'
        ? `${VITE_APP_DB_PREVIEW_API_HOST_QA}/api`
        : 'https://api-qa.redaptiveinc.com/api',
    [STAGE_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_DB_PREVIEW_API_HOST_STAGE !==
        'https://<jira ticket number>.preview-api-stage.redaptiveinc.com'
        ? `${VITE_APP_DB_PREVIEW_API_HOST_STAGE}/api`
        : 'https://api-stage.redaptiveinc.com/api',
    [PROD_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_DB_PREVIEW_API_HOST_PROD !==
        'https://<jira ticket number>.preview-api.redaptiveinc.com'
        ? `${VITE_APP_DB_PREVIEW_API_HOST_PROD}/api`
        : 'https://api.redaptiveinc.com/api',
  }
  return apiBaseEnvironmentsUrl[VITE_APP_DEPLOY_ENV]
}

export function denaliApiBaseUrl() {
  const {
    VITE_APP_DEPLOY_ENV,
    VITE_APP_R1_PREVIEW_API_HOST_DEV,
    VITE_APP_R1_PREVIEW_API_HOST_PROD,
    VITE_APP_R1_PREVIEW_API_HOST_QA,
    VITE_APP_R1_PREVIEW_API_HOST_STAGE,
    VITE_APP_PR_BASE_BRANCH,
  } = import.meta.env

  const denaliApiBaseEnvironmentsUrl = {
    [DEV_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_R1_PREVIEW_API_HOST_DEV !==
        'https://<jira ticket number>.preview-denali-api.dev.redaptive.com'
        ? `${VITE_APP_R1_PREVIEW_API_HOST_DEV}/api/denali`
        : 'https://denali-api.dev.redaptive.com/api/denali',
    [QA_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_R1_PREVIEW_API_HOST_QA !==
        'https://<jira ticket number>.preview-denali-api.qa.redaptive.com'
        ? `${VITE_APP_R1_PREVIEW_API_HOST_QA}/api/denali`
        : 'https://denali-api.qa.redaptive.com/api/denali',
    [STAGE_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_R1_PREVIEW_API_HOST_STAGE !==
        'https://<jira ticket number>.preview-denali-api.stage.redaptive.com'
        ? `${VITE_APP_R1_PREVIEW_API_HOST_STAGE}/api/denali`
        : 'https://denali-api.stage.redaptive.com/api/denali',
    [PROD_ENV]:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_R1_PREVIEW_API_HOST_PROD !==
        'https://<jira ticket number>.preview-denali-api.redaptive.com'
        ? `${VITE_APP_R1_PREVIEW_API_HOST_PROD}/api/denali`
        : 'https://denali-api.redaptive.com/api/denali',
  }
  return denaliApiBaseEnvironmentsUrl[VITE_APP_DEPLOY_ENV]
}

export function gcProposalApiBaseUrl() {
  const {
    VITE_APP_DEPLOY_ENV,
    VITE_APP_GC_PREVIEW_API_HOST_DEV,
    VITE_APP_GC_PREVIEW_API_HOST_PROD,
    VITE_APP_GC_PREVIEW_API_HOST_QA,
    VITE_APP_GC_PREVIEW_API_HOST_STAGE,
    VITE_APP_PR_BASE_BRANCH,
  } = import.meta.env

  const gcProposalApiBaseEnvironmentsUrl = {
    dev:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_DEV !==
        'https://<jira ticket number>.preview-gc-api-dev.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_DEV}/proposal/api`
        : 'https://gc-api-dev.redaptiveinc.com/proposal/api',
    qa:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_QA !==
        'https://<jira ticket number>.preview-gc-api-qa.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_QA}/proposal/api`
        : 'https://gc-api-qa.redaptiveinc.com/proposal/api',
    stage:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_STAGE !==
        'https://<jira ticket number>.preview-gc-api-stage.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_STAGE}/proposal/api`
        : 'https://gc-api-stage.redaptiveinc.com/proposal/api',
    prod:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_PROD !==
        'https://<jira ticket number>.preview-gc-api.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_PROD}/proposal/api`
        : 'https://gc-api.redaptiveinc.com/proposal/api',
  }

  return gcProposalApiBaseEnvironmentsUrl[VITE_APP_DEPLOY_ENV]
}

export function gcApiBaseUrl({ excludeApiSegment = false } = {}) {
  const {
    VITE_APP_DEPLOY_ENV,
    VITE_APP_GC_PREVIEW_API_HOST_DEV,
    VITE_APP_GC_PREVIEW_API_HOST_PROD,
    VITE_APP_GC_PREVIEW_API_HOST_QA,
    VITE_APP_GC_PREVIEW_API_HOST_STAGE,
    VITE_APP_PR_BASE_BRANCH,
  } = import.meta.env

  const gcApiBaseEnvironmentsUrl = {
    dev:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_DEV !==
        'https://<jira ticket number>.preview-gc-api-dev.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_DEV}`
        : 'https://gc-api-dev.redaptiveinc.com',
    qa:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_QA !==
        'https://<jira ticket number>.preview-gc-api-qa.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_QA}`
        : 'https://gc-api-qa.redaptiveinc.com',
    stage:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_STAGE !==
        'https://<jira ticket number>.preview-gc-api-stage.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_STAGE}`
        : 'https://gc-api-stage.redaptiveinc.com',
    prod:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_GC_PREVIEW_API_HOST_PROD !==
        'https://<jira ticket number>.preview-gc-api.redaptiveinc.com'
        ? `${VITE_APP_GC_PREVIEW_API_HOST_PROD}`
        : 'https://gc-api.redaptiveinc.com',
  }
  if (excludeApiSegment) return gcApiBaseEnvironmentsUrl[VITE_APP_DEPLOY_ENV]
  return `${gcApiBaseEnvironmentsUrl[VITE_APP_DEPLOY_ENV]}/api`
}

export function opportunityApiBaseUrl() {
  return gcProposalApiBaseUrl() + '/smart-projects'
}

export function redaptiveGeneralApiBaseUrl() {
  const {
    VITE_APP_DEPLOY_ENV,
    VITE_APP_RG_PREVIEW_API_HOST_DEV,
    VITE_APP_RG_PREVIEW_API_HOST_PROD,
    VITE_APP_RG_PREVIEW_API_HOST_QA,
    VITE_APP_RG_PREVIEW_API_HOST_STAGE,
    VITE_APP_PR_BASE_BRANCH,
  } = import.meta.env

  const enviromentUrl = {
    stage:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_RG_PREVIEW_API_HOST_STAGE !==
        'https://preview-redaptive-general-stage.s3.us-east-2.amazonaws.com/<jira ticket number>'
        ? VITE_APP_RG_PREVIEW_API_HOST_STAGE
        : 'https://redaptive-general-stage.s3.us-east-2.amazonaws.com',
    qa:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_RG_PREVIEW_API_HOST_QA !==
        'https://preview-redaptive-general-qa.s3.us-east-2.amazonaws.com/<jira ticket number>'
        ? VITE_APP_RG_PREVIEW_API_HOST_QA
        : 'https://redaptive-general-qa.s3.us-east-2.amazonaws.com',
    prod:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_RG_PREVIEW_API_HOST_PROD !==
        'https://preview-redaptive-general-prod.s3.us-east-2.amazonaws.com/<jira ticket number>'
        ? VITE_APP_RG_PREVIEW_API_HOST_PROD
        : 'https://redaptive-general-prod.s3.us-east-2.amazonaws.com',
    dev:
      VITE_APP_PR_BASE_BRANCH &&
      VITE_APP_RG_PREVIEW_API_HOST_DEV !==
        'https://preview-redaptive-general-dev.s3.us-east-2.amazonaws.com/<jira ticket number>'
        ? VITE_APP_RG_PREVIEW_API_HOST_DEV
        : 'https://redaptive-general-dev.s3.us-east-2.amazonaws.com',
  }

  return enviromentUrl[VITE_APP_DEPLOY_ENV]
}

export function defaultHeaders() {
  const headers: {
    'Content-Type': string
    Authorization?: string
    'Impersonate-Customer-Id'?: string
  } = {
    'Content-Type': 'application/json',
  }

  const token = getSessionToken()

  if (token) {
    headers.Authorization = `Token ${token}`
  }

  return headers
}
