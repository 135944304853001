interface TSProps {
  color?: string
  size?: string
}

const HvacIcon = ({ color = '#6C6D6E', size = '20' }: TSProps) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9.12891 15.5244C8.89727 15.8815 8.75829 16.2906 8.72455 16.7148C8.69081 17.1391 8.76337 17.5651 8.93566 17.9542C9.10795 18.3433 9.37455 18.6834 9.71132 18.9436C10.0481 19.2038 10.4444 19.3759 10.8645 19.4444H10.9022L11.7222 15.6667C10.9374 15.6047 10.1929 15.2932 9.5978 14.7778L9.12891 15.5244Z'
      fill={color}
    />
    <path
      d='M12.2778 15.6756L12.4622 16.52C12.5499 16.937 12.7401 17.3256 13.0158 17.6505C13.2915 17.9755 13.6438 18.2265 14.041 18.381C14.4381 18.5354 14.8675 18.5884 15.2903 18.535C15.7131 18.4817 16.1159 18.3238 16.4622 18.0756L16.4911 18.0533L14.4022 14.8044C13.8048 15.3134 13.0606 15.6186 12.2778 15.6756Z'
      fill={color}
    />
    <path
      d='M15.6756 11.7222L16.52 11.5378C16.937 11.4501 17.3256 11.2599 17.6505 10.9842C17.9755 10.7085 18.2265 10.3562 18.381 9.95904C18.5354 9.56189 18.5884 9.13249 18.535 8.70972C18.4817 8.28695 18.3238 7.88416 18.0756 7.5378L18.0533 7.50891L14.8044 9.5978C15.3134 10.1952 15.6186 10.9395 15.6756 11.7222Z'
      fill={color}
    />
    <path
      d='M9.20443 9.5978L8.47554 9.12891C8.11852 8.89727 7.70938 8.75829 7.28513 8.72455C6.86089 8.69081 6.43493 8.76337 6.04578 8.93566C5.65663 9.10795 5.31657 9.37455 5.05637 9.71132C4.79617 10.0481 4.62404 10.4444 4.55554 10.8645V10.9022L8.33332 11.7222C8.39029 10.9395 8.69545 10.1952 9.20443 9.5978Z'
      fill={color}
    />
    <path
      d='M8.32445 12.2778L7.48 12.4622C7.06299 12.5499 6.67443 12.7401 6.34949 13.0158C6.02455 13.2915 5.77349 13.6438 5.61904 14.041C5.4646 14.4381 5.41164 14.8675 5.46497 15.2903C5.51829 15.7131 5.67622 16.1159 5.92445 16.4622L5.94667 16.4911L9.19556 14.4022C8.68658 13.8048 8.38142 13.0606 8.32445 12.2778Z'
      fill={color}
    />
    <path
      d='M14.7955 14.4022L15.5244 14.8711C15.8814 15.1027 16.2906 15.2417 16.7148 15.2755C17.1391 15.3092 17.565 15.2366 17.9542 15.0644C18.3433 14.8921 18.6834 14.6255 18.9436 14.2887C19.2038 13.9519 19.3759 13.5556 19.4444 13.1355V13.0978L15.6666 12.2778C15.6097 13.0606 15.3045 13.8048 14.7955 14.4022Z'
      fill={color}
    />
    <path
      d='M11.7222 8.32445L11.5378 7.48C11.4501 7.06299 11.2599 6.67443 10.9842 6.34949C10.7085 6.02455 10.3562 5.77349 9.95904 5.61904C9.56189 5.4646 9.13249 5.41164 8.70972 5.46497C8.28695 5.51829 7.88416 5.67622 7.5378 5.92445L7.50891 5.94667L9.5978 9.19556C10.1952 8.68658 10.9395 8.38142 11.7222 8.32445Z'
      fill={color}
    />
    <path
      d='M14.8711 8.47554C15.1027 8.11852 15.2417 7.70938 15.2755 7.28513C15.3092 6.86089 15.2366 6.43493 15.0644 6.04578C14.8921 5.65663 14.6255 5.31657 14.2887 5.05637C13.9519 4.79617 13.5556 4.62404 13.1355 4.55554H13.0978L12.2778 8.33332C13.0626 8.39532 13.8071 8.7068 14.4022 9.22221L14.8711 8.47554Z'
      fill={color}
    />
    <path
      d='M14.3955 12C14.3953 11.5264 14.2547 11.0634 13.9914 10.6697C13.7282 10.276 13.3542 9.9691 12.9166 9.78788C12.479 9.60666 11.9975 9.55922 11.533 9.65156C11.0684 9.7439 10.6417 9.97186 10.3067 10.3067C10.0279 10.5852 9.82222 10.9282 9.70775 11.3052C9.59328 11.6822 9.57358 12.0817 9.6504 12.4682C9.72722 12.8547 9.89819 13.2162 10.1481 13.5208C10.3981 13.8254 10.7194 14.0637 11.0834 14.2144C11.4475 14.3652 11.8431 14.4238 12.2352 14.3851C12.6273 14.3464 13.0039 14.2116 13.3314 13.9926C13.659 13.7736 13.9275 13.4772 14.1131 13.1296C14.2987 12.782 14.3957 12.394 14.3955 12Z'
      fill={color}
    />
    <path
      d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34874 20.9425 6.80691 19.0678 4.93218C17.1931 3.05745 14.6513 2.00294 12 2ZM18.6311 17.5556C17.9007 18.4285 17.0055 19.149 15.9966 19.676C14.9877 20.2029 13.8849 20.526 12.7511 20.6267C12.5044 20.6467 12.2533 20.66 12 20.66C11.7467 20.66 11.4956 20.6467 11.2467 20.6267C9.2182 20.4481 7.31739 19.5609 5.87769 18.1208C4.438 16.6807 3.55137 14.7796 3.37334 12.7511C3.35334 12.5044 3.33334 12.2533 3.33334 12C3.33334 11.7467 3.34667 11.4956 3.36667 11.2467C3.54551 9.21715 4.43363 7.31552 5.87508 5.87568C7.31654 4.43585 9.21918 3.54988 11.2489 3.37333C11.4956 3.35333 11.7467 3.33333 12 3.33333C12.2533 3.33333 12.5044 3.34667 12.7533 3.36667C14.7828 3.5455 16.6845 4.43362 18.1243 5.87508C19.5641 7.31654 20.4501 9.21917 20.6267 11.2489C20.6467 11.4956 20.66 11.7467 20.66 12C20.66 12.2533 20.6467 12.5044 20.6267 12.7533C20.4719 14.5214 19.7749 16.1985 18.6311 17.5556Z'
      fill={color}
    />
  </svg>
)

export default HvacIcon
