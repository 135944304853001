import { isNil } from 'lodash'

import { useConvertAndFormat } from './ConvertAndFormat'
import { NumberFormatter, NumberFormatterParams } from './types'
import { useUnit } from './Unit'

export const useQuantity = (): NumberFormatter => {
  const convertAndFormat = useConvertAndFormat()
  const unit = useUnit()

  return (value, quantityType, options) => {
    if (isNil(value)) return ''

    return `${convertAndFormat(value, quantityType, options)} ${unit(
      quantityType
    )}`
  }
}

const Quantity = ({
  quantityType,
  value,
  precision,
}: NumberFormatterParams) => {
  const quantity = useQuantity()

  return quantity(value, quantityType, { precision })
}

export default Quantity
