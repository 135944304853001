import { Parser } from '@json2csv/plainjs'
import FileSaver from 'file-saver'
import { isEmpty, omit } from 'lodash'
import moment from 'moment'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import useProgramStartDate from './useProgramStartDate'
import { useResourceNames } from '../../../components/mantine/Quantity/ResourceName'
import useConvertQuantity from '../../../components/mantine/Quantity/useConvertQuantity'
import {
  API_DATE_FORMAT,
  DATE_FORMAT_CSV,
  DATE_FORMAT_DATA_API_RESPONSE_NO_TZ,
} from '../../../constants'
import { ResourceType, toResource } from '../../../ducks/types'
import { useResourceUsageData } from '../../../queries/resourceUsage'
import { useSiteVoltageData } from '../../../queries/siteVoltage'
import { titleCase } from '../../../utils'
import useActiveFilters from '../utils/filterParams'

const useDataExplorerCSVDownload = () => {
  const { pageId = 'consumption-analysis' } = useParams()
  const [activeFilters] = useActiveFilters()
  const convertQuantity = useConvertQuantity()
  const resourceNames = useResourceNames()
  const programStartDate = useProgramStartDate(activeFilters.resourceType)
  const isVoltageAnalysis = pageId === 'voltage-analysis'
  const usageQuery = useResourceUsageData(activeFilters, programStartDate, {
    enabled: !isVoltageAnalysis,
  })
  const siteVoltageQuery = useSiteVoltageData(omit(activeFilters, 'options'), {
    enabled: isVoltageAnalysis,
  })

  const multiSiteCsvData = useMemo(() => {
    const timeseries = usageQuery.data?.ts ?? []
    const resource = toResource[activeFilters.resourceType as ResourceType]
    return (usageQuery.data?.groups ?? []).map((group) => {
      const { groupName } = group
      const dataUsageToUse = pageId === 'peak-demand' ? 'kWUsage' : 'usage'
      const usageLabeled = (group[dataUsageToUse] ?? [])?.map((value, i) => [
        moment(timeseries[i], DATE_FORMAT_DATA_API_RESPONSE_NO_TZ).format(
          DATE_FORMAT_CSV
        ),
        convertQuantity(value, resource),
      ])
      return Object.fromEntries([
        ['Group', groupName],
        [
          'Resource',
          pageId === 'peak-demand' &&
          activeFilters.resourceType === ResourceType.ELECTRICITY
            ? 'Electric (kW)'
            : resourceNames[resource],
        ],
        ...usageLabeled,
      ])
    })
  }, [
    usageQuery.data?.ts,
    usageQuery.data?.groups,
    activeFilters.resourceType,
    pageId,
    resourceNames,
    convertQuantity,
  ])

  const singleSiteCsvVoltageData = useMemo(() => {
    const { ts: timeseries, groups = [] } = siteVoltageQuery.data ?? {}
    return groups.map(({ rmsVoltage, groupName }) => {
      const usageLabeled =
        rmsVoltage?.map((value, i) => [
          moment(timeseries[i], DATE_FORMAT_DATA_API_RESPONSE_NO_TZ).format(
            DATE_FORMAT_CSV
          ),
          value,
        ]) ?? []

      return Object.fromEntries([
        ['Group', groupName],
        ['Resource', 'Electric (V)'],
        ...usageLabeled,
      ])
    })
  }, [siteVoltageQuery.data])

  const csv = useMemo(() => {
    const csvData =
      pageId === 'voltage-analysis'
        ? singleSiteCsvVoltageData
        : multiSiteCsvData

    if (isEmpty(csvData)) {
      return null
    }
    const json2csvParser = new Parser()
    const csv = json2csvParser.parse(csvData)

    return new Blob([csv], { type: 'text/csv' })
  }, [multiSiteCsvData, singleSiteCsvVoltageData, pageId])

  const downloadCSV = useCallback(() => {
    const { fromDate, toDate, resolution, resourceType, grouping } =
      activeFilters
    const fromFormatted = moment(fromDate).format(API_DATE_FORMAT)
    const toFormatted = moment(toDate).format(API_DATE_FORMAT)
    const fileName =
      `Redaptive ${titleCase(
        resourceType
      )} Data - ${fromFormatted} to ${toFormatted} - ` +
      `${titleCase(grouping)} - ${titleCase(resolution)}.csv`
    return FileSaver.saveAs(csv, fileName)
  }, [csv, activeFilters])

  return downloadCSV
}

export default useDataExplorerCSVDownload
