interface TSProps {
  color?: string
  size?: string
}

const ChevronRightIcon = ({ color = '#162447', size = '16' }: TSProps) => (
  <svg fill={color} height={size} viewBox='0 0 16 16' width={size}>
    <path
      d='M6.83019 5.28995L2.59019 1.04995C2.49722 0.95622 2.38662 0.881826 2.26476 0.831057C2.1429 0.780288 2.0122 0.75415 1.88019 0.75415C1.74818 0.75415 1.61747 0.780288 1.49561 0.831057C1.37375 0.881826 1.26315 0.95622 1.17019 1.04995C0.983936 1.23731 0.879395 1.49076 0.879395 1.75495C0.879395 2.01913 0.983936 2.27259 1.17019 2.45995L4.71019 5.99995L1.17019 9.53995C0.983936 9.72731 0.879395 9.98076 0.879395 10.2449C0.879395 10.5091 0.983936 10.7626 1.17019 10.9499C1.26363 11.0426 1.37444 11.116 1.49628 11.1657C1.61812 11.2155 1.74858 11.2407 1.88019 11.2399C2.01179 11.2407 2.14226 11.2155 2.26409 11.1657C2.38593 11.116 2.49675 11.0426 2.59019 10.9499L6.83019 6.70995C6.92392 6.61699 6.99831 6.50638 7.04908 6.38453C7.09985 6.26267 7.12599 6.13196 7.12599 5.99995C7.12599 5.86794 7.09985 5.73723 7.04908 5.61537C6.99831 5.49351 6.92392 5.38291 6.83019 5.28995Z'
      stroke={color}
    />
  </svg>
)

export default ChevronRightIcon
